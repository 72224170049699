import AdminTypesPostEpics from "features/admin/parametres/src/typesPost/store/epics";
import { combineEpics } from "redux-observable";
import MonProfilEpics from "../monProfil/store/epics";



const ParametresEpics = combineEpics(
    MonProfilEpics,
    AdminTypesPostEpics
);

export default ParametresEpics;
