import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';
import TuneSVG from '@material-ui/icons/Tune';
import { ThemeProvider } from '@material-ui/styles';
import Axios from 'axios';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import qs from "qs";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/rootStore';
import { blackTheme, colors } from 'Theme';
import useStyle from './Header.style';
import useTeamUpStyle from 'TeamUp.style';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { navigationSetCurrentModule } from 'store/navigation/navigationActions';
import { push } from 'connected-react-router'
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { Theme } from 'Theme';

interface HeaderProps {
    onClickMenu: () => void
}

const Header: React.FC<HeaderProps> = props => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const { onClickMenu } = props;
    const teamUpClasses = useTeamUpStyle();

    const [filterDisplayed, setFilterDisplayed] = useState(false)

    const [selectedFilter, setSelectedFilter] = useState(0)

    const [nbrNotifications, setNbrNotifications] = useState(null)
    const [
        header,
        router
    ] = useSelector((state) => [
        state.header,
        state.router
    ], isEqual);

    useEffect(() => {
        setInterval(function () {
            loadNotifications();
        }, 5000);
    }, [])

    async function loadNotifications() {
        Axios.get(
            `ajx.php?${qs.stringify({
                aj: 2
            })}`
        ).then((data) => {
            setNbrNotifications(data.data.unseen_notification)
        }
        );
    }


    useEffect(() => {
        setFilterDisplayed(false)
    }, [router.location.pathname]);

    function redirectToNotifications() {
        dispatch(navigationSetCurrentModule('notification'))
        dispatch(push('/notification/'))
    }

    return (

        <ThemeProvider theme={blackTheme}>
            <AppBar position="fixed" className={clsx(classes.appBar)} elevation={0}>
                <Toolbar disableGutters style={{ minHeight : 56}}>
                    <Box display='flex'>
                        {
                            header.leftComponents.length !== 0 ?
                                <div style={header.leftComponents.length !== 0 ? { display : 'flex', marginLeft : Theme.spacing(1)} : { display: 'none' }}>
                                    {header.leftComponents.map((leftComponent: JSX.Element) => leftComponent)}
                                </div>
                                :
                                <IconButton onClick={() => onClickMenu()} style={{ color: 'white' }}>
                                    <MenuIcon />
                                </IconButton>
                        }


                    </Box>
                    <Typography style={{ flexGrow: 1, textAlign : 'center', color : 'white', fontSize : 20, fontWeight : 700 }}>{header.pageTitle}</Typography>
                    <div style={{ display: 'flex' }}>
                        <ThemeProvider theme={Theme}>
                            <IconButton onClick={() => redirectToNotifications()} style={{ color: 'white' }}>
                                <Badge invisible={!nbrNotifications || nbrNotifications === 0} badgeContent={nbrNotifications} color='primary'>
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </ThemeProvider>
                        {
                            header.isPageFilter &&
                            <TuneSVG style={{ height: 24, width: 24, color: 'white', marginTop: 'auto', marginBottom: 'auto', marginRight : 10 }} onClick={() => setFilterDisplayed(!filterDisplayed)} />
                        }
                        {
                            header.rightComponents.map((rightComponent: JSX.Element) => rightComponent)
                        }
                    </div>
                    <Collapse style={{ position: 'fixed', top: 56, left: 0, width: '100%', zIndex: 10 }} in={filterDisplayed} timeout={500}>
                        <Box padding="0 14px 20px" width='100%' display='flex' bgcolor='black' alignItems='center' overflow='auto'>
                            {
                                header.listFilters.map((listFilter, index) =>
                                    <span onClick={(e) => { setSelectedFilter(selectedFilter == index ? 0 : index); listFilter.callback(); }} key={`filtre-${index}`} className={`${classes.listFilter} badgeAstuce ${listFilter.className}`} style={selectedFilter == index ? { backgroundColor: listFilter.bgcolor, color: listFilter.color } : {
                                        backgroundColor: colors.grey.dark,
                                        color: colors.lighterGrey.main
                                    }}>{listFilter.title}</span>
                                )
                            }

                            <div style={{ width: 14, display: "list-item", visibility: "hidden" }}></div>
                        </Box>
                    </Collapse>
                </Toolbar>
            </AppBar>
            <Box height={56}></Box>
        </ThemeProvider>
    );
}

export default Header;