import { Acteur } from 'classes/Acteur.class';
import { B64File } from 'classes/B64File.class';
import moment, { Moment, unix } from 'moment';
import { PostLike } from './PostLike.class';
import { PostComment } from './PostComment.class';
import { ConcoursPhotoService } from "services/animations";

/**
 * Cette classe stocke l'instance d'un post de concours photo en front
 */
export class Post {

    /**
     * L'identifiant unique du post
     */
    public idPost: number;

    /**
     * L'identifiant de l'auteur du post
     */
    public idAuteur: string;

    /**
     * L'identifiant du concours dans lequel cette publication a été réalisée
     */
    public idConcours: number;

    /**
     * L'image du post
     */
    public imageFile: B64File;

    /**
     * La description du post
     */
    public description: string;

    /**
     * La date de publication du post
     */
    public datePublication: Moment;

    /**
     * Le statut de validation du post : 0 -> en attente de validation, 1 -> validé (ou le concours ne demande pas de validation), 2 -> refusé
     */
    public validation: number;

    /**
     * Vrai si le post est actif, faux sinon
     */
    public actif: boolean;

    /**
     * La raison du rejet par l'admin
     */
    public rejectReason: string;

    /**
     * L'auteur du post
     */
    public auteur: Acteur;

    /**
     * Les likes qu'a reçu le post
     */
    public likes: PostLike[];

    //public comments: PostComment[];

    /**
     * Vrai si l'utilisateur courant a liké le post
     */
    public wasLikedByCurrent: boolean;


    private concoursPhotoService = ConcoursPhotoService.getInstance();

    constructor(post?: any) {
        if (post) {
            this.idPost = post.idPost;
            this.idAuteur = post.idAuteur;
            this.idConcours = post.idConcours;
            if(typeof post.imageFile !== 'string') this.imageFile = post.imageFile;
            else this.imageFile = B64File.fromDb(post.imageFile);
            this.description = post.description;
            this.datePublication = moment.unix(post.datePublication);
            this.validation = post.validation;
            this.actif = post.actif;
            this.rejectReason = post.rejectReason;
            if(post.auteur) this.auteur = new Acteur(post.auteur);
            if (post.likes) {
                this.likes = post.likes.map((like: any) => new PostLike(like));
            }
            /*if(post.comments) {
                this.comments = post.comments.map((comment: any) => new PostComment(comment));
            }*/
        }
    }

    public async save(): Promise<Post> {
        return this.concoursPhotoService.savePost(this, () => {});
    }

    /**
     * Convertit l'objet Post en tableau pour le transmettre au back
     * @returns Le tableau représentant l'instance
     */
    public toObject(): object {
        return {
            idPost: this.idPost,
            idAuteur: this.idAuteur,
            idConcours: this.idConcours,
            imageFile: this.imageFile,
            description: this.description,
            validation: this.validation,
            rejectReason: this.rejectReason,
            actif: this.actif,
            datePublication: this.datePublication.unix()
        };
    }
}