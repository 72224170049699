import { B64File } from 'classes/B64File.class';
import cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    addFileDocuments,
    loadContenuFromChallengeAsync,
    loadPresentationAsync,
    loadDocuments,
    removeFileDocuments,
    updateFileDocumentIndex,
    setProgressSaveFiles
} from './actions';
import { ContenuWithEverything, Document } from './types';

const files = createReducer<Document[], RootAction>([])
    .handleAction(setProgressSaveFiles, (_state, action) => {
        let newState = cloneDeep(_state);
        let i = newState.findIndex((elem) => elem.idBloc == action.payload.idBloc);
        newState[i].documents.map(obj => action.payload.b64File.localIdFile == obj.localIdFile ? action.payload.b64File : obj);
        return cloneDeep(newState);
    })
    .handleAction(addFileDocuments.request, (_state, action) => {
        let newState = cloneDeep(_state);
        let i = newState.findIndex((elem) => elem.idBloc == action.payload.idBloc);
        newState[i].documents.push(action.payload.b64File);
        return cloneDeep(newState);
    })
    .handleAction(addFileDocuments.success, (_state, action) => {
        let newState = cloneDeep(_state);
        newState.map((item: Document, index: number) => {
            let i = item.documents.findIndex((elem) => elem.localIdFile == action.payload.b64File.localIdFile);
            item.documents[i] = action.payload.b64File;
        });
        return cloneDeep(newState);
    })
    .handleAction(removeFileDocuments.success, (_state, action) => {
        let newState = cloneDeep(_state);
        newState.map((item: Document, index: number) => {
            newState[index].documents = item.documents.filter(o => o.idFile != action.payload.b64File.idFile);
        });
        return cloneDeep(newState);
    })
    .handleAction(loadDocuments.success, (_state, action) => {
        return [..._state, ...action.payload];
    })
    .handleAction(updateFileDocumentIndex.success, (state, action) => action.payload)
    .handleAction(updateFileDocumentIndex.request, (state, action) => {
        let newState = cloneDeep(state);
        newState.map((item: Document, index: number) => {
            if (action.payload.newIndex >= item.documents.length) {
                var k = action.payload.newIndex - item.documents.length + 1;
                while (k--) {
                    item.documents.push(undefined);
                }
            }
            item.documents.splice(action.payload.newIndex, 0, item.documents.splice(action.payload.oldIndex, 1)[0]);
        });
        return cloneDeep(newState);
    });

const presentation = createReducer<ContenuWithEverything, RootAction>(null)
    .handleAction([loadPresentationAsync.success], (_state, action) => cloneDeep(action.payload))
    .handleAction([loadContenuFromChallengeAsync.success], (_state, action) => cloneDeep(action.payload));

export const challengeReducers = combineReducers({
    files,
    presentation
});

export default challengeReducers;