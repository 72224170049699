import { createAsyncAction, createAction } from 'typesafe-actions';
import { Animation } from '../../../../classes/animations/Animation.class';

export const loadAnimationsAsync = createAsyncAction('LOAD_ANIMATIONS_REQUEST', 'LOAD_ANIMATIONS_SUCCESS', 'LOAD_ANIMATIONS_FAILURE')<
void,
Animation[],
string
>();

export const loadFrontCarnetQuestionnaires = createAsyncAction(
    "LOAD_FRONT_CARNET_QUESTIONNAIRES_REQUEST",
    "LOAD_FRONT_CARNET_QUESTIONNAIRES_SUCCESS",
    "LOAD_FRONT_CARNET_QUESTIONNAIRES_FAILURE"
)<void, Animation[], string>();

export const setCurrentAnimation = createAction('SET_CURRENT_ANIMATION')<Animation>();

export * from '../quiz/store/actions';
export * from '../photoCompetition/store/actions';
