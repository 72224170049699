import { Transform, Type } from "class-transformer";
import { Acteur } from "classes/Acteur.class";
import { B64File } from "classes/B64File.class";
import moment, { Moment } from "moment";
import { MursService } from "services";
import { SignalementsService } from "services/signalements";
import { Commentaire } from "./Commentaire.class";
import { Like } from "./Like.class";
import { TypePost } from "./TypePost.class";

export type PostMenuAction = { name: string, authorized: boolean }
export type ListPostMenuActions = { [key: number]: PostMenuAction }
export class Post {

    public idMurPost: number = null;

    public idType: number = null;

    @Type(() => TypePost)
    public typePost: TypePost;

    public idReferentielActeur: string = null;

    @Type(() => Acteur)
    public acteur: Acteur;

    public idReferentielActeurAuteur: string = null;

    @Type(() => Acteur)
    public acteurAuteur: Acteur;

    public idReferentielMetier: number = null;

    public post: string = '';

    public dateCreationPost: string = null;

    public belleDateCreationPost: string = null;

    public longueDateCreationPost: string = null;

    public courteDateCreationPost: string = null;

    public nbCommentaire: number = null;

    public nbLike: number = null;

    public groupesLikes: any[] = [];

    public monLike: number = null;

    public actif: number = null;

    public dateDebutAffichage: string = null;

    public longueDateDebutAffichage: string = null;

    public belleDateDebutAffichage: string = null;

    public courteDateDebutAffichage: string = null;

    @Type(() => Date)
    @Transform(value => moment.unix(value), {toClassOnly : true})
    public timestampDateDebutAffichage: Moment = null;

    public lienUrl: string = null;

    public lienVideo: string = null;

    public lienImage: string = null;

    @Type(() => B64File)
    @Transform(value => B64File.fromDb(value), {toClassOnly : true})
    public fileImage: B64File = null;

    @Type(() => B64File)
    @Transform(value => B64File.fromDb(value), {toClassOnly : true})
    public fileVideo: B64File = null;

    public oldLienImage: string = null;

    public oldLienVideo: string = null;

    public reponses: Array<string> = ['', ''];

    public reponse1: string = null;

    public reponse2: string = null;

    public reponse3: string = null;

    public nbReponses: Array<number> = [0, 0];

    public nbReponse1: number = null;

    public nbReponse2: number = null;

    public nbReponse3: number = null;

    public maReponseSondage: number = null;

    public lienDocument: string = null;

    public nomDocument: string = null;

    @Type(() => B64File)
    @Transform(value => B64File.fromDb(value), {toClassOnly : true})
    public fileDocument: B64File = null;

    public notificationEnvoye: boolean = null;

    public dateDebutEpingle: string = null;

    public longueDateDebutEpingle: string = null;

    public belleDateDebutEpingle: string = null;

    public courteDateDebutEpingle: string = null;

    @Type(() => Date)
    @Transform(value =>  moment.unix(value), {toClassOnly : true})
    public timestampDateDebutEpingle: Moment = null;

    public dateFinEpingle: string = null;

    public longueDateFinEpingle: string = null;

    public belleDateFinEpingle: string = null;

    public courteDateFinEpingle: string = null;

    @Type(() => Date)
    @Transform(value =>  moment.unix(value), {toClassOnly : true})
    public timestampDateFinEpingle: Moment = null;

    public epingle: boolean = null;

    public isEpingle: boolean = null;

    public willBeEpingle: boolean = null;

    public idTypeSuppression: number = null;

    public dateSuppression: string = null;

    public longueDateSuppression: string = null;

    public belleDateSuppression: string = null;

    public courteDateSuppression: string = null;

    public isFavori: boolean = null;

    public index: number = null;

    public menuActions: ListPostMenuActions = null;

    public typeMur: number = null;

    private murService = MursService.getInstance();

    private signalementsService = SignalementsService.getInstance();

    constructor(newPost?: any) {

        if (newPost) {
            const {
                idMurPost,
                idType,
                typePost,
                idReferentielActeur,
                acteur,
                idReferentielActeurAuteur,
                acteurAuteur,
                idReferentielMetier,
                post,
                dateCreationPost,
                longueDateCreationPost,
                belleDateCreationPost,
                courteDateCreationPost,
                nbCommentaire,
                nbLike,
                groupesLikes,
                monLike,
                actif,
                dateDebutAffichage,
                longueDateDebutAffichage,
                belleDateDebutAffichage,
                courteDateDebutAffichage,
                timestampDateDebutAffichage,
                lienUrl,
                lienVideo,
                lienImage,
                oldLienImage,
                oldLienVideo,
                fileImage,
                fileVideo,
                reponse1,
                reponse2,
                reponse3,
                nbReponse1,
                nbReponse2,
                nbReponse3,
                maReponseSondage,
                lienDocument,
                nomDocument,
                fileDocument,
                notificationEnvoye,
                dateDebutEpingle,
                longueDateDebutEpingle,
                belleDateDebutEpingle,
                courteDateDebutEpingle,
                timestampDateDebutEpingle,
                dateFinEpingle,
                longueDateFinEpingle,
                belleDateFinEpingle,
                courteDateFinEpingle,
                timestampDateFinEpingle,
                epingle,
                isEpingle,
                willBeEpingle,
                idTypeSuppression,
                dateSuppression,
                longueDateSuppression,
                belleDateSuppression,
                courteDateSuppression,
                isFavori,
                index,
                menuActions,
                typeMur
            } = newPost;

            this.idMurPost = idMurPost;

            this.idType = idType;

            this.typePost = new TypePost(typePost);

            this.idReferentielActeur = idReferentielActeur;

            this.acteur = new Acteur(acteur);

            this.idReferentielActeurAuteur = idReferentielActeurAuteur;

            this.acteurAuteur = new Acteur(acteurAuteur);

            this.idReferentielMetier = idReferentielMetier;

            this.post = post;

            this.dateCreationPost = dateCreationPost;

            this.longueDateCreationPost = longueDateCreationPost;

            this.belleDateCreationPost = belleDateCreationPost;

            this.courteDateCreationPost = courteDateCreationPost

            this.nbCommentaire = nbCommentaire;

            this.nbLike = nbLike;

            this.groupesLikes = groupesLikes;

            this.monLike = monLike;

            this.actif = actif;

            this.dateDebutAffichage = dateDebutAffichage;

            this.longueDateDebutAffichage = longueDateDebutAffichage;

            this.belleDateDebutAffichage = belleDateDebutAffichage;

            this.courteDateDebutAffichage = courteDateDebutAffichage;

            this.timestampDateDebutAffichage = moment.unix(timestampDateDebutAffichage);

            this.lienUrl = lienUrl;

            this.lienVideo = lienVideo;

            this.lienImage = lienImage;

            this.oldLienImage = oldLienImage;

            this.fileImage = B64File.fromDb(fileImage);

            this.fileVideo = B64File.fromDb(fileVideo);

            this.reponse1 = reponse1;

            this.reponse2 = reponse2;

            this.reponse3 = reponse3;

            this.reponses = [
                reponse1,
                reponse2
            ];

            if (reponse3) {
                this.reponses.push(reponse3);
            }

            this.nbReponses = [
                nbReponse1,
                nbReponse2,
                nbReponse3
            ];

            this.nbReponse1 = nbReponse1;

            this.nbReponse2 = nbReponse2;

            this.nbReponse3 = nbReponse3;

            this.maReponseSondage = maReponseSondage;

            this.lienDocument = lienDocument;

            this.nomDocument = nomDocument;

            
            this.fileDocument = B64File.fromDb(fileDocument);

            this.notificationEnvoye = notificationEnvoye;

            this.dateDebutEpingle = dateDebutEpingle;

            this.longueDateDebutEpingle = longueDateDebutEpingle;

            this.belleDateDebutEpingle = belleDateDebutEpingle;

            this.courteDateDebutEpingle = courteDateDebutEpingle;

            this.timestampDateDebutEpingle = moment.unix(timestampDateDebutEpingle);

            this.dateFinEpingle = dateFinEpingle;

            this.longueDateFinEpingle = longueDateFinEpingle;

            this.belleDateFinEpingle = belleDateFinEpingle;

            this.courteDateFinEpingle = courteDateFinEpingle;

            this.timestampDateFinEpingle = moment.unix(timestampDateFinEpingle);

            this.epingle = epingle;

            this.isEpingle = isEpingle;

            this.willBeEpingle = willBeEpingle;

            this.idTypeSuppression = idTypeSuppression;

            this.dateSuppression = dateSuppression;

            this.longueDateSuppression = longueDateSuppression;

            this.belleDateSuppression = belleDateSuppression;

            this.courteDateSuppression = courteDateSuppression;

            this.isFavori = isFavori;

            this.index = index;

            this.menuActions = menuActions;

            this.typeMur = typeMur;
        }
    }

    public toDatabaseObject(): object {
        // return {
        //     randomQuestion: this.randomQuestion,
        //     randomQuestionCount: this.randomQuestionCount,
        //     showResultsAfterGame: this.showResultsAfterGame,
        //     publishResultsAfterEnd: this.publishResultsAfterEnd,
        //     enableRanking: this.enableRanking,
        // };
        return {
            id_mur_post: this.idMurPost,
            id_type: this.idType,
            id_referentiel_acteur: this.idReferentielActeur,
            id_referentiel_acteur_auteur: this.idReferentielActeurAuteur,
            id_referentiel_metier: this.idReferentielMetier,
            post: this.post,
            date_creation_post: this.dateCreationPost,
            nb_commentaire: this.nbCommentaire,
            nb_like: this.nbLike,
            actif: this.actif,
            date_debut_affichage: this.dateDebutAffichage,
            lien_url: this.formatUrl(this.lienUrl),
            lien_video: this.fileVideo ? this.fileVideo : this.lienVideo,
            lien_image: this.fileImage ? this.fileImage : this.lienImage,
            reponse1: this.reponses[0],
            reponse2: this.reponses[1],
            reponse3: this.reponses[2] ? this.reponses[2] : null,
            nb_reponse1: this.nbReponses[0],
            nb_reponse2: this.nbReponses[1],
            nb_reponse3: this.nbReponses[2] ? this.nbReponses[2] : null,
            lien_document: this.fileDocument ? this.fileDocument : this.lienDocument,
            nom_document: this.nomDocument,
            notification_envoye: this.notificationEnvoye,
            date_debut_epingle: this.dateDebutEpingle,
            date_fin_epingle: this.dateFinEpingle,
            epingle: this.epingle,
            id_type_suppression: this.idTypeSuppression,
            date_suppression: this.dateSuppression,
        }
    }

    private formatUrl(url?: string) {
        if (!url) {
            return url;
        } else if (!url.match(/^https?:\/\//)) {
            return 'https://' + url;
        } else {
            return url;
        }
    }

    public async addLike(typeMur: number, typeLike: number): Promise<number> {
        return this.murService.addLike(typeMur, typeLike, this.idMurPost)
    }

    public async removeLike(typeMur: number): Promise<number> {
        return this.murService.removeLike(typeMur, this.idMurPost)
    }

    public async removePost(typeMur: number): Promise<boolean> {
        return this.murService.removePost(typeMur, this.idMurPost)
    }

    public async addComment(commentaire: string, idPere: number, typeMur: number, typeComment: number): Promise<number> {
        return this.murService.addComment(commentaire, idPere, typeMur, typeComment, this.idMurPost)
    }

    public async getCommentaires(typeMur: number): Promise<Commentaire[]> {
        return this.murService.loadCommentaires(typeMur, this.idMurPost);
    }
    public async getLikes(typeMur: number): Promise<Like[]> {
        return this.murService.loadLikes(typeMur, this.idMurPost);
    }

    public async sendReponseSondage(typeMur: number, idReponse: number): Promise<number> {
        return this.murService.sendReponseSondage(typeMur, idReponse, this.idMurPost)
    }

    public async publishPost(typeMur: number): Promise<Post> {
        return this.murService.publishPost(typeMur, this);
    }

    public async epingler(typeMur: number, dateDebut?: string, dateFin?: string): Promise<Post> {
        //TODO : Définir les champs de class et sauvegarder?
        return this.murService.epinglerPost(typeMur, this.idMurPost, dateDebut, dateFin);
    }

    public async favori(typeMur: number): Promise<Post> {
        return this.murService.favoriPost(typeMur, this.idMurPost);
    }

    public async signaler(typeMur: number, raison: number, description: string): Promise<boolean> {
        return this.signalementsService.signalerPost(typeMur, this.idMurPost, raison, description);
    }


}