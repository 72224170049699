
import cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { setHeaderStore, setPageTitle, setLeftComponents, setRightComponents, setListFilters, setIsPageFilter } from './actions';

const leftComponents = createReducer<JSX.Element[], RootAction>([])
    .handleAction(setLeftComponents, (_state, action) => action.payload)
    .handleAction(setHeaderStore, (_state, action) => action.payload.leftComponents || _state)

const pageTitle = createReducer<string, RootAction>('')
    .handleAction(setPageTitle, (_state, action) => action.payload)
    .handleAction(setHeaderStore, (_state, action) => action.payload.pageTitle || _state)

const rightComponents = createReducer<JSX.Element[], RootAction>([])
    .handleAction(setRightComponents, (_state, action) => action.payload)
    .handleAction(setHeaderStore, (_state, action) => action.payload.rightComponents  || _state)

const isPageFilter = createReducer<boolean, RootAction>(false)
    .handleAction(setIsPageFilter, (_state, action) => action.payload)
    .handleAction(setHeaderStore, (_state, action) => !action.payload.isPageFilter ? false : true )

const listFilters = createReducer<HeaderFilter[], RootAction>([])
    .handleAction(setListFilters, (_state, action) => action.payload)
    .handleAction(setHeaderStore, (_state, action) => action.payload.listFilters || _state)

export const headerReducers = combineReducers({
    leftComponents,
    pageTitle,
    rightComponents,
    listFilters,
    isPageFilter
});

export default headerReducers;