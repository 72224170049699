import { PropTypes } from '@material-ui/core/index';
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { TextFieldProps } from "@material-ui/core/TextField";
import { withStyles, WithStyles } from '@material-ui/styles'
import { B64File } from "classes/B64File.class";
import React, { Component, ComponentType, PureComponent } from "react";
import ChoosePictureSVG from "../../features/admin/animations/assets/choose_picture.svg";
import FilePickerComponent from "./FilePicker.component";
import style from "./FilePicker.style";


export interface B64FilePickerProps extends WithStyles<typeof style> {
    value: B64File;
    onChange: (file: B64File) => void;
    fileType?: "image" | "file";
    variant?: "zone" | "button" | "icon" | "textfield" | "hidden";
    ButtonImg?: string | ComponentType<SvgIconProps>;
    emptyInnerZone?: boolean;
    buttonText?: string;
    buttonVariant?: "contained" | "outlined" | "text";
    buttonColor?: PropTypes.Color;
    buttonSize?: "medium" | "small";
    inputAccept?: string;
    textFieldProps?: TextFieldProps;
    round?: boolean;
    height?: string;
    maxFileSize?: number;
    open?: boolean;
    readonly?: boolean;
    readonlyDelete?: boolean;
}

class B64FilePickerComponent extends Component<B64FilePickerProps> {
    public static defaultProps: Pick<
        B64FilePickerProps,
        | "fileType"
        | "variant"
        | "ButtonImg"
        | "buttonText"
        | "buttonVariant"
        | "buttonColor"
        | "buttonSize"
        | "inputAccept"
        | "emptyInnerZone"
        | "round"
        | "height"
        | "maxFileSize"
        | "open"
        | "readonly"
        | "readonlyDelete"
    > = {
            fileType: "image",
            variant: "zone",
            ButtonImg: ChoosePictureSVG,
            buttonVariant: "outlined",
            buttonColor: "default",
            buttonSize: "small",
            inputAccept: "image/*",
            emptyInnerZone: false,
            round: false,
            height: "100%",
            maxFileSize: 8 * 1e6,
            open: false,
            readonly: false,
            readonlyDelete: false
        };

    private input: HTMLInputElement;

    public async handleFileChange(file: File) {
        if (file) {
            const b64File = await B64File.fromFormFile(file);
            this.setState({ file: b64File });
            this.props.onChange(b64File);
        } else {
            this.props.onChange(null);
        }
    }

    public shouldComponentUpdate(nextProps : B64FilePickerProps){
        if(nextProps.value && this.props.value){
            return !(nextProps.value.name === this.props.value.name)
        }else{
            return true;
        }
    }

    public setEmptyFile(): void {
        this.setState({ file: null });
        this.input.value = "";
        this.props.onChange(null);
    }

    public render(): JSX.Element {
        return <FilePickerComponent {...this.props}
            onChange={(f) => this.handleFileChange(f)}
            directPathToFile={(this.props.value && !this.props.value.hasData()) ? this.props.value.getSrc() : null}
            value={this.props.value ? this.props.value.getAsFile() : null}
        />
    }
}

export default withStyles(style)(B64FilePickerComponent);
