import { createAction } from 'typesafe-actions';
import { BreadcrumbsProps } from '@material-ui/core';
import { AdminHeaderInfos } from './adminHeaderTypes'

export const adminHeaderSetTitleAction = createAction('ADMIN_HEADER_SET_TITLE')<string | JSX.Element>();

export const adminHeaderSetArianeAction = createAction('ADMIN_HEADER_SET_ARIANE')<(string | JSX.Element)[]>();

export const adminHeaderSetAccueilLink = createAction('ADMIN_HEADER_SET_ACCUEIL_LINK')<string>();

export const adminHeaderSetReturnHeader = createAction('ADMIN_HEADER_SET_RETURN_HEADER')<boolean>();

export const adminHeaderSetReturnLink = createAction('ADMIN_HEADER_SET_RETURN_LINK')<string>();

export const adminHeaderSetBreadcrumbsProps = createAction('ADMIN_HEADER_SET_BREADCRUMBS')<BreadcrumbsProps>();

export const adminHeaderSetStore = createAction('ADMIN_HEADER_SET_STORE')<AdminHeaderInfos>();

export const adminHeaderActions = {
    adminHeaderSetTitleAction,
    adminHeaderSetArianeAction,
    adminHeaderSetAccueilLink,
    adminHeaderSetReturnHeader,
    adminHeaderSetReturnLink,
    adminHeaderSetBreadcrumbsProps,
    adminHeaderSetStore
};