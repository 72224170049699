import {v4} from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';

/**
 * Bloc Bouton contenant une URL et un texte pour le bouton
 */

export class ContenuBlocBouton extends ContenuBloc {

    public uid: string = v4();

    public texte : string;

    public url : string;

    public newtab : boolean;

    constructor(row?: any) {
        super(row);

        if (row) {
            const { texte,
                    url,
                    newtab
            } = row;
            this.texte = texte;
            this.url = url;
            this.newtab = newtab;
        }
    }


    public toRaw() {
        return {
            texte : this.texte,
            url : this.url,
            newtab : this.newtab,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.texte || !this.url) return true;
        else return false;
    }

}
