import { ChallengeService } from "services";
import { B64File } from "classes/B64File.class";
import { ChallengeThemeImage } from "./ChallengeThemeImage.class";

export class ChallengeThemePersonnaliseImage extends ChallengeThemeImage {

    public idThemePersonnalise : number = null;

    public image : B64File = null;

    public type : number = null;

    private ChallengeService = ChallengeService.getInstance();

    constructor(row? : any) {
        super(row)
        if (row) {

            const {
                idThemePersonnalise,
                image,
                type,
            } = row;

            this.idThemePersonnalise = idThemePersonnalise;

            this.image = B64File.fromDb(image);

            this.type = type;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.ChallengeService.saveChallengeThemeImage(this);
    // }

    public getSrc() : string
    {
        return this.image.getSrc();
    }
    
    public toDatabaseObject(): object {
        return {
            idThemePersonnalise: this.idThemePersonnalise,
            image: this.image,
            type: this.type,
        }
    }
}