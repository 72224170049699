import { B64File } from "classes/B64File.class";
import moment, { Moment } from "moment";

export class QuizBadge {

    public idBadge : number = null;

    public idAnimation : number = null;

    public title : string = null;

    public description : string = null;

    public imageUrl : B64File = null;

    public unlockedDate : Moment;

    public alreadyUnlocked : boolean = false;

    constructor(badge?: any) {

        if (badge) {
            this.idBadge = badge.idBadge;
            this.idAnimation = badge.idAnimation;
            this.title = badge.title;
            this.description = badge.description;
            this.imageUrl = B64File.fromDb(badge.imageUrl);
            if(badge.unlockedDate){
                this.unlockedDate = moment(badge.unlockedDate, 'X');
            }
        }
    }

    public toObject(): object {
        return {
            idBadge: this.idBadge,
            idAnimation: this.idAnimation,
            title: this.title,
            description: this.description,
            imageUrl: this.imageUrl,
        };
    }

}