import Axios from "axios";
import { Acteur } from "classes/Acteur.class";
import { Animation } from "classes/animations/Animation.class";
import { B64File } from "classes/B64File.class";
import { CarnetVoyage } from "classes/carnet_voyage/CarnetVoyage.class";
import { Edito } from "classes/carnet_voyage/edito/Edito.class";
import { Hotel } from "classes/carnet_voyage/hotel/Hotel.class";
import { CarnetInformations } from "classes/carnet_voyage/informations/CarnetInformations.class";
import { CarnetInformationsItem } from "classes/carnet_voyage/informations/CarnetInformationsItem.class";
import { Item } from "classes/carnet_voyage/Item.class";
import { CarnetParticipant } from "classes/carnet_voyage/participants/CarnetParticipant.class";
import { CarnetCategory } from "classes/carnet_voyage/programme/CarnetCategory.class";
import { CarnetDay } from "classes/carnet_voyage/programme/CarnetDay.class";
import { CarnetInformationsInfos, CarnetScheduleInfos } from "features/carnet_voyage/src/store/types";
import qs from "qs";
import { rxAxios, RxAxios } from "services/RxAxios";

export class CarnetVoyageService {
    private static instance: CarnetVoyageService;

    public static getInstance(): CarnetVoyageService {
        if (!CarnetVoyageService.instance) {
            CarnetVoyageService.instance = new CarnetVoyageService();
        }

        return CarnetVoyageService.instance;
    }

    private static rubriqueMur = IS_ADMIN ? 250 : 25;

    public async save(carnet: CarnetVoyage, file: B64File): Promise<CarnetVoyage> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 1
            })}`,
            qs.stringify({
                carnet: JSON.stringify(carnet.toRaw()),
                file: file ? JSON.stringify(file) : ''
            })
        ).then(({ data: { content } }) => new CarnetVoyage(content));
    }

    public async saveItem(item: Item): Promise<Item> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 4
            })}`,
            qs.stringify({
                item: JSON.stringify(item.toRaw())
            })
        ).then(({ data: { content } }) => new Item(content));
    }

    public async deleteParticipant(participant: CarnetParticipant): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 25
            })}`,
            qs.stringify({
                participant: JSON.stringify(participant.idReferentielActeur)
            })
        );
    }

    public async switchOnlineItem(idItem: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 21
            })}`,
            qs.stringify({
                idItem: JSON.stringify(idItem)
            })
        ).then(({ data: { content } }) => true)
            .catch(() => false);
    }

    public async loadItem(idItem: number): Promise<Item> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 26
        };
        return await Axios.post(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({
                idItem: JSON.stringify(idItem)
            })
        ).then(({ data: { content } }) => new Item(content));
    }

    public async load(): Promise<CarnetVoyage> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 2
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => {
            var carnet = new CarnetVoyage(content);
            return carnet;
        });
    }

    public async loadItems(): Promise<Item[]> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 3
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => content.map((item: any) => new Item(item)));
    }

    public async loadParticipants(): Promise<CarnetParticipant[]> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 6
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => content.map((carnetParticipant: any) => new CarnetParticipant(carnetParticipant)));
    }

    public async loadSchedule(): Promise<CarnetScheduleInfos> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 7
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => {
            let ret: CarnetScheduleInfos = { schedule: [], scheduleCategories: [] };
            ret.schedule = content.schedule.map((schedule: any) => new CarnetDay(schedule));

            content.scheduleCategories.forEach((category: any) => {
                ret.scheduleCategories[category.idCategory] = new CarnetCategory(category);
            });
            return ret;
        });
    }

    public async loadInformations(): Promise<CarnetInformationsInfos> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 8
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => {
            let ret: CarnetInformationsInfos = { carnetInformations: [], carnetInformationsItems: [] };
            ret.carnetInformations = content.carnetInformations.map((carnetInformation: any) => new CarnetInformations(carnetInformation));

            ret.carnetInformationsItems = content.carnetInformationsItems.map(
                (carnetInformationItem: any) => new CarnetInformationsItem(carnetInformationItem)
            );
            return ret;
        });
    }

    public async saveInformations(informations: CarnetInformations[]): Promise<CarnetInformations[]> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 9
            })}`,
            qs.stringify({
                informations: JSON.stringify(informations)
            })
        ).then(({ data: { content } }) => content.map((information: any) => new CarnetInformations(information)));
    }

    public async saveSchedule(schedule: CarnetDay[]): Promise<void> {
        Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 10
            })}`,
            qs.stringify({
                schedule: JSON.stringify(schedule.map(day => day.toRaw()))
            })
        );
    }

    public async loadContacts(): Promise<Acteur[]> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 11
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => content.map((acteur: any) => new Acteur(acteur)));
    }

    public async saveContacts(contacts: Acteur[]): Promise<Acteur[]> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 12
            })}`,
            qs.stringify({
                contacts: JSON.stringify(contacts)
            })
        ).then(({ data: { content } }) => content.map((contact: any) => new Acteur(contact)));
    }

    public async loadCarnetQuestionnaires(): Promise<Animation[]> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 13
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: animations } }) => {
            return animations.map(this.mapToAnimation);
        });
    }

    public async loadEdito(): Promise<Edito> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 14
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => new Edito(content));
    }

    public async saveEdito(edito: Edito): Promise<Edito> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 15
            })}`,
            qs.stringify({
                edito: JSON.stringify(edito)
            })
        ).then(({ data: { content } }) => new Edito(content));
    }

    public async loadHotel(): Promise<Hotel> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 16
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => new Hotel(content));
    }

    public async saveHotel(hotel: Hotel): Promise<Hotel> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 17
            })}`,
            qs.stringify({
                hotel: JSON.stringify(hotel)
            })
        ).then(({ data: { content } }) => new Hotel(content));
    }

    public async loadTelechargements(): Promise<B64File[]> {
        const params: any = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 18
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => B64File.rowsToArray(content));
    }

    public addFileTelechargements(b64File: B64File): RxAxios<{ content: B64File }> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 19
            })}`,
            data: qs.stringify({
                b64File: JSON.stringify(b64File)
            })
        });
    }

    public async removeFileTelechargements(b64File: B64File): Promise<B64File> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 20
            })}`,
            qs.stringify({
                b64File: JSON.stringify(b64File)
            })
        ).then(({ data: { content } }) => B64File.rowsToFile(content));
    }

    public async resetCarnet(): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 24
            })}`
        );
    }


    private mapToAnimation(animation: any): Animation {
        return new Animation.TYPES_ANIMATION[animation.type](animation);
    }
}
