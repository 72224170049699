import { Challenge } from 'classes/challenges/Challenge.class';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ThemesIndexedByRubriquesLabels, ChallengeRubriquesAndInfos, LoadRubriquesAndInfosPayload, LoadClassements, ListClassementsEtDate } from './types';
import { ChallengeEdito } from 'classes/challenges/Edito.class';
import { B64File } from 'classes/B64File.class';
import { ChallengeActualite } from 'classes/challenges/ChallengeActualite.class';
import { ChallengeReglement } from 'classes/challenges/Reglement.class';
import { ChallengeDestinationRubrique } from 'classes/challenges/ChallengeDestinationRubrique.class';
import { ChallengeDestinationRubriqueInfos } from 'classes/challenges/ChallengeDestinationRubriqueInfos.class';
import { Video } from 'classes/videos/Video.class';
import { Suivi } from 'classes/challenges/suivi/Suivi.class';
import { MulticritereClassement } from 'classes/challenges/suivi/MulticritereClassement.class';
import { ChallengeRubrique } from 'classes/challenges/ChallengeRubrique.class';



export const setCurrentChallenge = createAction('SET_CURRENT_CHALLENGE')<Challenge>();

// Chargement des challenges
export const loadChallengesAsync = createAsyncAction(
    'LOAD_CHALLENGES_REQUEST',
    'LOAD_CHALLENGES_SUCCESS',
    'LOAD_CHALLENGES_FAILURE',
)<void, Challenge[], string>();

export const getChallengeAsync = createAsyncAction(
    'GET_CHALLENGE_REQUEST',
    'GET_CHALLENGE_SUCCESS',
    'GET_CHALLENGE_FAILURE'
)<number, Challenge, string>();

export const getChallengeAndRubriqueAsync = createAsyncAction(
    'GET_CHALLENGE_AND_RUBRIQUE_REQUEST',
    'GET_CHALLENGE_AND_RUBRIQUE_SUCCESS',
    'GET_CHALLENGE_AND_RUBRIQUE_FAILURE'
)<{idChallenge : number, idRubriqueInfos : number}, {challenge:  Challenge, challengeRubrique : ChallengeRubrique}, string>();

export const deleteChallengeAsync = createAsyncAction(
    'DELETE_CHALLENGE_REQUEST',
    'DELETE_CHALLENGE_SUCCESS',
    'DELETE_CHALLENGE_FAILURE'
)<number, void, string>();


export const loadPredefinedThemesAsync = createAsyncAction(
    'LOAD_PREDEFINED_THEMES_REQUEST',
    'LOAD_PREDEFINED_THEMES_SUCCESS',
    'LOAD_PREDEFINED_THEMES_FAILURE'
)<void, ThemesIndexedByRubriquesLabels, string>();

// Rubrique edito
export const loadChallengeEditoAsync = createAsyncAction(
    'LOAD_CHALLENGE_EDITO_REQUEST',
    'LOAD_CHALLENGE_EDITO_SUCCESS',
    'LOAD_CHALLENGE_EDITO_FAILURE',
)<number, ChallengeEdito, string>();

export const loadChallengeActualitesAsync = createAsyncAction(
    'ADMIN_LOAD_ACTUALITES_REQUEST',
    'ADMIN_LOAD_ACTUALITES_SUCCESS',
    'ADMIN_LOAD_ACTUALITES_FAILURE',
)<number, ChallengeActualite[], string>();

export const loadChallengeActualiteAsync = createAsyncAction(
    'ADMIN_LOAD_ACTUALITE_REQUEST',
    'ADMIN_LOAD_ACTUALITE_SUCCESS',
    'ADMIN_LOAD_ACTUALITE_FAILURE',
)<number, ChallengeActualite, string>();

export const loadChallengeReglementAsync = createAsyncAction(
    'ADMIN_LOAD_REGLEMENT_REQUEST',
    'ADMIN_LOAD_REGLEMENT_SUCCESS',
    'ADMIN_LOAD_REGLEMENT_FAILURE',
)<number, ChallengeReglement, string>();

// export const loadChallengeRubriquesAsync = createAsyncAction(
//     'LOAD_CHALLENGE_RUBRIQUES_REQUEST',
//     'LOAD_CHALLENGE_RUBRIQUES_SUCCESS',
//     'LOAD_CHALLENGE_RUBRIQUES_FAILURE'
// )<number, ChallengeRubrique[], string>();

export const loadChallengeRubriquesAndInfosAsync = createAsyncAction(
    'LOAD_CHALLENGE_RUBRIQUES_AND_INFOS_REQUEST',
    'LOAD_CHALLENGE_RUBRIQUES_AND_INFOS_SUCCESS',
    'LOAD_CHALLENGE_RUBRIQUES_AND_INFOS_FAILURE'
)<LoadRubriquesAndInfosPayload, ChallengeRubriquesAndInfos, string>();

// export const loadChallengeRubriquesInfosAsync = createAsyncAction(
//     'LOAD_CHALLENGE_RUBRIQUES_INFOS_REQUEST',
//     'LOAD_CHALLENGE_RUBRIQUES_INFOS_SUCCESS',
//     'LOAD_CHALLENGE_RUBRIQUES_INFOS_FAILURE'
// )<void, ChallengeRubriqueInfos[], string>();

// Chargement des rubriques de destination d'un challenge
export const loadDestinationRubriquesAsync = createAsyncAction(
    'LOAD_DESTINATION_RUBRIQUES_REQUEST',
    'LOAD_DESTINATION_RUBRIQUES_SUCCESS',
    'LOAD_DESTINATION_RUBRIQUES_FAILURE',
)<number, ChallengeDestinationRubrique[], string>();

// Chargement des informations de toutes les rubriques de la partie destination
export const loadDestinationRubriquesInfosAsync = createAsyncAction(
    'LOAD_DESTINATION_RUBRIQUES_INFOS_REQUEST',
    'LOAD_DESTINATION_RUBRIQUES_INFOS_SUCCESS',
    'LOAD_DESTINATION_RUBRIQUES_INFOS_FAILURE',
)<void, ChallengeDestinationRubriqueInfos[], string>();

// Chargement des informations de toutes les rubriques de la partie destination
export const saveRubriquesDestinationAsync = createAsyncAction(
    'SAVE_RUBRIQUES_DESTINATION_REQUEST',
    'SAVE_RUBRIQUES_DESTINATION_SUCCESS',
    'SAVE_RUBRIQUES_DESTINATION_FAILURE',
)<{ rubriquesDestination: ChallengeDestinationRubrique[], idChallenge: number }, ChallengeDestinationRubrique[], string>();

// Chargement des suivis
export const loadChallengeSuivisAsync = createAsyncAction(
    'LOAD_CHALLENGE_SUIVIS_REQUEST',
    'LOAD_CHALLENGE_SUIVIS_SUCCESS',
    'LOAD_CHALLENGE_SUIVIS_FAILURE',
)<number, Suivi[], string>();

// Chargement des suivis pour la version mobile
export const loadChallengeSuivisMobileAsync = createAsyncAction(
    'LOAD_CHALLENGE_SUIVIS_MOBILE_REQUEST',
    'LOAD_CHALLENGE_SUIVIS_MOBILE_SUCCESS',
    'LOAD_CHALLENGE_SUIVIS_MOBILE_FAILURE',
)<number, Suivi[], string>();



export const loadChallengeSuiviAsync = createAsyncAction(
    'LOAD_CHALLENGE_SUIVI_REQUEST',
    'LOAD_CHALLENGE_SUIVI_SUCCESS',
    'LOAD_CHALLENGE_SUIVI_FAILURE',
)<number, Suivi, string>();

// Chargement des classements multicritère
export const loadClassementsMulticritereAsync = createAsyncAction(
    'LOAD_CLASSEMENTS_MULTICRITERE_REQUEST',
    'LOAD_CLASSEMENTS_MULTICRITERE_SUCCESS',
    'LOAD_CLASSEMENTS_MULTICRITERE_FAILURE',
)<LoadClassements, ListClassementsEtDate, string>();

// Chargement des classements multicritère pour l'acteur courant
export const loadClassementMulticritereParActeurAsync = createAsyncAction(
    'LOAD_CLASSEMENT_MULTICRITERE_PAR_ACTEUR_REQUEST',
    'LOAD_CLASSEMENT_MULTICRITERE_PAR_ACTEUR_SUCCESS',
    'LOAD_CLASSEMENT_MULTICRITERE_PAR_ACTEUR_FAILURE',
)<number, MulticritereClassement, string>();



export const setProgressSaveFiles = createAction('SET_CHALLENGES_PROGRESS_SAVE_FILES')<B64File>()

export const setSelectedChallenge = createAction('SET_SELECTED_CHALLENGE')<Challenge>()

export const loadTelechargements = createAsyncAction("LOAD_CHALLENGES_TELECHARGEMENTS_REQUEST", "LOAD_CHALLENGES_TELECHARGEMENTS_SUCCESS", "LOAD_CHALLENGES_TELECHARGEMENTS_FAILURE")<number, B64File[], string>();

export const addFileTelechargements = createAsyncAction("ADD_FILE_CHALLENGES_TELECHARGEMENTS_REQUEST", "ADD_FILE_CHALLENGES_TELECHARGEMENTS_SUCCESS", "ADD_FILE_CHALLENGES_TELECHARGEMENTS_FAILURE")<{ b64File: B64File, idChallenge: number }, B64File, string>();

export const removeFileTelechargements = createAsyncAction("REMOVE_FILE_CHALLENGES_TELECHARGEMENTS_REQUEST", "REMOVE_FILE_CHALLENGES_TELECHARGEMENTS_SUCCESS", "REMOVE_FILE_CHALLENGES_TELECHARGEMENTS_FAILURE")<B64File, any, string>();

export const updateFileTelechargementsIndex = createAsyncAction("UPDATE_FILE_INDEX_CHALLENGES_TELECHARGEMENTS_REQUEST", "UPDATE_FILE_INDEX_CHALLENGES_TELECHARGEMENTS_SUCCESS", "UPDATE_FILE_INDEX_CHALLENGES_TELECHARGEMENTS_FAILURE")<{ oldIndex: number, newIndex: number }, B64File[], string>();

export const adminSetCurrentChallenge = createAction('ADMIN_SET_CURRENT_CHALLENGE')<Challenge>();

export const saveVideo = createAsyncAction("SAVE_VIDEO_REQUEST", "SAVE_VIDEO_SUCCESS", "SAVE_VIDEO_FAILURE")<{ idChallenge: number, video: Video }, Video, string>();

export const loadVideos = createAsyncAction("LOAD_VIDEOS_REQUEST", "LOAD_VIDEOS_SUCCESS", "LOAD_VIDEOS_FAILURE")<number, Video[], string>();

export const removeVideo = createAsyncAction("REMOVE_VIDEO_REQUEST", "REMOVE_VIDEO_SUCCESS", "REMOVE_VIDEO_FAILURE")<{ idChallenge: number, video: Video }, Video, string>();


export const setListChallengesIdTypeChallenge = createAction("CHALLENGE_SET_LIST_CHALLENGES_ID_TYPE_CHALLENGE")<number>();

export const setCurrentSuivi = createAction("SET_CURRENT_SUIVI")<Suivi>();
