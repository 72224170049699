import { Acteur } from "classes/Acteur.class";
import { Post } from "classes/murs/Post.class";
import { SignalementsService } from "services/signalements";
import { Commentaire } from "classes/murs/Commentaire.class";

export class Signalement {

    public idMurSignalement: number;

    public idRaison: number;

    public description: number;

    public idType: number;

    public idReferentielActeur: number;

    public idReferentielMetier: number;

    public idMurPost: number;

    public idMurCommentaire: number;

    public dateCreationSignalement: number;

    public actif: number;

    public belleDateCreationSignalement: string;

    public longueDateCreationSignalement: string;

    public courteDateCreationSignalement: string;

    public post: Post;

    public comment : Commentaire;

    public acteur: Acteur;

    public anomalie: number;

    public raison: string;

    public typeMur: number;

    private signalementService = SignalementsService.getInstance();

    constructor(signalement?: any) {
        if (signalement) {
            const {
                idMurSignalement,
                idRaison,
                description,
                idType,
                idReferentielActeur,
                idReferentielMetier,
                idMurPost,
                idMurCommentaire,
                dateCreationSignalement,
                actif,
                belleDateCreationSignalement,
                longueDateCreationSignalement,
                courteDateCreationSignalement,
                post,
                comment,
                acteur,
                anomalie,
                raison,
                typeMur
            } = signalement;



            this.idMurSignalement = idMurSignalement;

            this.idRaison = idRaison;

            this.description = description;

            this.idType = idType;

            this.idReferentielActeur = idReferentielActeur;

            this.idReferentielMetier = idReferentielMetier;

            this.idMurPost = idMurPost;

            this.idMurCommentaire = idMurCommentaire;

            this.dateCreationSignalement = dateCreationSignalement;

            this.actif = actif;

            this.belleDateCreationSignalement = belleDateCreationSignalement;

            this.longueDateCreationSignalement = longueDateCreationSignalement;

            this.courteDateCreationSignalement = courteDateCreationSignalement;

            this.anomalie = anomalie;

            this.post = new Post(post);

            this.comment = new Commentaire(comment);

            this.acteur = new Acteur(acteur);

            this.raison = raison;

            this.typeMur = typeMur;
        }


    }

    public toDatabaseObject(): object {
        return {
            id_mur_signalement: this.idMurSignalement,
            id_raison: this.idRaison,
            description: this.description,
            id_type: this.idType,
            id_referentiel_acteur: this.idReferentielActeur,
            id_referentiel_metier: this.idReferentielMetier,
            id_mur_post: this.idMurPost,
            id_mur_commentaire: this.idMurCommentaire,
            date_creation_signalement: this.dateCreationSignalement,
            actif: this.actif,
            typeMur: this.typeMur,
            anomalie: this.anomalie,

        }
    }

    public async save(): Promise<void> {
        return this.signalementService.saveSignalement(this);
    }
}