import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'

export default ({ spacing, palette }: typeof Theme) => createStyles({
    ligneHautTableau: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    lineAction: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 0,
    },
    buttonIcon: {
        marginRight: spacing(1),
    },
});
