import { ChallengeService } from "services";
import { ChallengeRubriqueInfos } from "./ChallengeRubriqueInfos.class";

export class ChallengeRubrique {

    //Lie l'identifiant de la rubrique info à la classe
    static RUBRIQUES_DROITS : {
        [key : number] : string
    } = {
        1 : 'challenge_presentation', //Présentation
        2 : 'challenge_reglement',
        3 : 'challenge_telechargement',
        4 : 'challenge_destination', //Destination,
        5 : 'challenge_edito',
        6 : 'challenge_actualite',
        7 : 'challenge_video', //Vidéos
        8 : 'challenge_suivi', //Suivis
        9 : 'challenge_animation' //Animations
    };

    public leChallenge: number = null;

    public lesInfosRubrique: number = null;

    public enLigne: boolean = null;

    public indexRubrique: number = null;


    public infos: ChallengeRubriqueInfos = null;

    private ChallengeService = ChallengeService.getInstance();

    constructor(row?: any) {

        if (row) {

            const {
                leChallenge,
                lesInfosRubrique,
                enLigne,
                indexRubrique,
                infos
            } = row;

            this.leChallenge = leChallenge;

            this.lesInfosRubrique = lesInfosRubrique;

            this.enLigne = enLigne;

            this.indexRubrique = indexRubrique;

            this.infos = new ChallengeRubriqueInfos(infos);

        }
    }

    public save: () => Promise<string> = () => {
        return this.ChallengeService.saveRubrique(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.ChallengeService.deleteRubrique(this);
    }

    public toDatabaseObject(): object {
        return {
            leChallenge: this.leChallenge,
            lesInfosRubrique: this.lesInfosRubrique,
            enLigne: this.enLigne,
            indexRubrique: this.indexRubrique,
        }
    }
}