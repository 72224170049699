import {v4} from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';


export class ContenuBlocCarte extends ContenuBloc {

    public uid: string = v4();

    public adresse : string;
    public lat : number;
    public lng : number;

    constructor(row?: any) {
        super(row);

        if (row) {
            const { adresse, lat, lng } = row;
            this.adresse = adresse;
            this.lat = lat;
            this.lng = lng;
        }
    }


    public toRaw() {
        return {
            adresse : this.adresse,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc,
            lat : this.lat,
            lng : this.lng
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.lat || !this.lng) return true;
        else return false;
    }


}
