import { Animation } from 'classes/animations/Animation.class';
import { loadCarnetQuestionnaires } from 'features/carnet_voyage/src/store/actions';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import quizReducer from '../quiz/store/reducer';
import concoursPhotoReducer from '../photoCompetition/store/reducer';
import { loadAnimationsAsync, loadFrontCarnetQuestionnaires, setCurrentAnimation } from './actions';

const isLoading = createReducer(true)
    .handleAction([loadAnimationsAsync.request, loadFrontCarnetQuestionnaires.request, loadCarnetQuestionnaires.request], () => true)
    .handleAction([loadAnimationsAsync.success, loadCarnetQuestionnaires.success, loadCarnetQuestionnaires.failure, loadFrontCarnetQuestionnaires.success,loadFrontCarnetQuestionnaires.failure], () => false);

const list = createReducer<Animation[], RootAction>([]).handleAction(loadAnimationsAsync.success, (_state, action) => action.payload)
.handleAction(loadFrontCarnetQuestionnaires.success, (state, action) => action.payload)
.handleAction(loadCarnetQuestionnaires.success, (state, action) => action.payload);

const currentAnimation = createReducer<Animation, RootAction>(null)
    .handleAction(loadAnimationsAsync.request, () => null)
    .handleAction(setCurrentAnimation, (_state, action) => action.payload);

const animationsReducer = combineReducers({
    isLoading,
    list,
    currentAnimation,
    quiz: quizReducer,
    concoursPhoto: concoursPhotoReducer
});

export default animationsReducer;
