import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/styles'
import MoreHorizSVG from '@material-ui/icons/MoreHoriz';
import SendSVG from '@material-ui/icons/Send';
import { Commentaire } from 'classes/murs/Commentaire.class';
import { Post } from 'classes/murs/Post.class';
import Avatar from 'components/avatar/Avatar.component';
import ConfirmationDrawerComponent from 'components/drawer/ConfirmationDrawer.component';
import React, { Component, FocusEvent, Fragment } from 'react';
import { connect } from 'react-redux';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { RootState } from 'store/types';
import { colors } from "Theme";
import SuppressionSVG from '../../../../../assets/visuels/suppression.svg';
import { getPostAsync, getPostFromIndexAsync, setShouldPostsListGetUpdated } from '../store/actions';
import style from './Commentaire.style';
import ModifyCommentDialogComponent from './ModifyCommentDialog.component';
import SignalementDrawerComponent from './SignalementDrawer.component';

const stateToProps = ({ murs: { typeMur, elements }, acteur: { current } }: RootState) => ({
    typeMur,
    elements,
    moi: current
})

const dispatchToProps = {
    getPost: getPostAsync.request,
    setShouldPostsListGetUpdated,
    newSnackbar,
    getPostFromIndex: getPostFromIndexAsync.request
}

interface CommentaireLocalProps {
    comment: Commentaire,
    readOnly?: boolean,
    index: number,
    commentDeleted: (index: number) => void,
    commentAdd: () => void,
    post?: Post
}

interface CommentaireState {
    answering: boolean,
    answer: string,
    anchorEl: HTMLElement,
    moiIsAuteur: boolean,
    openModifyCommentDialog: boolean,
    openDeleteCommentDrawer: boolean,
    signalerPostDrawerOpen: boolean
}

type CommentaireProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & CommentaireLocalProps

class CommentaireComponent extends Component<CommentaireProps, CommentaireState> {

    readonly state: CommentaireState = {
        answering: false,
        answer: "",
        anchorEl: null,
        moiIsAuteur: false,
        openModifyCommentDialog: false,
        openDeleteCommentDrawer: false,
        signalerPostDrawerOpen: false
    }

    handleRepondreClick() {
        this.setState({
            answering: true
        })
    }

    handleAnswerFocusEvent(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    }


    handleMoreMenuCommentClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            anchorEl: event.currentTarget,
        });
    }

    handleCloseActionsMenu() {
        this.setState({
            anchorEl: null
        })
    }

    async handleDeleteCommentaire() {
        const { post, comment, typeMur, index, commentDeleted, getPostFromIndex, getPost, setShouldPostsListGetUpdated } = this.props;
        post.nbCommentaire = await comment.delete(typeMur);

        if (post.index) {
            getPostFromIndex(post.index);
        } else {

            setShouldPostsListGetUpdated(true);
            getPost(post.idMurPost);
        }

        commentDeleted(index);
    }

    handleClickDeleteComment() {
        this.setState({ openDeleteCommentDrawer: true });
        this.handleCloseActionsMenu();
    }

    handleCloseDeleteCommentDialog(ok: boolean) {
        if (ok) {
            this.handleDeleteCommentaire();
        }
        this.setState({ openDeleteCommentDrawer: false });
    }

    handleClickModifyComment() {
        this.setState({ openModifyCommentDialog: true });
        this.handleCloseActionsMenu();
    }

    handleCloseModifyComment(commentText: string) {

        const { post, comment, typeMur, index, commentDeleted } = this.props;
        if (commentText && commentText.length > 0) {
            this.props.comment.commentaire = commentText;
            this.props.comment.saveText(typeMur);
        }
        this.setState({ openModifyCommentDialog: false, anchorEl: null });
    }
    async handleSendCommentClick() {
        const { answer } = this.state;
        const { comment, typeMur, index, getPostFromIndex, post, getPost, setShouldPostsListGetUpdated } = this.props;

        // const { post, typeMur, getPost } = this.props;
        if (answer != "") {
            //TODO : Un tableau dans commentaire pour savoir quel type de commentaire on supprime?
            // 1 : commentaire de post
            // 2 : commentaire de commentaire
            post.nbCommentaire = await post.addComment(answer, comment.idMurCommentaire, typeMur, 2);


            if (post.index) {
                getPostFromIndex(post.index);
            } else {

                setShouldPostsListGetUpdated(true);
                getPost(post.idMurPost);
            }

            this.setState({ answer: "", answering: false })
            this.props.commentAdd();
        }
    }

    handleClickSignalerComment() {
        this.setState({ signalerPostDrawerOpen: true });
        this.handleCloseActionsMenu();
    }

    async handleSignalerCommentCloseDrawer(raison: number, commentaire: string) {
        const { post, comment, typeMur, index, commentDeleted, newSnackbar } = this.props;
        if (raison) {
            let signaledComment = await comment.signaler(typeMur, raison, commentaire);
            if (signaledComment) {
                newSnackbar({
                    type: SnackbarType.INFO,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Votre signalement a bien été envoyé.`,
                    }
                });
            } else {
                newSnackbar({
                    type: SnackbarType.WARNING,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Il y a eu un problème dans l'envoi de votre signalement`,
                    }
                });
            }
        }
        this.setState({ signalerPostDrawerOpen: false });
    }

    handleKeyUpForm(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key == 'Enter') {
            this.handleSendCommentClick();
        }

    }
    render() {
        const { comment, classes, elements, moi, typeMur, post, readOnly } = this.props;
        const { answering, anchorEl, openModifyCommentDialog, openDeleteCommentDrawer, answer } = this.state;



        // a revoir pour récupérer le post à partir de la liste des commentaires
        // quand clic sur nombre de commentaire
        let affRepondre = false;
        if (typeof post != 'undefined') {
            if (post) {
                if (
                    ((elements[`${IS_ADMIN ? 'admin_' : ''}commenter_public`] && post.idReferentielMetier === -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}commenter`] && post.idReferentielMetier !== -1))
                ) {
                    affRepondre = true;
                }
            } else {
                if (
                    ((elements[`${IS_ADMIN ? 'admin_' : ''}commenter_public`]) || (elements[`${IS_ADMIN ? 'admin_' : ''}commenter`]))
                ) {
                    affRepondre = true;
                }
            }

        } else {

            if (
                ((elements[`${IS_ADMIN ? 'admin_' : ''}commenter_public`]) || (elements[`${IS_ADMIN ? 'admin_' : ''}commenter`]))
            ) {
                affRepondre = true;
            }

        }

        return (
            <Fragment>
                <Box display='flex' flexDirection='row' width='100%' marginTop={1}>
                    <Box display='flex' flexDirection='row' width='100%' marginLeft={comment.idCommentaireReponse ? 2 : 0}>
                        <Avatar variant='front' acteur={comment.acteur} />
                        <Box display='flex' width='100%' flexDirection='column' marginLeft={2} justifyContent='space-between'>
                            <Box display='flex' width='100%' flexDirection='row' justifyContent='space-between'>
                                <Typography className={classes.nomCommentaire} variant='h6'>{comment.acteur.prenom + ' ' + comment.acteur.nom}</Typography>
                                <Typography className={classes.dateCommentaire}>{comment.dateCreationCommentaire}</Typography>
                            </Box>
                            <Typography className={classes.texteCommentaire}>{comment.commentaire}</Typography>


                            {
                                !readOnly &&

                                <Box display='flex' className={classes.actionsBox} >
                                    {
                                        (!comment.idCommentaireReponse && !answering) && (affRepondre) &&
                                        <Link style={{ color: colors.grey.main }} onClick={this.handleRepondreClick.bind(this)} className={classes.answerCommentaire}>Répondre</Link>
                                    }
                                    {
                                        (
                                            (((elements[`${IS_ADMIN ? 'admin_' : ''}supprimer_comment`]  && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}supprimer_comment_public`]  && post.idReferentielMetier === -1)  ) && (comment.currentIsAuteur)) ||
                                            (((elements[`${IS_ADMIN ? 'admin_' : ''}modifier_comment`]  && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}modifier_comment_public`]  && post.idReferentielMetier === -1)  ) && (comment.currentIsAuteur)) ||
                                            (((elements[`${IS_ADMIN ? 'admin_' : ''}signaler_comment`]   && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}signaler_comment_public`]   && post.idReferentielMetier === -1) ) && (!comment.currentIsAuteur))
                                         )

                                        &&
                                        <IconButton size='small' onClick={this.handleMoreMenuCommentClick.bind(this)}><MoreHorizSVG className={classes.moreIcon} /></IconButton>
                                    }
                                </Box>
                            }


                            <Collapse in={answering}>
                                <FormControl className={classes.formComment} onKeyPress={this.handleKeyUpForm.bind(this)}>
                                    <TextField className={classes.textFieldComment} autoFocus onBlur={(event) => this.setState({ answering: false })} value={answer} onChange={(event) => this.setState({ answer: event.target.value })}></TextField>
                                    <Button onClick={this.handleSendCommentClick.bind(this)} variant='contained' className={classes.sendIconButton} color='primary'><SendSVG /></Button>
                                </FormControl>
                            </Collapse>
                        </Box>
                    </Box>
                    {
                        (
                            (
                               
                               (((elements[`${IS_ADMIN ? 'admin_' : ''}supprimer_comment`]  && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}supprimer_comment_public`]  && post.idReferentielMetier === -1)  ) && (comment.currentIsAuteur)) ||
                                (((elements[`${IS_ADMIN ? 'admin_' : ''}modifier_comment`]  && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}modifier_comment_public`]  && post.idReferentielMetier === -1)  ) && (comment.currentIsAuteur)) ||
                                (((elements[`${IS_ADMIN ? 'admin_' : ''}signaler_comment`]   && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}signaler_comment_public`]   && post.idReferentielMetier === -1) ) && (!comment.currentIsAuteur))
                            )
                        )
                        &&
                        <Menu
                            id={"comment-menu"}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleCloseActionsMenu.bind(this)}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                        >
                            {



                                (
                                     ((elements[`${IS_ADMIN ? 'admin_' : ''}modifier_comment`]  && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}modifier_comment_public`] && post.idReferentielMetier === -1))
                                    &&
                                    (comment.currentIsAuteur)
                                )
                                &&
                                <MenuItem onClick={this.handleClickModifyComment.bind(this)}>Modifier</MenuItem>
                            }
                            {
                                (
                                    ((elements[`${IS_ADMIN ? 'admin_' : ''}supprimer_comment`]   && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}supprimer_comment_public`]  && post.idReferentielMetier === -1))
                                    &&
                                    (comment.currentIsAuteur)
                                )
                                &&
                                <MenuItem onClick={this.handleClickDeleteComment.bind(this)}>Supprimer</MenuItem>

                            }
                            {
                                (
                                    ((elements[`${IS_ADMIN ? 'admin_' : ''}signaler_comment`]  && post.idReferentielMetier !== -1) || (elements[`${IS_ADMIN ? 'admin_' : ''}signaler_comment_public`]  && post.idReferentielMetier === -1))
                                    &&
                                    (!comment.currentIsAuteur)
                                )
                                &&
                                <MenuItem onClick={this.handleClickSignalerComment.bind(this)}>Signaler</MenuItem>
                            }


                        </Menu>
                    }

                </Box>
                <ModifyCommentDialogComponent typeMur={typeMur} triggered={openModifyCommentDialog} comment={comment} onClose={this.handleCloseModifyComment.bind(this)} />
                <ConfirmationDrawerComponent
                    title='Supprimer le commentaire'
                    // text='En cliquant sur <b>Supprimer</b>, votre post sera définitement supprimé.'
                    text={<Fragment>En cliquant sur <b>Oui</b>, le commentaire sera définitivement supprimé.</Fragment>}
                    textKO='Non'
                    textOK='Oui'
                    onClose={(ok: boolean) => this.handleCloseDeleteCommentDialog(ok)}
                    triggered={openDeleteCommentDrawer}
                    image={SuppressionSVG} />
                <SignalementDrawerComponent triggered={this.state.signalerPostDrawerOpen} onClose={this.handleSignalerCommentCloseDrawer.bind(this)} />

            </Fragment >
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(CommentaireComponent));