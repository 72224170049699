import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { B64File } from './B64File.class';
import { QuizBadge } from './animations/QuizBadge.class';
import { v4 } from 'uuid';
import { Type, Transform } from 'class-transformer';
import { ParticipantsService } from 'services';
import { EntiteChampValue } from './referentiel/EntiteChampValue.class';
import { EntiteCaracteristiqueValue } from './referentiel/EntiteCaracteristiqueValue.class';
import cloneDeep from 'lodash/cloneDeep'
import { ActeurEntiteRole } from './referentiel/ActeurEntiteRole.class';
import { Role } from './referentiel/Role.class';

export class Acteur {
    private static readonly AVATAR_FOLDER: string = '/dist/assets/avatar/';

    public uid: string = v4();

    public idActeur: string;

    public nom: string;

    public prenom: string;

    public civilite: number;

    public ddn: number;

    public mobile: string;

    /**
     * Tableau d'équivalence entre la valeur de la civilité en base et son libellé
     */
    static civiliteLibelle: { [key: number]: string } = {
        1: "Madame",
        2: "Monsieur"
    };

    @Transform((value, acteur) => {
        if (acteur.customAvatar) {
            if (acteur.fileAvatar) {
                return acteur.fileAvatar.getSrc();
            } else {
                let tmpFileAvatar = B64File.fromDb(value);
                return tmpFileAvatar.getSrc();
            }
        } else {
            return `/dist/assets/avatar/${value}.svg`
        }
    })
    public avatar: string;

    @Type(() => String)
    @Transform((value, acteur) => {
        if (acteur.customAvatar) {
            return B64File.fromDb(value);
        } else {
            return null;
        }
    }, { toClassOnly: true })
    public fileAvatar: B64File = null;


    public role: string;

    public stranger: boolean;

    public customAvatar: boolean;

    public entiteActif: boolean;

    public validation: number;

    public idPere: string;

    public libellePere: string;

    public entiteChampsValues: EntiteChampValue[] = [];

    public entiteCaracteristiquesValue: EntiteCaracteristiqueValue[] = [];

    public leRole: Role;

    public leAER : ActeurEntiteRole;

    //EXCLUSIF AU CARNET CONTACT
    public sequence: number;


    private participantsService = ParticipantsService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idActeur,
                nom,
                prenom,
                mobile,
                avatar,
                ddn,
                role,
                stranger,
                customAvatar,
                validation,
                sequence,
                idPere,
                libellePere,
                civilite,
                entiteChampsValues,
                entiteCaracteristiquesValue,
                entiteActif,
                leRole,
                leAER } = row;
            this.idActeur = idActeur;
            this.nom = nom;
            this.prenom = prenom;
            this.civilite = civilite;
            this.mobile = mobile;
            this.customAvatar = customAvatar;
            if (customAvatar) {
                //TODO : C'est dégueulasse
                this.fileAvatar = B64File.fromDb(avatar);
                if (this.fileAvatar) {

                    this.avatar = this.fileAvatar.getSrc();
                } else {
                    this.customAvatar = false;
                    this.avatar = `/dist/assets/avatar/${avatar}.svg`;
                }
            } else {
                this.avatar = `/dist/assets/avatar/${avatar}.svg`;
                this.fileAvatar = null;
            }

            this.entiteActif = entiteActif;
            this.stranger = stranger;
            this.ddn = ddn;
            this.role = role;
            this.validation = validation;
            this.sequence = sequence;
            this.idPere = idPere;
            this.libellePere = libellePere;
            this.entiteChampsValues = cloneDeep(entiteChampsValues);
            this.entiteCaracteristiquesValue = cloneDeep(entiteCaracteristiquesValue);
            this.leRole = leRole;
            this.leAER = leAER;
        }
    }

    public getAvatarUrl(): string {
        return this.avatar;
    }

    public save(entiteCaracteristiqueValues: EntiteCaracteristiqueValue[] = [], acteurEntiteRole?: ActeurEntiteRole, estModification: boolean = true): Promise<Boolean> {
        return this.participantsService.saveActeur(this, entiteCaracteristiqueValues, acteurEntiteRole, estModification);
    }

    public delete(): Promise<Boolean> {
        return this.participantsService.deleteActeurEntiteRole(this.idPere, this.idActeur);
    }

    public async getStatistiques() {
        return Axios.get(`index.php?${qs.stringify({
            rub: 68553,
            p: 13
        })}`).then(({ data: { content } }) => content);
    }

    public toRaw() {
        return {
            idActeur: this.idActeur,
            nom: this.nom,
            prenom: this.prenom,
            mobile: this.mobile,
            avatar: this.avatar,
            ddn: this.ddn,
            role: this.role,
            validation: this.validation,
            civilite: this.civilite,
            entiteActif: this.entiteActif
        };
    }

    public toDatabaseObject() {
        return {
            id_entite: this.idActeur,
            acteur_nom: this.nom,
            acteur_prenom: this.prenom,
            mobile: this.mobile,
            avatar: this.avatar,
            ddn: this.ddn,
            role: this.role,
            validation: this.validation,
            acteur_civilite: this.civilite,
            entite_actif: this.entiteActif
        };
    }
}
