import {v4} from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';
import { ContenuDotation } from '../ContenuDotation.class';


export class ContenuBlocDotations extends ContenuBloc {

    public uid: string = v4();

    public dotations : ContenuDotation[] = [];

    constructor(row?: any) {
        super(row);

        if (row) {
            const { dotations } = row;
            this.dotations = [];
            if (dotations) {
                for (let d of dotations) {
                    this.dotations.push(new ContenuDotation(d));
                }
            }
        }
    }


    public toRaw() {
        return {
            dotations : this.dotations,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.dotations || this.dotations.length == 0) return true;
        for (let d of this.dotations) {
            if (d.isEmpty()) return true;
        }

        return false;
    }


}
