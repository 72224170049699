import { Acteur } from "classes/Acteur.class";
import Axios from "axios";
import qs from 'qs';
import { ActeurInfos, Droits } from "store/acteur/acteurTypes";
import { Role } from "classes/referentiel/Role.class";
import cloneDeep from 'lodash/cloneDeep'

export class ReferentielService {
    private static instance: ReferentielService;

    public static getInstance(): ReferentielService {
        if (!ReferentielService.instance) {
            ReferentielService.instance = new ReferentielService();
        }

        return ReferentielService.instance;
    }

    public async findCurrentActeur(): Promise<Acteur> {
        return Axios.get(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 685530 : 68553,
            p: 10
        })}`).then(({ data: { content } }) => new Acteur(content));
    }


    public async findActeur(idActeur: string): Promise<Acteur> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 270,
            p: 8,
            idActeur
        })}`).then(({ data: { content } }) => new Acteur(content));
    }

    public async loadActeurInfos(idActeur: string): Promise<ActeurInfos> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 270,
            p: 10,
            idActeur
        })}`).then(({ data: { content } }) => {
            var ret: ActeurInfos = {
                acteur: null,
                statistics: {},
                currentIsMe : false,
                monRole : null,
                niveauCaracteristiques : null,
                droits : {}
            }
            ret.acteur = new Acteur(content.acteur);
            ret.statistics = content.statistics;
            ret.currentIsMe = content.currentIsMe;
            ret.monRole = new Role(content.monRole);
            ret.niveauCaracteristiques = cloneDeep(content.niveauCaracteristiques);
            return ret;
        });
    }

    public async loadCurrentActeurInfos(): Promise<ActeurInfos> {
        return Axios.get(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 685530 : 68553,
            p: 20
        })}`).then(({ data: { content } }) => {
            var ret: ActeurInfos = {
                acteur: null,
                statistics: {},
                currentIsMe : false,
                monRole : null,
                niveauCaracteristiques : null,
                droits : {}
            }
            ret.acteur = new Acteur(content.acteur);
            ret.statistics = content.statistics;
            ret.currentIsMe = true;
            ret.monRole = new Role(content.monRole);
            ret.niveauCaracteristiques = cloneDeep(content.niveauCaracteristiques);
            ret.droits = content.droits;
            return ret;
        });
    }

    public async getMesDroits() : Promise<Droits> {
        return Axios.get(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 685530 : 68553,
            p: 19,
        })}`).then(({ data: { content } }) => {
            console.log(content);
            return {};
        });
    }
}
