import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, WithStyles } from '@material-ui/styles'
import GetAppSVG from '@material-ui/icons/GetApp';
import Axios from 'axios';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import IconPhotosSVG from '../../../assets/icon_photos.svg';
import style from './SouvenirsSettingsCarnetLeftComponent.style';

const stateToProps = ({ carnetVoyage: { carnet } }: RootState) => ({
    carnet
})

const dispatchToProps = {

}

interface SouvenirsSettingsCarnetLeftComponentLocalProps {
}

interface SouvenirsSettingsCarnetLeftComponentState {
}

type SouvenirsSettingsCarnetLeftComponentProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & SouvenirsSettingsCarnetLeftComponentLocalProps;

class SouvenirsSettingsCarnetLeftComponent extends Component<SouvenirsSettingsCarnetLeftComponentProps, SouvenirsSettingsCarnetLeftComponentState> {

    async downloadImages() {
        const params: any = {
            rub: 250,
            p: 22
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(() => {

        });
    }
    render() {
        const { carnet, classes } = this.props;
        return (
            <Paper className={classes.paper} elevation={0}>
                <Box display='flex' alignItems='center'>
                    <img src={IconPhotosSVG} />
                    {
                        carnet &&
                        <p className={classes.textDownload}>{carnet.nbrPhotos} photos à télécharger</p>
                    }

                </Box>
                <IconButton href={'index.php?rub=250&p=22'} style={{padding : 0}}
                //  onClick={() => this.downloadImages()}
                >
                    <GetAppSVG />
                </IconButton>
            </Paper>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(SouvenirsSettingsCarnetLeftComponent));