import axios from 'axios';


// axios.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem('access_token')}`;
// Add a request interceptor
// axios.interceptors.request.use( (config) => {
//     console.log('ici');
//     const token = window.localStorage.getItem('access_token');
//     config.headers.Authorization =  `Bearer ${token}`;
//     return config;
// });

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = window.localStorage.getItem('access_token');

    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



export * from './animations';
export * from './murs';
export * from './carnet_voyage';
export * from './signalements';
export * from './recherche';
export * from './parametres';
export * from './notifications';
export * from './statistiques';
export * from './contenus';
export * from './video';
export * from './video';
export * from './challenges';
export * from './participants';
export { ReferentielService } from './Referentiel.service'