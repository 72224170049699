import cloneDeep from 'lodash/cloneDeep'
import { ChallengeThemeImage } from './ChallengeThemeImage.class'
export abstract class ChallengeTheme {


    public libelle: string = null;

    public actif: number = null;

    public images: ChallengeThemeImage[]

    public getImageCouv(): ChallengeThemeImage {
        return this.images.filter((image) => image.type == 1)[0];
    }

    constructor(row?: any) {
        if (row) {

            const {
                libelle,
                actif,
                images
            } = row;

            this.libelle = libelle;

            this.actif = actif

            this.images = cloneDeep(images)



        }
    }

}