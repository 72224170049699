import { ChallengeService } from "services";
import { B64File } from "classes/B64File.class";
import { v4 as uuidv4 } from 'uuid';
import ImageDefautCritereSVG from '/front/react/features/admin/challenges/assets/rubrique_classements.svg'
import cloneDeep from 'lodash/cloneDeep'
export class MulticritereCritere {

    static typeClassementToLibelle : {[key : number] : string} =
    {
        0 : 'Résultat/Objectif',
        1 : 'Ratio',
        2 : 'Volume pondéré',
        3 : 'Volume avec décrémentation',
    }
    
    public idCritere : number = null;

    public idChallenge : number = null;

    public idSuivi : number = null;

    public idTypeClassement : number = null;

    public urlImage : B64File = null;
    
    public libelle : string = null;

    public nbMaxPoint : number = null;

    public decrementation : number = null;

    public seuilMini : number = null;

    public coefficientPonderation : number = null;

    // Attributs utile pour l'affichage des classements en front office
    public pointsCritere : number = null;

    public objectifCritere : number = null;

    public resultatCritere : number = null;

    public rsuroCritere : number = null;

    public estTotal : number = null;
    
    public ordre : number = null;

    public ordreSuivi : number = null;

    public uuid : string = uuidv4(); 

    private challengeService = ChallengeService.getInstance();

    constructor(row? : any) {

        if (row) {

            const {
                idCritere,
                idChallenge,
                idSuivi,
                idTypeClassement,
                urlImage,
                libelle,
                nbMaxPoint,
                decrementation,
                seuilMini,
                coefficientPonderation,
                pointsCritere,
                objectifCritere,
                resultatCritere,
                rsuroCritere,
                estTotal,
                ordre
            } = row;

            this.idCritere = idCritere;

            this.idChallenge = idChallenge;

            this.idSuivi = idSuivi;

            this.idTypeClassement = idTypeClassement;

            this.urlImage = urlImage ? (typeof urlImage === 'string' ? B64File.fromDb(urlImage) : cloneDeep(urlImage)) : null

            this.libelle = libelle;

            this.nbMaxPoint = nbMaxPoint;

            this.decrementation = decrementation;

            this.seuilMini = seuilMini;

            this.coefficientPonderation = coefficientPonderation;

            this.pointsCritere = pointsCritere;

            this.objectifCritere = objectifCritere;

            this.resultatCritere = resultatCritere;

            this.rsuroCritere = rsuroCritere;

            this.estTotal = estTotal;

            this.ordre = ordre;

            this.ordreSuivi = ordre;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.MulticritereCritereService.saveMulticritereCritere(this);
    // }

    public toDatabaseObject(): object {
        return {
            idCritere: this.idCritere,
            idChallenge: this.idChallenge,
            idSuivi: this.idSuivi,
            idTypeClassement: this.idTypeClassement,
            urlImage: this.urlImage,
            libelle: this.libelle,
            nbMaxPoint: this.nbMaxPoint,
            decrementation: this.decrementation,
            seuilMini: this.seuilMini,
            coefficientPonderation: this.coefficientPonderation,
            ordre: this.ordre,
        }
    }

    public getCritereImage() : string
    {
        return this.urlImage ? this.urlImage.getSrc() : ImageDefautCritereSVG
    }
}