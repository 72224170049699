import Axios from 'axios';
import qs from 'qs';
import { Contenu } from 'classes/contenus/Contenu.class';
import { ContenuLigne } from 'classes/contenus/ContenuLigne.class';
import { ContenuBloc } from 'classes/contenus/ContenuBloc.class';
import { ContenuBlocBouton } from 'classes/contenus/types_blocs/ContenuBlocBouton.class';
import { ContenuBlocCarte } from 'classes/contenus/types_blocs/ContenuBlocCarte.class';
import { ContenuBlocImage } from 'classes/contenus/types_blocs/ContenuBlocImage.class';
import { ContenuBlocReseaux } from 'classes/contenus/types_blocs/ContenuBlocReseaux.class';
import { ContenuBlocTexte } from 'classes/contenus/types_blocs/ContenuBlocTexte.class';
import { ContenuBlocVideo } from 'classes/contenus/types_blocs/ContenuBlocVideo.class';
import { ContenuWithEverything, LigneAvecBlocs } from 'features/contenus/src/store/types';
import { ContenuBlocSeparateur } from 'classes/contenus/types_blocs/ContenuBlocSeparateur.class';
import { ContenuBlocDotations } from 'classes/contenus/types_blocs/ContenuBlocDotations.class';
import { ContenuBlocDocuments } from 'classes/contenus/types_blocs/ContenuBlocDocuments.class';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { B64File } from 'classes/B64File.class';
import { Document} from 'features/contenus/src/store/types';
import { Challenge } from 'classes/challenges/Challenge.class';
import { ContenuDotation } from 'classes/contenus/ContenuDotation.class';

export class ContenusService {
    private static instance: ContenusService;

    public static getInstance(): ContenusService {
        if (!ContenusService.instance) {
            ContenusService.instance = new ContenusService();
        }

        return ContenusService.instance;
    }

    /* Utils */

    /**
     * Transforme l'array row en bloc en utilisant le bon sous type
     * @param row contenu du bloc
     */
    public mapToBloc(row: any): ContenuBloc {
        switch(row.idTypeBloc) {
            case 1:
                return new ContenuBlocBouton(row);
            case 2: 
                return new ContenuBlocCarte(row);
            case 3: 
                return new ContenuBlocImage(row);
            case 4: 
                return new ContenuBlocReseaux(row);
            case 5: 
                return new ContenuBlocTexte(row);
            case 6: 
                return new ContenuBlocVideo(row);
            case 7:
                return new ContenuBlocSeparateur(row);
            case 8:
                return new ContenuBlocDotations(row);
            case 9:
                return new ContenuBlocDocuments(row);
        }
    }

    /* Contenus */

    /**
     * Envoie une requête pour supprimer un contenu en BDD
     * @param idContenu identifiant du contenu à supprimer
     */
    public async deleteContenu(idContenu: number): Promise<Boolean> {
        const params: any = {
            rub: 291,
            p: 2,
            idContenu
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
    }

    /**
     * Sauvegarde un contenu en base de donnée
     * @param contenu objet Contenu à sauvegarder
     * @param idChallenge éventuel identifiant du challenge associé
     */
    public async saveContenu(contenu: Contenu, idChallenge?: number): Promise<number> {
        const params: any = {
            rub: 291,
            p: 3,
            idChallenge
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ contenu : JSON.stringify(contenu.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }


    /* Lignes */

    public async deleteLigne(idLigne: number): Promise<Boolean> {
        const params: any = {
            rub: 291,
            p: 6,
            idLigne
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
    }

    public async saveLigne(ligne: ContenuLigne): Promise<number> {
        const params: any = {
            rub: 291,
            p: 7,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ ligne : JSON.stringify(ligne.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }


    /* Blocs */

    public async deleteBloc(idBloc: number): Promise<Boolean> {
        const params: any = {
            rub: 291,
            p: 10,
            idBloc
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
    }

    public async saveBloc(bloc: ContenuBloc): Promise<number> {
        const params: any = {
            rub: 291,
            p: 11,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ bloc : JSON.stringify(bloc.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }


    /**
     * Pour charger toutes les infos d'un challenge,
     * y compris ses blocs et lignes, tout en un
     * @param leContenu identifiant du contenu à charger
     */
    public async loadChallengeWithEverything(leContenu : number): Promise<ContenuWithEverything> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 291,
            p: 12,
            leContenu,
        })}`).then(({ data: { content } }) => {

            var ret: ContenuWithEverything = {
                contenu: null,
                lignes: [],
                lignesSupprimees: []
            }

            if (content) {
                ret.contenu = new Contenu(content.contenu);

                content.lignes.map((l: LigneAvecBlocs) => {
                    let ligneRet : LigneAvecBlocs = {
                        ligne: new ContenuLigne(l.ligne),
                        blocs: l.blocs.map((b: ContenuBloc) => this.mapToBloc(b)),
                        blocsSupprimes: []
                    }
                    ret.lignes.push(ligneRet);
                });
            } else {
                ret.contenu = new Contenu();
                ret.lignes = [];
            }

            return ret;
        })
    }

    public async loadChallengeFromContenu(idContenu : number): Promise<Challenge> {
        const params: any = {
            rub: 291,
            p: 14,
            idContenu
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: challenge } }) => new Challenge(challenge));
    }

    public async loadContenuFromChallenge(idChallenge : number): Promise<ContenuWithEverything> {
        const params: any = {
            rub: 291,
            p: 15,
            idChallenge
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: id } }) => this.loadChallengeWithEverything(id));
    }

    /** Special bloc documents */

    public async loadDocuments(idBloc: number): Promise<Document[]> {
        const params: any = {
            rub: 291,
            p: 20,
            idBloc: idBloc
        };

        let docs : Document[] = [];

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => { 
                docs.push({ idBloc, documents: B64File.rowsToArray(content)});
                return docs;
            });
    }

    public addFileDocuments(b64File: B64File, idBloc: number): RxAxios<{ content: B64File }> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: 291,
                p: 21

            })}`,
            data: qs.stringify({
                b64File: JSON.stringify(b64File),
                idBloc: idBloc
            })
        });
    }

    public async removeFileDocuments(b64File: B64File, idBloc: number): Promise<{ b64File: B64File, idBloc: number }> {
        let docs : Document[] = [];
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 291,
                p: 22
            })}`,
            qs.stringify({
                b64File: JSON.stringify(b64File)
            })
        ).then(({ data: { content } }) => {
            return { idBloc, b64File: B64File.rowsToFile(content)};
        });
    }

    public async updateFileDocumentIndex(index: { oldIndex: number, newIndex: number, idBloc: number }): Promise<Document[]> {
        let docs : Document[] = [];

        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 291,
                p: 23
            })}`,
            qs.stringify({
                oldIndex: index.oldIndex,
                newIndex: index.newIndex,
                idBloc: index.idBloc
            })
        ).then(({ data: { content } }) => {
            docs.push({ idBloc: index.idBloc, documents: B64File.rowsToArray(content)});
            return docs;
        });
    }

    public async deleteDotation(idDotation: number): Promise<Boolean> {
        const params: any = {
            rub: rubriques.RUB_ADMIN_CONTENUS,
            p: 24,
            idDotation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
    }

    /**
     * Sauvegarde une dotation en base de donnée
     * @param dotation objet ContenuDotation à sauvegarder
     */
    public async saveDotation(dotation: ContenuDotation): Promise<number> {
        const params: any = {
            rub: rubriques.RUB_ADMIN_CONTENUS,
            p: 25,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ dotation : JSON.stringify(dotation.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }

}