import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/styles'
import LocationOnSVG from '@material-ui/icons/LocationOn';
import { CarnetCategory } from 'classes/carnet_voyage/programme/CarnetCategory.class';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { RootState } from 'store/types';
import { colors } from 'Theme';
import TickSVG from '../../assets/tick.svg';
import { loadSchedule } from '../store/actions';
import style from './ProgrammeCarnet.style';

const stateToProps = ({ carnetVoyage: { schedule, scheduleCategories, isLoading } }: RootState) => ({
    schedule,
    scheduleCategories,
    isLoading
})

const dispatchToProps = {
    loadSchedule: loadSchedule.request
}

interface ProgrammeCarnetLocalProps {
}

interface ProgrammeCarnetState {
    currentTab: number,
    now: Moment,
    mounted: boolean,
    tabIsToday: boolean,
}

type ProgrammeCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ProgrammeCarnetLocalProps;

class ProgrammeCarnet extends Component<ProgrammeCarnetProps, ProgrammeCarnetState> {

    readonly state: ProgrammeCarnetState = {
        currentTab: 0,
        now: moment(),
        mounted: false,
        tabIsToday: false,
    }

    private currentActivityOrangeChecked = false;

    componentDidMount() {
        this.props.loadSchedule();
        this.setState({ mounted: true })
    }

    componentDidUpdate() {
        const { isLoading, schedule } = this.props;
        const { now, tabIsToday } = this.state;
        if (!isLoading && schedule.length > 0) {
            if (!tabIsToday) {
                schedule.forEach((day, index) => {
                    if (now.isSame(day.day, 'day')) {
                        this.setState({ currentTab: index, tabIsToday: true });
                    }
                });
            }
        }
    }


    getTime(dateTime: Moment): Moment {
        return moment({ h: dateTime.hours(), m: dateTime.minutes() });
    }

    render() {
        const { classes, schedule, scheduleCategories } = this.props;
        const { currentTab, now, mounted } = this.state;
        return (
            <Box bgcolor='white' height='100%' paddingBottom={2}>
                <Tabs
                    value={currentTab}
                    onChange={(_event, value) => this.setState({ currentTab: value })}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {schedule.map((day, index) =>
                        <Tab style={{ width: 90 }} key={`day-${index}`} label={`JOUR ${index + 1}`} value={index} />
                    )}
                </Tabs>
                <SwipeableViews
                    index={currentTab}
                    animateHeight={mounted}
                    onChangeIndex={(i) => this.setState({ currentTab: i })}
                // className={classes.swipeableView}
                // slideClassName={classes.swipeableView}
                >

                    {schedule.map((day, index) =>
                        <Box key={`day-${index}`} display='flex' flexDirection='column'>
                            <Box className={classes.dayImage}>
                                {
                                    day.image &&
                                    <Box className={classes.dayImageSrc} style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), transparent 20%), url(${day.image.getSrc()}) ` }} />

                                }
                                <Typography className={classes.dayText}>{day.day.format('dddd D MMMM').charAt(0).toUpperCase() + day.day.format('dddd D MMMM').slice(1)}</Typography>
                            </Box>
                            {
                                [this.currentActivityOrangeChecked = false,
                                day.activities.map((activity, index) =>
                                    <Box key={`${activity.idActivity}` + `${index}`} display='flex' flexDirection='row' paddingX={2} paddingTop={2} >
                                        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginRight={1.5}>
                                            {
                                                (
                                                    now.isAfter(day.day, 'day')
                                                    ||
                                                    (now.isSame(day.day, 'day') && day.activities.length > index + 1 && now.isSameOrAfter(this.getTime(day.activities[index + 1].hour), 'minute'))
                                                )
                                                    ?
                                                    <img src={TickSVG} style={{ width: 20 }} /> // hour > nexActivity
                                                    :
                                                    <Box
                                                        marginTop={'2px'}
                                                        width={20}
                                                        height={20}
                                                        minHeight={20}
                                                        display='flex'
                                                        borderRadius='50%'
                                                        border={`2px solid ${this.currentActivityOrangeChecked ? colors.grey.main : colors.orange.main}`}
                                                    >
                                                        {!this.currentActivityOrangeChecked && <Box style={{ margin: 'auto', backgroundColor: colors.orange.main, borderRadius: '50%', width: 10, height: 10, }} />}
                                                        {this.currentActivityOrangeChecked = true}
                                                    </Box>
                                            }

                                            <Box height='100%' width='1px'
                                                borderLeft={`4px dotted ${colors.lighterGrey.main}`}
                                            // style={{ backgroundImage: 'linear-gradient(black 33%, rgba(255,255,255,0) 0%)' backgroundPosition: 'center' backgroundSize: '1px 3px' backgroundRepeat: 'repeat-y'}} 
                                            />
                                        </Box>
                                        <Box display='flex' flexDirection='column' width='100%' >
                                            <Box display='flex' marginBottom={0.5}>
                                                <Typography className={classes.activityHour} > {activity.hour.format('H[H]mm')}</Typography>
                                                <Typography className={classes.activityCategory} style={{ backgroundColor: CarnetCategory.CarnetCategoryColor[activity.idCategory] }}>{scheduleCategories[activity.idCategory].name}</Typography>
                                            </Box>
                                            <Typography className={classes.activityTitle}>{activity.title} </Typography>
                                            {activity.description &&
                                                <Typography className={classes.activityDescription}>{activity.description}</Typography>
                                            }
                                            {
                                                activity.location &&
                                                <Box display='flex' marginTop={1} alignItems='center'>
                                                    <LocationOnSVG />
                                                    <Typography className={classes.activityLocation}>{activity.location}</Typography>
                                                </Box>
                                            }

                                        </Box>
                                    </Box>
                                )]
                            }

                        </Box>
                    )}
                    {/* {this.tabs.map(({ component: Comp }, index) =>
                        <Comp key={index} index={index} currentTab={currentTab} />
                    )} */}

                </SwipeableViews>
            </Box >
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ProgrammeCarnet));