import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { CarnetVoyageService } from 'services';
import { ItemInfos } from 'features/carnet_voyage/src/store/types';
import IconParticipantsSVG from '../../features/admin/carnet_voyage/assets/items/icon_participants.svg'
import IconSouvenirsSVG from '../../features/admin/carnet_voyage/assets/items/icon_mur_echanges.svg'
import IconProgrammeSVG from '../../features/admin/carnet_voyage/assets/items/icon_programme.svg'
import IconInformationsSVG from '../../features/admin/carnet_voyage/assets/items/icon_infos_pratiques.svg'
import IconHotelSVG from '../../features/admin/carnet_voyage/assets/items/icon_hotel.svg'
import IconContactsSVG from '../../features/admin/carnet_voyage/assets/items/icon_accompagnateurs.svg'
import IconQuestionnaireSVG from '../../features/admin/carnet_voyage/assets/items/icon_questionnaire.svg'
import IconEditoSVG from '../../features/admin/carnet_voyage/assets/items/icon_edito.svg'
import IconTelechargementsSVG from '../../features/admin/carnet_voyage/assets/items/icon_telechargements.svg'

import { colors } from "Theme";
import SouvenirsSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/souvenirs/SouvenirsSettingsCarnet.component";
import SouvenirsSettingsCarnetLeftComponent from 'features/admin/carnet_voyage/src/itemSettings/souvenirs/SouvenirsSettingsCarnetLeftComponent.component';
import ProgrammeSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/programme/ProgrammeSettingsCarnet.component";
import InformationsSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/informations/InformationsSettingsCarnet.component";
import HotelSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/hotel/src/HotelSettingsCarnet.component";
import ContactsSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/contacts/ContactsSettingsCarnet.component";
import QuestionnaireSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/questionnaire/QuestionnaireSettingsCarnet.component";
import EditoSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/edito/EditoSettingsCarnet.component";
import TelechargementsSettingsCarnetComponent from "features/admin/carnet_voyage/src/itemSettings/telechargements/TelechargementsSettingsCarnet.hook.component";

import ParticipantsCarnetComponent from "features/carnet_voyage/src/participants/ParticipantsCarnet.component";
import SouvenirsCarnetComponent from "features/carnet_voyage/src/souvenirs/SouvenirsCarnet.component";
import ProgrammeCarnetComponent from "features/carnet_voyage/src/programme/ProgrammeCarnet.component";
import InformationsCarnetComponent from "features/carnet_voyage/src/informations/InformationsCarnet.component";
import HotelCarnetComponent from "features/carnet_voyage/src/hotel/HotelCarnet.component";
import ContactsCarnetComponent from "features/carnet_voyage/src/contacts/ContactsCarnet.component";
import QuestionnaireCarnetComponent from "features/carnet_voyage/src/questionnaire/QuestionnaireCarnet.component";
import EditoCarnetComponent from "features/carnet_voyage/src/edito/EditoCarnet.component";
import TelechargementsCarnetComponent from "features/carnet_voyage/src/telechargements/TelechargementsCarnet.hook.component";

export class Item {
    public idCarnet: number;

    public idItem: number;

    public active: boolean;

    public online: boolean = false;

    public sequence: number;

    static itemInfos : ItemInfos = {
        0 : {
            title : 'Participants',
            description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis, tortor ut luctus laoreet, enim velit hendrerit magna, id dignissim justo erat quis ipsum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos.',
            subtitle : 'Lorem ipsum',
            image : IconParticipantsSVG,
            color : colors.purple.dark,
            rightComponent : SouvenirsSettingsCarnetComponent,
            frontComponent : ParticipantsCarnetComponent,
            leftComponent : SouvenirsSettingsCarnetLeftComponent
        },
        1 : {
            title : 'Mur d\'échanges',
            description : 'Le mur d’échanges est l’espace social mis à disposition des participants au séminaire. Ils pourront y partager leurs meilleurs souvenirs : textes, photos, vidéos, etc. Ils peuvent également liker, commenter les différentes publications et interagir avec les autres participants. A la fin du séminaire, pensez à télécharger l’ensemble des photos publiées sur le mur pour en faire un album souvenir :)',
            subtitle : 'Les participants peuvent partager tous leurs souvenirs du séminaire\xa0: photos, textes, vidéos, etc.',
            image : IconSouvenirsSVG,
            color : colors.purple.dark,
            rightComponent : SouvenirsSettingsCarnetComponent,
            frontComponent : SouvenirsCarnetComponent,
            leftComponent : SouvenirsSettingsCarnetLeftComponent
        },
        2: {
            title: "Programme",
            description:
                "Partagez le programme de votre séminaire auprès des participants. Programmez jour par jour et renseignez soigneusement les différentes informations possibles : photo de présentation, date, heure, catégorie et intitulé de l’activité. Si vous le souhaitez, vous pouvez y ajouter une description et/ou une localisation pour plus de précision :)",
            subtitle: "Affichez le programme du séminaire et les différentes activités proposées jour par jour",
            image: IconProgrammeSVG,
            color: colors.purple.main,
            rightComponent: ProgrammeSettingsCarnetComponent,
            frontComponent: ProgrammeCarnetComponent
        },
        3: {
            title: "Infos pratiques",
            description:
                "Afin que vos participants n’oublient rien avant de partir et de les informer sur le lieu du séminaire, renseignez toutes les informations pratiques à connaitre avant ou pendant le séjour : formalités, nourriture, climat, etc. Vous pouvez ajouter autant d’informations que vous le souhaitez, les trier par ordre d’importance, y ajouter du texte et une photo d’illustration.",
            subtitle: "Renseignez les diverses informations indispensables à connaitre pour le voyage en cours.",
            image: IconInformationsSVG,
            color: colors.grey.main,
            rightComponent: InformationsSettingsCarnetComponent,
            frontComponent: InformationsCarnetComponent
        },
        4: {
            title: "Hôtel",
            description:
                "C’est toujours sympa et rassurant de voir où l’on va passer la nuit ! Présentez l’hôtel dans lequel séjournera vos participants durant le séminaire et tous les équipements dont ils disposeront durant le séjour. Vous pouvez également y ajouter une description complète, des photos, informations pratiques.",
            subtitle: "Présentez l’hôtel (photos, description, équipements, etc) dans lequel vos participants séjourneront.",
            image: IconHotelSVG,
            color: colors.blue.main,
            rightComponent: HotelSettingsCarnetComponent,
            frontComponent: HotelCarnetComponent
        },
        5: {
            title: "Accompagnateurs",
            description:
                "Vous avez des accompagnateurs durant votre séminaire ? Renseignez-les dans l’application ainsi que leurs coordonnées afin que vos participants sachent qui contacter en cas de besoin d’informations ou d’urgence durant le séminaire.",
            subtitle: "Renseignez les personnes à contacter en cas de besoin ou d’urgence durant le séminaire",
            image: IconContactsSVG,
            color: colors.yellow.dark,
            rightComponent: ContactsSettingsCarnetComponent,
            frontComponent: ContactsCarnetComponent
        },
        6: {
            title: "Questionnaire",
            description:
                "Besoin d’animer votre séminaire avec des quiz ou d’envoyer un questionnaire de satisfaction à la fin du séjour ? Créez vos quiz ou sondages et partagez-les auprès de vos participants pour les challenger, recueillir leurs avis, etc.",
            subtitle: "Mettez en place des quiz pour challengez vos participants ou des sondages pour recueillir des avis.",
            image: IconQuestionnaireSVG,
            color: colors.pink.dark,
            rightComponent: QuestionnaireSettingsCarnetComponent,
            frontComponent: QuestionnaireCarnetComponent
        },
        7: {
            title: "Edito",
            description:
                "Un p’tit mot à dire avant ou pendant le séminaire ? Rédigez et partagez votre message auprès des participants. Vous pouvez y ajouter une image de présentation, un titre, une accroche, votre texte, une photo de l’auteur ainsi que son nom et rôle.",
            subtitle: "Un p’tit mot à dire avant ou pendant le séminaire ? Partagez votre message auprès des participants.",
            image: IconEditoSVG,
            color: colors.blue.dark,
            rightComponent: EditoSettingsCarnetComponent,
            frontComponent: EditoCarnetComponent
        },
        8: {
            title: "Téléchargements",
            description:
                "Besoin de partager des informations, documents auprès des participants du séminaire ? Ajoutez divers documents (PDF, Word, Excel, Powerpoint, Images, etc) que vos participants pourront aussitôt télécharger via leur application.",
            subtitle: "Mettez à disposition divers documents téléchargeables par les participants du séminaire",
            image: IconTelechargementsSVG,
            color: colors.green.main,
            rightComponent: TelechargementsSettingsCarnetComponent,
            frontComponent: TelechargementsCarnetComponent
        }
    };

    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idCarnet, idItem, active, online, sequence } = row;
            this.idCarnet = idCarnet;
            this.idItem = idItem;
            this.active = active;
            this.online = online;
            this.sequence = sequence;
        }
    }

    public toRaw() {
        return {
            idCarnet: this.idCarnet,
            idItem: this.idItem,
            active: this.active,
            online: this.online,
            sequence: this.sequence
        };
    }

    public async save(): Promise<Item> {
        return this.carnetVoyageService.saveItem(this);
    }

    public async switchOnline() : Promise<boolean> {
        return this.carnetVoyageService.switchOnlineItem(this.idItem);
    }
}
