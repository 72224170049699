import { ParticipantsService } from "services";
import { EntiteCaracteristiqueValue } from "./EntiteCaracteristiqueValue.class";
import { EntiteChampValue } from "./EntiteChampValue.class";
import cloneDeep from 'lodash/cloneDeep'

export class Entite {

    public idEntite : string = null;

    public idPere : string = null;

    public idReferentielNiveau : number = null;

    public niveau : number = null;

    public entiteLibelle : string = null;

    public entiteActif : boolean = null;

    public idReferentielMetier : number = null;

    public nbrOfChilds : number = null;

    public nbrOfActeurs : number = null;

    public entiteChampsValues : EntiteChampValue[] = [];
    
    public entiteCaracteristiquesValue : EntiteCaracteristiqueValue[] = [];

    public libellePere : string = null;

    private participantsService = ParticipantsService.getInstance();

    constructor(row? : any) {

        if (row) {

            const {
                idEntite,
                idPere,
                idReferentielNiveau,
                niveau,
                entiteLibelle,
                entiteActif,
                idReferentielMetier,
                nbrOfChilds,
                entiteChampsValues,
                entiteCaracteristiquesValue,
                nbrOfActeurs,
                libellePere
            } = row;

            this.idEntite = idEntite;

            this.idPere = idPere;

            this.idReferentielNiveau = idReferentielNiveau;

            this.niveau = niveau;

            this.entiteLibelle = entiteLibelle;

            this.entiteActif = entiteActif;

            this.idReferentielMetier = idReferentielMetier;

            this.nbrOfChilds = nbrOfChilds;
            
            this.entiteChampsValues = cloneDeep(entiteChampsValues);
            
            this.entiteCaracteristiquesValue = cloneDeep(entiteCaracteristiquesValue);
            
            this.nbrOfActeurs = nbrOfActeurs;

            this.libellePere = libellePere

        }
    }

    public save(entiteChampValues : EntiteChampValue[] = [], entiteCaracteristiqueValues : EntiteCaracteristiqueValue[] = [], estModification : boolean = true) : Promise<string> {
        return this.participantsService.saveEntity(this, entiteChampValues, entiteCaracteristiqueValues, estModification);
    }

    public delete() : Promise<Boolean> {
        return this.participantsService.deleteEntity(this.idEntite);
    }

    public toDatabaseObject(): object {
        return {
            id_entite: this.idEntite,
            id_pere: this.idPere,
            id_referentiel_niveau: this.idReferentielNiveau,
            niveau: this.niveau,
            entite_libelle: this.entiteLibelle,
            entite_actif: this.entiteActif,
            id_referentiel_metier: this.idReferentielMetier
        }
    }
}