import { Answer } from 'classes/animations/Answer.class';
import { QuestionTypes } from './QuestionTypes.enum';
import {v4} from 'uuid'
import Axios from 'axios';
import { B64File } from 'classes/B64File.class';

export class Question {
    public uid: string = v4();

    public idQuestion: number = null;

    public idQuiz: number = null;

    public sequence: number = null;

    public type: QuestionTypes = QuestionTypes.UNIQUE;

    public title: string = '';

    public imageUrl: B64File = null;

    public videoUrl : string = "";

    public videoFrame : string = null;

    public sondage: boolean = false;

    public answers: Answer[] = [];

    constructor(question?: any) {
        
        if (question) {
            const {
                idQuestion, title, type, imageUrl, answers, sequence, idQuiz, sondage, videoUrl, videoFrame
            } = question;
            this.idQuestion = idQuestion;
            this.idQuiz = idQuiz;
            this.title = title;
            this.type = type;
            this.imageUrl = B64File.fromDb(imageUrl);
            this.videoUrl = videoUrl;
            this.videoFrame = videoFrame;
            this.sequence = sequence;
            this.sondage = sondage;
            if (answers) {
                this.answers = answers.map((answer: any) => new Answer(answer));
            }
        }
    }

    
}
