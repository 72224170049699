import Axios from "axios";
import qs from 'qs';
import { Metier } from "classes/referentiel/Metier.class";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import { NiveauInfos, RawDroit, RoleAndRightsToSave } from "features/admin/parametres/src/canaux/src/niveaux/src/store/types";
import { NiveauTypeChamp } from "classes/referentiel/NiveauTypeChamp.class";
import { NiveauChamp } from "classes/referentiel/NiveauChamp.class";
import { Role } from "classes/referentiel/Role.class";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";

export class CanauxService {
    private static instance: CanauxService;

    public static getInstance(): CanauxService {
        if (!CanauxService.instance) {
            CanauxService.instance = new CanauxService();
        }

        return CanauxService.instance;
    }

    public async loadCanaux(): Promise<Metier[]> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 1
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: canaux } }) => canaux.map((metier: any) => new Metier(metier)));

    }

    public async loadCanal(idRefMetier: number): Promise<Metier> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 4,
            idReferentielMetier: idRefMetier
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(error => {
                throw error.response.data.message;
                // throw message;
            })
            .then(({ data: { content: canal } }) => new Metier(canal));

    }

    public async saveMetier(metier: Metier): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 2
            })}`,
            qs.stringify({
                metier: JSON.stringify(metier.toDatabaseObject())
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async deleteMetier(metier: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 3
            })}`,
            qs.stringify({
                idReferentielMetier: metier
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async loadNiveaux(idReferentielMetier: number): Promise<Niveau[]> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 5,
            idReferentielMetier: idReferentielMetier
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: niveaux } }) => niveaux.map((niveau: any) => new Niveau(niveau)));

    }

    public async loadCurrentRefNiveaux(): Promise<Niveau[]> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 19
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: niveaux } }) => niveaux.map((niveau: any) => new Niveau(niveau)));

    }

    public async loadNiveau(idRefNiveau: number): Promise<Niveau> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 6,
            idReferentielNiveau: idRefNiveau
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: niveau } }) => new Niveau(niveau));

    }

    public async saveNiveau(niveau: Niveau): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 7
            })}`,
            qs.stringify({
                niveau: JSON.stringify(niveau.toDatabaseObject())
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async deleteNiveau(idNiveau: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 8
            })}`,
            qs.stringify({
                idReferentielNiveau: idNiveau
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async loadNiveauInfos(idRefNiveau: number): Promise<NiveauInfos> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 11,
            idReferentielNiveau: idRefNiveau
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => {

                var ret: NiveauInfos = {
                    niveau: null,
                    champsInEntite: [],
                    champsInInfosEntite: [],
                    champsOutEntite: [],
                    champsInActeur: [],
                    champsInInfosActeur: [],
                    champsOutActeur: [],
                    roles: [],
                    caracteristiques: []
                }
                ret.niveau = new Niveau(content.niveau);

                ret.champsInEntite = content.champsInEntite.map((typeChamp: NiveauTypeChamp) =>
                    new NiveauTypeChamp(typeChamp)
                );

                ret.champsOutEntite = content.champsOutEntite.map((typeChamp: NiveauTypeChamp) =>
                    new NiveauTypeChamp(typeChamp)
                );

                ret.champsInInfosEntite = content.champsInInfosEntite.map((niveauChamp: NiveauChamp) =>
                    new NiveauChamp(niveauChamp)
                );

                ret.champsInActeur = content.champsInActeur.map((typeChamp: NiveauTypeChamp) =>
                    new NiveauTypeChamp(typeChamp)
                );

                ret.champsOutActeur = content.champsOutActeur.map((typeChamp: NiveauTypeChamp) =>
                    new NiveauTypeChamp(typeChamp)
                );

                ret.champsInInfosActeur = content.champsInInfosActeur.map((niveauChamp: NiveauChamp) =>
                    new NiveauChamp(niveauChamp)
                );

                ret.roles = content.roles.map((role: Role) =>
                    new Role(role)
                );

                ret.caracteristiques = content.caracteristiques.map((caracteristique: NiveauCaracteristique) =>
                    new NiveauCaracteristique(caracteristique)
                );

                return ret;

            });

    }

    public async saveNiveauChamps(niveauChamps: NiveauChamp[], type : number, idReferentielNiveau: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 12
            })}`,
            qs.stringify({
                niveauChamps: JSON.stringify(niveauChamps),
                idReferentielNiveau: idReferentielNiveau,
                type
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async saveNiveauxCaracteristiques(niveauxCaracteristiques: NiveauCaracteristique[], idReferentielNiveau: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 13
            })}`,
            qs.stringify({
                niveauxCaracteristiques: JSON.stringify(niveauxCaracteristiques),
                idReferentielNiveau: idReferentielNiveau
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async loadRoles(idReferentielNiveau: number): Promise<Role[]> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 14,
            idReferentielNiveau: idReferentielNiveau
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: roles } }) => roles.map((role: any) => new Role(role)));

    }

    public async loadRole(idRefRole: number): Promise<Role> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 15,
            idReferentielRole: idRefRole
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: role } }) => new Role(role));

    }

    public async loadRoleListeDroits(idRefNiveau : number): Promise<RawDroit[]> {
        const params: any = {
            rub: 306,
            p: 1,
            sp: 16,
            idReferentielNiveau : idRefNiveau
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => JSON.parse(content));

    }


    public async saveRole(role : Role): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 17
            })}`,
            qs.stringify({
                role: JSON.stringify(role.toDatabaseObject())
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    
    public async deleteRole(idReferentielRole: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 18
            })}`,
            qs.stringify({
                idReferentielRole
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }
}
