import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { BreadcrumbsProps } from '@material-ui/core';
import { navigationSetCurrentModule } from './navigationActions';
import { Module } from './navigationTypes';

const currentModule = createReducer<Module, RootAction>('parametre')
    .handleAction(navigationSetCurrentModule, (state, action) => action.payload)


const navigationReducers = {
    currentModule
}

const navigationReducer = combineReducers(navigationReducers);


export default navigationReducer;
