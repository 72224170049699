import { CanauxService } from "services";

export class Niveau {

    public idReferentielNiveau: number = null;

    public idReferentielMetier: number = null;

    public niveau: number = null;

    public niveauLibelle: string = null;

    public genreLibelle: number = null;

    public ferme: boolean = null;

    public codeEntitePrefixe: string = null;

    public codeEntiteAutomatique: number = null;

    public codeEntiteLibelleFormulaire: string = null;

    public responsableCodeLibelleFormulaire: string = null;

    public affichageAdjoint: boolean = null;

    public adjointLibelleFormulaire: string = null;

    public affichageResponsableClassement: boolean = null;

    private canauxService = CanauxService.getInstance();

    constructor(row?: any) {

        if (row) {

            const {
                idReferentielNiveau,
                idReferentielMetier,
                niveau,
                niveauLibelle,
                genreLibelle,
                ferme,
                codeEntitePrefixe,
                codeEntiteAutomatique,
                codeEntiteLibelleFormulaire,
                responsableCodeLibelleFormulaire,
                affichageAdjoint,
                adjointLibelleFormulaire,
                affichageResponsableClassement,
            } = row;

            this.idReferentielNiveau = idReferentielNiveau;

            this.idReferentielMetier = idReferentielMetier;

            this.niveau = niveau;

            this.niveauLibelle = niveauLibelle;

            this.genreLibelle = genreLibelle;

            this.ferme = ferme;

            this.codeEntitePrefixe = codeEntitePrefixe;

            this.codeEntiteAutomatique = codeEntiteAutomatique;

            this.codeEntiteLibelleFormulaire = codeEntiteLibelleFormulaire;

            this.responsableCodeLibelleFormulaire = responsableCodeLibelleFormulaire;

            this.affichageAdjoint = affichageAdjoint;

            this.adjointLibelleFormulaire = adjointLibelleFormulaire;

            this.affichageResponsableClassement = affichageResponsableClassement;


        }
    }

    public save: () => Promise<boolean> = () => {
        return this.canauxService.saveNiveau(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.canauxService.deleteNiveau(this.idReferentielNiveau);
    }

    public toDatabaseObject(): object {
        return {
            id_referentiel_niveau: this.idReferentielNiveau,
            id_referentiel_metier: this.idReferentielMetier,
            niveau: this.niveau,
            niveau_libelle: this.niveauLibelle,
            genre_libelle: this.genreLibelle,
            ferme: this.ferme,
            code_entite_prefixe: this.codeEntitePrefixe,
            code_entite_automatique: this.codeEntiteAutomatique,
            code_entite_libelle_formulaire: this.codeEntiteLibelleFormulaire,
            responsable_code_libelle_formulaire: this.responsableCodeLibelleFormulaire,
            affichage_adjoint: this.affichageAdjoint,
            adjoint_libelle_formulaire: this.adjointLibelleFormulaire,
            affichage_responsable_classement: this.affichageResponsableClassement,
        }
    }
}