import { ContenusService } from 'services';
import {v4} from 'uuid'

export class ContenuLigne {

    public uid: string = v4();

    public idLigne : number;

    public leContenu : number;

    public indexLigne : number;

    public nbBlocs : number;

    private contenusService = ContenusService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idLigne,
                leContenu,
                indexLigne,
                nbBlocs
            } = row;
            this.idLigne = idLigne;
            this.leContenu =leContenu;
            this.indexLigne = indexLigne;
            this.nbBlocs = nbBlocs;
        }
    }


    public toRaw() {
        return {
            idLigne  : this.idLigne,
            leContenu : this.leContenu,
            indexLigne : this.indexLigne,
            nbBlocs  : this.nbBlocs
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveLigne(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteLigne(this.idLigne);
    }


}
