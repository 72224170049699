import qs from 'qs';
import Axios from 'axios';
import { Animation } from 'classes/animations/Animation.class';
import 'classes/animations/Quiz.class';
import 'classes/animations/concoursPhoto/ConcoursPhoto.class';
import { AnimationTypes } from '../../classes/animations/AnimationTypes.enum';
import { Observable, Subject } from 'rxjs';

export class AnimationsService {
    private static instance: AnimationsService;

    public static getInstance(): AnimationsService {
        if (!AnimationsService.instance) {
            AnimationsService.instance = new AnimationsService();
        }

        return AnimationsService.instance;
    }

    public async findAll(admin?: boolean): Promise<Animation[]> {
        return await Axios.get(`index.php?rub=${(admin ? 22 : 20)}&p=${(admin ? "6" : "1")}`)
            .then(({ data: { content: animations } }) => {return animations.map(this.mapToAnimation)});
    }

    public async find(idAnimation: number, type?: AnimationTypes, admin?: boolean): Promise<Animation> {
        const params: any = {
            rub: admin ? 22 : 20,
            p: admin ? 3 : 2,
            idAnimation,
        };

        if (type) {
            params.type = type;
        }
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: animation } }) => this.mapToAnimation(animation));
    }

    public save(animation: Animation): { progress: Observable<number>, animation: Observable<Animation> } {
        const progressSubject = new Subject<number>();
        const animationSubject = new Subject<Animation>();
        Axios.post(
            `index.php?${qs.stringify({
                rub: 22,
                p: 2
            })}`,
            qs.stringify({
                animation: JSON.stringify(Object.assign(animation.toObject(), {
                    idAnimation: animation.idAnimation,
                    type: animation.type,
                    title: animation.title,
                    summary: animation.summary,
                    beginDate: animation.beginDate.unix(),
                    endDate: animation.endDate.unix(),
                    active: animation.active,
                    frontImage : animation.frontImage,
                    descriptionImage : animation.descriptionImage
                }))
            }),
            {
                onUploadProgress: (progressEvent) => {
                    progressSubject.next(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            }
        ).then(({ data: { content } }) => {
            animationSubject.next(this.mapToAnimation(content));
            animationSubject.complete();
            progressSubject.complete();
        });
        return {
            progress: progressSubject.asObservable(),
            animation: animationSubject.asObservable()
        };
    }

    public async delete(idAnimation: number): Promise<void> {
        const params: any = {
            rub: 22,
            p: 5,
            idAnimation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
        
    }

    public async duplicate(idAnimation: number) : Promise<void> {
        const params: any = {
            rub: 22,
            p: 7,
            idAnimation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
    }

    public async getStatistics(idAnimation: number, type: AnimationTypes) : Promise<any>{
        const params: any = {
            rub: 22,
            p: 4,
            idAnimation,
            type
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: statistics } }) => statistics);
    }

    private mapToAnimation(animation: any): Animation {
        return new Animation.TYPES_ANIMATION[animation.type](animation);
    }
}
