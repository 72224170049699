import {v4} from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';


export class ContenuBlocReseaux extends ContenuBloc {

    public uid: string = v4();

    public facebook : string;
    public twitter : string;
    public linkedin : string;
    public pinterest : string;
    public instagram : string;

    constructor(row?: any) {
        super(row);

        if (row) {
            const { facebook,
                twitter,
                linkedin,
                pinterest,
                instagram } = row;
            this.facebook  = facebook  ?? "";
            this.twitter   = twitter   ?? "";
            this.linkedin  = linkedin  ?? "";
            this.pinterest = pinterest ?? "";
            this.instagram = instagram ?? "";
        }
    }


    public toRaw() {
        return {
            facebook : this.facebook,
            twitter : this.twitter,
            linkedin : this.linkedin,
            pinterest : this.pinterest,
            instagram : this.instagram,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.facebook && !this.twitter && !this.linkedin && !this.pinterest && !this.instagram) return true;
        else return false;
    }


}
