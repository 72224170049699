import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import EventIcon from "@material-ui/icons/Event"
import GetAppIcon from "@material-ui/icons/GetApp"
import MessageIcon from '@material-ui/icons/Message'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { Like } from 'classes/murs/Like.class'
import { Post } from 'classes/murs/Post.class'
import classNames from 'classnames'
import AvatarComponent from 'components/avatar/Avatar.component'
import ConfirmationDialogComponent from 'components/dialog/ConfirmationDialog.component'
import ConfirmationDrawer from 'components/drawer/ConfirmationDrawer.component'
import { MediaQueryProps } from 'components/mediaQueries/MediaQueries.component'
import { push, replace } from 'connected-react-router'
import { navigationPush } from 'store/navigation/navigationActions';
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import moment, { Moment } from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'store/rootStore'
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions'
import { colors, filesIcons, Theme } from 'Theme'
import SuppressionSVG from '../../../../../assets/visuels/suppression.svg'
import EpingleSVG from '../../assets/epingle.svg'
import { getPostAsync, getPostFromIndexAsync, removePostFromList, setShouldPostsListGetUpdated, setTypeMur } from '../store/actions'
import EpinglerPostDialog from './EpinglerPostDialog.component'
import ModifyPostDialog from './ModifyPostDialog.component'
import useStyle from './Post.style'
import SchedulePostDialogComponent from './SchedulePostDialogComponent'
import SignalementDrawer from './SignalementDrawer.component'
import SondageComponent from './Sondage.component'
import ViewCommentsPostDialog from './ViewCommentsPostDialog.component'
import ViewLikesPostDrawer from './ViewLikesPostDrawer.component'




interface PostLocalProps {
    post: Post,
    readOnly?: boolean,
    index?: number,
    onClose?: (ok: boolean) => void,
    removePost?: (index: number) => void,
}


const popoverTheme = (theme: typeof Theme) => {
    return createMuiTheme({
        ...theme,
        overrides: {
            MuiPopover: {
                paper: {

                },

            }
        }
    });
};

type ResponsivePaperProps =
    MediaQueryProps
    & PaperProps


export const ResponsivePaper = ({ children, id }: ResponsivePaperProps) => {
    const classes = useStyle();
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? <Paper id={id} className={classNames(classes.paperPost, classes.paperPostDesktop)} elevation={0}>{children}</Paper> : <Paper className={classes.paperPost} elevation={1} id={id}>{children}</Paper>
}

const PostComponent: React.FC<PostLocalProps> = props => {

    const { post,
        index,
        readOnly,
        onClose,
        removePost
    } = props;


    const classes = useStyle();


    const [
        elements,
        typeMur,
        moi,
        isLoading,
        pathname,
        shouldPostsListGetUpdated
    ] = useSelector((state) => [
        state.murs.elements,
        state.murs.typeMur,
        state.acteur.current,
        state.murs.isLoading,
        state.router.location.pathname,
        state.murs.shouldPostsListGetUpdated
    ], isEqual);

    const dispatch = useDispatch();


    const { typePost } = post;

    const [anchorElPopover, setAnchorElPopover] = useState(null);
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [likePopoverOpen, setLikePopoverOpen] = useState(false);
    const [deletePostDrawerOpen, setDeletePostDrawerOpen] = useState(false);
    const [viewImage, setViewImage] = useState('');
    const [signalerPostDrawerOpen, setSignalerPostDrawerOpen] = useState(false);
    const [epinglerPostDialogOpen, setEpinglerPostDialogOpen] = useState(false);
    const [modifyPostDialogOpen, setModifyPostDialogOpen] = useState(false);
    const [viewCommentsPostDialogOpen, setViewCommentsPostDialogOpen] = useState(false);
    const [viewLikesPostDrawerOpen, setViewLikesPostDrawerOpen] = useState(false);
    const [videoReady, setVideoReady] = useState(false);
    const [postMenuActions, setPostMenuActions] = useState([]);
    const [menuActionsLoaded, setMenuActionsLoaded] = useState(false);
    const [openSchedulePostDialog, setOpenSchedulePostDialog] = useState(false);
    const [postDialogView, setPostDialogView] = useState(false);

    const parentLikes = useRef(null);

    useEffect(() => {

    })
    useEffect(() => {
        window.addEventListener('load', removeViewImageFromPopstate);
        window.addEventListener('popstate', removeViewImageFromPopstate);
        return function cleanup() {
            window.removeEventListener('load', removeViewImageFromPopstate);
            window.removeEventListener('popstate', removeViewImageFromPopstate);
        }
    }, [viewImage]);

    const [openConfirmationDesepinglerDialog, setOpenConfirmationDesepinglerDialog] = useState(false);



    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    const idActionToMethod: { [key: number]: () => void } =
    {
        0: handleModifierClick,
        1: handleSignalerClick,
        2: handleEpinglerClick,
        3: handleFavoriClick,
        4: handleEnLigneClick,
        5: handleProgrammerClick,
        6: handleReprogrammerClick,
        7: handleSupprimerClick,
    }
    // function alterGroupesLikes(typeLike: number, add: boolean) {

    //     var found = false;
    //     for (const [index, groupeLike] of props.post.groupesLikes.entries()) {
    //         if (groupeLike.id_groupe_like === typeLike) {
    //             groupeLike.nbrLikes += add ? 1 : -1;
    //             found = true;
    //             if (!groupeLike.nbrLikes) {
    //                 props.post.groupesLikes.splice(index, 1);
    //             }
    //             break;
    //         }
    //     }
    //     if (!found) {
    //         props.post.groupesLikes.dispatch(navigationPush({ id_groupe_like: typeLike, nbrLikes: 1 }));
    //     }
    // }

    function removeViewImageFromPopstate(this: Window, ev: Event) {
        if (viewImage != '') {
            setViewImage('');


        }
    }

    function updatePost() {
        if (typeMur != post.typeMur) {
            dispatch(setTypeMur(post.typeMur));
        }

        if (index != null) {
            dispatch(getPostFromIndexAsync.request(index));
        } else {
            dispatch(getPostAsync.request(post.idMurPost));
            if (!shouldPostsListGetUpdated) {
                dispatch(setShouldPostsListGetUpdated(true));
            }
        }


    }
    async function handleOnClickLikesMenu(event: React.MouseEvent<HTMLElement>) {
        if (post.monLike) {
            await post.removeLike(typeMur);
            // alterGroupesLikes(props.post.monLike, false);
            updatePost();
        } else {
            setAnchorElPopover(event.currentTarget);
        }

    }

    function handleClosePopoverLikes() {
        setAnchorElPopover(null);
    }

    async function handleClickLike(vent: React.MouseEvent<HTMLImageElement, MouseEvent>, typeLike: number) {
        if (!Boolean(anchorElPopover)) {
            event.preventDefault();
        } else {
            await post.addLike(typeMur, typeLike);
            // post.monLike = typeLike;
            // alterGroupesLikes(typeLike, true);
            updatePost();
            setAnchorElPopover(null);
        }

    }

    function handleOnClickActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorElMenu(event.currentTarget);
    }

    function handleCloseActionsMenu() {
        setAnchorElMenu(null);
    }


    async function handleSupprimerPost(ok: boolean) {
        if (ok) {
            let postRemoved = await post.removePost(typeMur);
            if (postRemoved) {
                if (onClose) {

                    onClose(true);

                } else if (index) {

                    dispatch(removePostFromList(index));

                } else {

                    dispatch(setShouldPostsListGetUpdated(true));

                    dispatch(replace('/'));


                }

                dispatch(newSnackbar({
                    type: SnackbarType.INFO,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: 'Le post a bien été supprimé.'
                    }
                }));

                // updatePost();
                // setPost(null);
            } else {
                dispatch(newSnackbar({
                    type: SnackbarType.WARNING,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: 'Un problème est survenu lors de la suppression du post.'
                    }
                }));
            }
        }
        setDeletePostDrawerOpen(false);

    }

    function handleViewPostClick() {
        if (!readOnly && !pathname.includes('view-post')) {
            if (IS_ADMIN) {
                setPostDialogView(true);
                setModifyPostDialogOpen(true);
            } else {

                const currentUrl = window.location.toString();
                // if (!currentUrl.includes('rub=24')) {
                //     window.location.replace(`/`);
                // } else {
                if (isDesktopOrLaptop) {
                    if (!onClose) {
                        setPostDialogView(true);
                        setModifyPostDialogOpen(true);
                    }

                } else {
                    dispatch(getPostAsync.request(props.post.idMurPost));

                    // if (pathname.length > 1) {
                    //     //TODO : à modifier lorsque tout sera en React
                    //     dispatch(navigationPush(`${pathname.length > 1 ? pathname + '/' : ''}view-post/${props.post.idMurPost}`));
                    // } else {
                    dispatch(navigationPush(`/view-post/${props.post.idMurPost}/`));
                    // }


                }
                // }
            }

        }

    }

    function handleSupprimerClick() {
        setDeletePostDrawerOpen(true);
    }

    function handleModifierClick() {
        if (!isDesktopOrLaptop && !IS_ADMIN && !pathname.includes('modify-post')) {
            //dispatch(navigationPush(`${pathname.length > 1 ? pathname + '/' : ''}modify-post/${props.post.idMurPost}`))

            dispatch(navigationPush(`/modify-post/${props.post.idMurPost}`));




        } else {
            setPostDialogView(false);
            setModifyPostDialogOpen(true);
        }
    }

    function handleEpinglerClick() {
        if (elements['epingler']) {
            if (post.isEpingle || post.willBeEpingle) {
                setOpenConfirmationDesepinglerDialog(true);
            } else {
                setEpinglerPostDialogOpen(true);
            }
        }

    }

    async function handleEpinglerDialogClose(dateDebut?: Moment, dateFin?: Moment) {

        if (dateDebut && dateFin) {
            await props.post.epingler(typeMur, dateDebut.format('YYYYMMDDHHmmss').toString(), dateFin.format('YYYYMMDDHHmmss').toString());
            updatePost();

        }
        setEpinglerPostDialogOpen(false);
    }

    async function handleConfirmationDesepinglerDialogClose(ok: boolean) {
        if (ok && (post.isEpingle || post.willBeEpingle)) {
            await props.post.epingler(typeMur);
            updatePost();
        }
        setOpenConfirmationDesepinglerDialog(false)
    }

    function handleModifyDialogClose(ok: boolean) {
        if (ok) {
            dispatch(setShouldPostsListGetUpdated(true));
        }
        setModifyPostDialogOpen(false);

    }

    function handleViewCommentsPostDialogClose(ok: boolean) {
        setViewCommentsPostDialogOpen(false);
    }

    function handleViewLikesPostDrawerClose(ok: boolean) {
        setViewLikesPostDrawerOpen(false);
    }

    function handleSignalerClick() {
        setSignalerPostDrawerOpen(true);
    }

    function handleFavoriClick() {
        post.favori(post.typeMur ? post.typeMur : typeMur);
        if (removePost) {
            removePost(index);
        }
        updatePost();
    }

    function handleProgrammerClick() {
        setOpenSchedulePostDialog(true);
    }

    function handleReprogrammerClick() {
        setOpenSchedulePostDialog(true);
    }

    async function handleEnLigneClick() {
        let postToEnLigne: Post = cloneDeep(post);
        postToEnLigne.actif = 1;
        postToEnLigne.dateDebutAffichage = moment().format('YYYYMMDDHHmmss');
        await postToEnLigne.publishPost(typeMur);
        updatePost();
    }

    async function handleCloseScheduleDialog(date: Moment) {
        setOpenSchedulePostDialog(false);
        setAnchorElMenu(null);
        if (date) {
            let postScheduled: Post = cloneDeep(post);
            postScheduled.actif = 1
            postScheduled.dateDebutAffichage = date.format('YYYYMMDDHHmmss');
            await postScheduled.publishPost(typeMur);
            updatePost();
        }

    }

    async function handleSignalerPostCloseDrawer(raison: number, commentaire: string) {
        if (raison) {
            let signaledPost = await post.signaler(typeMur, raison, commentaire);
            if (signaledPost) {
                dispatch(newSnackbar({
                    type: SnackbarType.INFO,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Votre signalement a bien été envoyé.`,
                    }
                }));
            } else {
                dispatch(newSnackbar({
                    type: SnackbarType.WARNING,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Il y a eu un problème dans l'envoi de votre signalement`,
                    }
                }));
            }
        }
        setSignalerPostDrawerOpen(false);
    }


    return <Fragment>
        <Paper onClick={readOnly ? (e) => e.stopPropagation() : () => { }} id={`post-${post.idMurPost}-${post.typeMur}`} className={isDesktopOrLaptop ? classNames(classes.paperPost, classes.paperPostDesktop) : classes.paperPost} elevation={0}>
            {/* <Paper elevation={isDesktop ? 0 : 1}> */}
            <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' marginBottom={1} >
                <Box display='flex'>
                    {
                        elements['cm'] && post.idReferentielActeurAuteur && post.idReferentielActeurAuteur != post.idReferentielActeur ?
                            <Tooltip title={`${post.actif === 1 ? 'Publié' : 'Écrit'} par ${post.acteurAuteur.prenom} ${post.acteurAuteur.nom}`}>
                                <Box margin='auto' marginRight={1}>
                                    <AvatarComponent variant='front' acteur={post.acteur} />
                                </Box>
                            </Tooltip>
                            :
                            <Box margin='auto' marginRight={1}>
                                <AvatarComponent variant='front' acteur={post.acteur} />
                            </Box>
                    }

                    <Box display='flex' flexDirection='column' justifyContent='space-evenly'>
                        <Typography className={classes.nameText}><b>{`${post.acteur.prenom} ${post.acteur.nom}`}</b></Typography>
                        <Box display='flex' alignItems='center'>
                            {
                                typePost &&
                                <span className={`badgeAstuce ${typePost.couleur} ${classes.badgeTypePost}`}>{typePost.libelle}</span>

                            }
                            {
                                post.dateDebutAffichage ?
                                    <Typography className={classes.date}>{post.belleDateDebutAffichage}</Typography>
                                    :
                                    <Typography className={classes.date}>{post.belleDateCreationPost}</Typography>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box display='flex' alignItems='center'>
                    {
                        post.actif === 0 &&
                        <Tooltip title={`Post supprimé`}>
                            <VisibilityOffIcon color='secondary' />
                        </Tooltip>
                    }
                    {
                        (post.isEpingle || post.willBeEpingle) &&
                        <Tooltip title={`${post.isEpingle ? 'Détaché' : 'Épinglé'} le ${post.isEpingle ? post.belleDateFinEpingle : post.belleDateDebutEpingle}`}>
                            <Box onClick={handleEpinglerClick} alignItems='center'>
                                {
                                    post.isEpingle ?
                                        <img style={{ cursor: 'pointer' }} src={EpingleSVG} />
                                        :
                                        <EventIcon style={{ color: colors.grey.main }} />

                                }
                            </Box>
                        </Tooltip>
                    }
                    {
                        Object.entries(post.menuActions).length > 0 &&
                        <IconButton style={{ cursor: 'pointer' }} onClick={(e) => handleOnClickActionsMenu(e)}><MoreHorizIcon /></IconButton>

                    }
                </Box>
            </Box>
            <Box >
                {post.post &&
                    <Typography
                        className={classes.contentBox}
                        style={{ whiteSpace: 'pre-line', wordWrap: 'break-word', cursor: 'pointer', textAlign: 'start' }}
                        onClick={() => handleViewPostClick()}>
                        {post.post.length > 200 && index !== undefined
                            ?
                            `${post.idReferentielMetier === -1 ? '[MULTICANAL] ' : ''}${post.post.substring(0, 200)}...`
                            :
                            `${post.idReferentielMetier === -1 ? '[MULTICANAL] ' : ''}${post.post}`}{(post.post.length > 200 && index !== undefined)
                                && <a href='javascript:void(0)' >Voir plus</a>}
                        {post.lienUrl &&
                            <Fragment> ➡️<Link rel="noopener" target="_blank" onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()} href={post.lienUrl}>{' ' + post.lienUrl.replace(/(^\w+:|^)\/\//, '')}</Link></Fragment>
                        }
                    </Typography>
                }

                {!post.fileVideo && post.lienVideo &&
                    <Box>
                        {/* {3265
                        videoReady && */}
                        <ReactPlayer style={{ borderRadius: 5, overflow: "hidden", zIndex: 1 }} config={
                            {
                                vimeo: {
                                    playerOptions: {
                                        transparent: true, color: '#00ADEF', playsinline: true
                                    }
                                }
                            }} height='auto' onReady={() => setVideoReady(true)} className={classes.videoFrame} width="100%" controls url={post.lienVideo} />
                        {/* } */}
                    </Box>
                }
                {post.fileVideo &&
                    <Box style={{ backgroundColor: "black" }} position='relative' display='flex' justifyContent='center' alignItems='center' margin="10px 0" borderRadius={5} overflow="hidden">
                        <video width="100%" style={{ maxHeight: "min(550px, 60vw)" }} playsInline controls poster={post.fileVideo.getSrc() + "_thumbnail.jpg"} preload="metadata">
                            <source type="video/mp4" src={post.fileVideo.getSrc()} />
                        </video>
                    </Box>
                }
                {post.fileImage ?
                    <Box className={classes.boxViewImage} marginTop={1}>
                        <Box className={classes.boxPostImage} onClick={() => { setViewImage(post.fileImage.getSrc()); history.pushState(null, null, window.location.href) }} style={{ cursor: 'pointer', backgroundImage: `url('${post.fileImage.getSrc()}')` }} />
                    </Box>
                    :
                    post.lienImage && <Box className={classes.boxViewImage}><Box className={classes.boxPostImage} onClick={() => { setViewImage(post.oldLienImage); history.pushState(null, null, window.location.href) }} style={{ cursor: 'pointer', backgroundImage: `url(${post.oldLienImage})` }} /></Box>
                }
                {
                    post.fileDocument ?
                        <Paper className={classes.paperDocument} elevation={0} style={{ border: `1px solid ${colors.lighterGrey.light}` }}>
                            <Box display='flex'>
                                <img src={filesIcons[post.fileDocument.getFileTypeFromName()]} style={{ marginRight: Theme.spacing(1) }} />
                                <Typography>{post.fileDocument.name}</Typography>
                            </Box>
                            <IconButton size='small' rel="noopener" target="_blank" href={post.fileDocument.getSrc(true)} >
                                <GetAppIcon />
                            </IconButton>
                        </Paper>
                        :
                        post.lienDocument &&
                        <Paper className={classes.paperDocument} elevation={0} style={{ border: `1px solid ${colors.lighterGrey.light}` }}>
                            <Box display='flex'>
                                <img style={{ marginRight: Theme.spacing(1) }} src={filesIcons['pdf']} />
                                <Typography>{post.nomDocument}</Typography>
                            </Box>
                            <IconButton size='small' href={post.lienDocument} rel="noopener" target="_blank" >
                                <GetAppIcon />
                            </IconButton>
                        </Paper>
                }
                {
                    post.idType === 6 &&
                    <Box marginTop={1} marginBottom={1}>
                        <SondageComponent post={post} />
                    </Box>
                }
            </Box>
            {
                (post.nbLike > 0 || post.nbCommentaire > 0) &&
                <Box display='flex' justifyContent='space-between' width='100%' alignItems='center' marginTop={0.5}>
                    <Box display='flex' alignItems='center' marginLeft='8px'>
                        {
                            post.nbLike > 0 &&
                            <Fragment>
                                {post.groupesLikes.map((value: { id_groupe_like: number }, index) => {
                                    if (index < 3) {
                                        return <img key={`${value.id_groupe_like}-${index}`} src={Like.TYPES_LIKES[value.id_groupe_like]} className={classes.imgGroupeLikeNb} />
                                    }
                                })}
                                <Typography className={classes.nbrLikesCommentsText} onClick={() => setViewLikesPostDrawerOpen(true)} style={{ marginLeft: 5, cursor: 'pointer' }}>{post.nbLike} Like{post.nbLike > 1 && 's'}</Typography>
                            </Fragment>

                        }


                    </Box>
                    {post.nbCommentaire > 0 &&
                        <Typography className={classes.nbrLikesCommentsText} style={{ cursor: 'pointer' }} onClick={() => handleViewPostClick()}>{post.nbCommentaire} Commentaire{post.nbCommentaire > 1 && 's'}</Typography>
                    }
                </Box>
            }

            {
                !readOnly &&
                <div ref={parentLikes} style={{ position: 'relative' }}>
                    <Box display='flex' width='100%' justifyContent='center' alignItems='center' marginTop={1} marginBottom={0.5} >

                        {
                            ((elements[`${IS_ADMIN ? 'admin_' : ''}liker_public`] && post.idReferentielMetier === -1)
                                ||
                                (elements[`${IS_ADMIN ? 'admin_' : ''}liker`] && post.idReferentielMetier !== -1))
                            &&
                            <Box display='flex' marginRight={1} alignItems='center' color={post.monLike ? colors.blue.dark : colors.grey.dark} style={{ cursor: 'pointer' }} onClick={(e) => handleOnClickLikesMenu(e)}>
                                <ThumbUpIcon className={classes.iconLikeCommentActions} />
                                <Typography className={classes.textLikeCommentActions} style={{ color: post.monLike ? colors.blue.dark : colors.grey.dark }}>J'aime</Typography>
                            </Box>
                        }
                        {(index !== undefined &&
                            ((elements[`${IS_ADMIN ? 'admin_' : ''}commenter_public`] && post.idReferentielMetier === -1)
                                ||
                                (elements[`${IS_ADMIN ? 'admin_' : ''}commenter`] && post.idReferentielMetier !== -1)))
                            &&
                            <Box display='flex' marginLeft={1} alignItems='center' color={colors.grey.dark} style={{ cursor: 'pointer' }} onClick={() => handleViewPostClick()}>
                                <MessageIcon className={classes.iconLikeCommentActions} />
                                <Typography className={classes.textLikeCommentActions} style={{ color: colors.grey.dark }} >Commenter</Typography>
                            </Box>
                        }
                    </Box>
                </div>
            }

            {/* </Paper> */}
        </Paper>
        <ThemeProvider theme={popoverTheme}>
            <Popover
                id={`likes-popover-${post.idMurPost}`}
                anchorEl={anchorElPopover}
                open={Boolean(anchorElPopover)}
                container={parentLikes.current}
                onClose={() => handleClosePopoverLikes()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        width: parentLikes && parentLikes.current ? parentLikes.current.offsetWidth : '100%'
                    },
                    className: classes.popoverLikesPaper
                }}>
                {
                    Object.entries(Like.TYPES_LIKES).map(([k, v]) => (
                        <img key={`type_like_${k}`} style={{ cursor: 'pointer' }} onClick={(e) => handleClickLike(e, Number.parseInt(k))} src={v} />
                    ))
                }
            </Popover>
        </ThemeProvider>
        {
            Object.entries(post.menuActions).length > 0 &&
            <Menu
                id={`actions-menu-${post.idMurPost}`}
                anchorEl={anchorElMenu}
                open={Boolean(anchorElMenu)}
                onClose={() => handleCloseActionsMenu()}
                style={{ cursor: 'pointer' }} onClick={() => handleCloseActionsMenu()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {Object.entries(post.menuActions).map(([key, value], index) => {
                    return <MenuItem style={{ border: 0, cursor: 'pointer' }} key={`MenuAction-${key}`} onClick={(e) => idActionToMethod[Number.parseInt(key)]()}>{value.name}</MenuItem>
                })}
                {/* {postMenuActions.map((value, index) => {
                return <MenuItem style={{ border: 0, cursor: 'pointer' }} key={`MenuAction-${index}`} onClick={value.method()}>{value.name}</MenuItem>
            })} */}
            </Menu>
        }

        <ConfirmationDrawer
            title='Supprimer le post'
            // text='En cliquant sur <b>Supprimer</b>, votre post sera définitement supprimé.'
            text={<Fragment>En cliquant sur <b>Supprimer</b>, votre post sera définitivement supprimé.</Fragment>}
            textKO='Annuler'
            textOK='Supprimer'
            onClose={(ok: boolean) => handleSupprimerPost(ok)}
            triggered={deletePostDrawerOpen}
            image={SuppressionSVG} />
        <SignalementDrawer triggered={signalerPostDrawerOpen} onClose={handleSignalerPostCloseDrawer} />

        <EpinglerPostDialog triggered={epinglerPostDialogOpen} onClose={(dateDebut: Moment, dateFin: Moment) => handleEpinglerDialogClose(dateDebut, dateFin)} dateDebut={post.dateDebutEpingle || null} dateFin={post.dateFinEpingle || null} />
        <ViewCommentsPostDialog typeMur={typeMur} post={post} triggered={viewCommentsPostDialogOpen} onClose={(ok: boolean) => handleViewCommentsPostDialogClose(ok)} />
        <ViewLikesPostDrawer typeMur={typeMur} triggered={viewLikesPostDrawerOpen} onClose={(ok: boolean) => handleViewLikesPostDrawerClose(ok)} post={post} />
        <SchedulePostDialogComponent triggered={openSchedulePostDialog} date={post.timestampDateDebutAffichage} onClose={(date: Moment) => handleCloseScheduleDialog(date)} />
        <ConfirmationDialogComponent DialogProps={{ fullWidth: true, open: null }} triggered={openConfirmationDesepinglerDialog} title='Détacher du haut' text='Voulez-vous vraiment détacher ce post ?' onClose={handleConfirmationDesepinglerDialogClose} />
        {
            (isDesktopOrLaptop || IS_ADMIN) &&
            <ModifyPostDialog view={postDialogView} triggered={modifyPostDialogOpen} onClose={(ok: boolean) => handleModifyDialogClose(ok)} idPost={post.idMurPost} typeMur={post.typeMur} />
        }

        {
            viewImage &&
            <Box
                style={{ cursor: 'pointer' }}
                onClick={() => { setViewImage(''); history.back() }}
                display='flex'
                position='fixed'
                top={0}
                left={0}
                width='100%'
                height='100%'
                zIndex={500}
                onKeyPress={(e) => { if (e.key === "Backspace") { setViewImage(''); history.back() } }}
                bgcolor={isDesktopOrLaptop ? 'rgba(0,0,0,0.4)' : 'black'}

            >
                <img className={isDesktopOrLaptop ? classes.imgViewImageBack : classes.imgViewImageFront} src={viewImage} />
            </Box>
        }
    </Fragment>
}

PostComponent.defaultProps = {
    readOnly: false
}

export default PostComponent;
