import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { B64File } from "classes/B64File.class"
import EmptyListComponent from "components/EmptyList.component"
import FileView from "components/fileView/FileView.component"
import LigneHautTableau from "components/LigneHautTableau.component"
import { Pacman } from "components/pacman"
import { addFileTelechargements, loadTelechargements, removeFileTelechargements } from "features/carnet_voyage/src/store/actions"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { newSnackbar, SnackbarType } from "store/snackbars/snackBarsActions"
import { RootState } from "store/types"
import { v4 } from 'uuid'
import TelechargementsVideSVG from "../../../assets/informations_vide.svg"
import style from "./TelechargementsSettingsCarnet.style"

const stateToProps = ({ carnetVoyage: { files, isLoading } }: RootState) => ({
    files,
    isLoading,
});

const dispatchToProps = {
    loadTelechargements: loadTelechargements.request,
    addFileTelechargements: addFileTelechargements.request,
    removeFileTelechargements: removeFileTelechargements.request,
    newSnackbar: newSnackbar
};

interface TelechargementsSettingsCarnetLocalProps {
    loading: any;
}

type TelechargementsSettingsCarnetProps = WithStyles<typeof style> &
    ReturnType<typeof stateToProps> &
    typeof dispatchToProps &
    TelechargementsSettingsCarnetLocalProps;

const TelechargementsSettingsCarnet: React.FC<TelechargementsSettingsCarnetProps> = (props: TelechargementsSettingsCarnetProps) => {
    // Load the page's information.
    useEffect(() => {
        props.loadTelechargements();
    }, []);

    function handleChange(files: FileList) {
        const maxFileSize: number = 50e6;

        // Count the number of too big files
        let invalidCount: number = Array.from(files).filter(file => file.size >= maxFileSize).length;

        // Warn if there is too big files in the selection
        if (invalidCount > 0) {
            props.newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 2000,
                    message: invalidCount + ` fichiers dépassent la taille maximale de ` + maxFileSize / 1e6 + ` MB.`,

                }
            })
        }

        // Add files thare aren't too big
        Array.from(files).forEach(
            (file: File) => {
                if (file.size < maxFileSize) {
                    addFile(file);
                }
            }
        )
    }

    async function addFile(file: File) {
        const b64File = await B64File.fromFormFile(file);
        b64File.localIdFile = v4();
        props.addFileTelechargements(b64File);
    }

    async function removeFile(b64File: B64File) {
        props.removeFileTelechargements(b64File);
    }

    function showFiles() {
        const filesView: JSX.Element[] = [];
        const typeRegex = /.+\.([^\.]+)$/g;

        for (let i = 0; i < props.files.length; i++) {
            const file: B64File = Object.assign(new B64File(props.files[i].name), props.files[i]);
            const name = file.name;
            const type = name.replace(typeRegex, "$1");
            const url = file.idFile ? file.getSrc() : null;
            const progress = file.progress;

            filesView.push(
                <FileView
                    key={file.idFile}
                    admin={true}
                    type={type}
                    name={name}
                    url={url}
                    removeFile={() => removeFile(file)}
                    progressValue={file.idFile ? 100 : (!progress ? 0 : progress)}
                />
            );
        }

        return filesView;
    }

    const { classes, isLoading } = props;

    const componentsTopLine = [
        <Box>
            <input
                multiple
                onChange={event => handleChange(event.target.files)}
                type="file"
                id="add-file-button"
                style={{ display: "none" }}
            />
            <label htmlFor="add-file-button">
                <Button className={classes.firstAddButton} color="primary" variant="contained" size="large" component="span">
                    <AddCircleIcon className={classes.firstAddButtonImg} />
                        Ajouter un document
                    </Button>
            </label>
        </Box>
    ];


    if (isLoading) {
        return <Pacman />;
    }

    return (
        <Box className={classes.root}>
            <LigneHautTableau className={classes.topTextLine} components={componentsTopLine} title="Documents à disposition" />

            <Paper elevation={0} className={classes.paper}>
                {(props.files == null || props.files.length == 0) ? (
                    <Box margin="auto">
                        <EmptyListComponent
                            urlImage={TelechargementsVideSVG}
                            title="C'est vide !"
                            subtitle="Pour ajouter des documents, cliquez sur « Ajouter un document » "
                        />
                    </Box>
                ) : (
                        <Box width="100%" display="flex" minWidth="0px" flexDirection="column">
                            <Box minWidth="0px">{showFiles()}</Box>
                            <label htmlFor="add-file-button">
                                <Button className={classes.secondAddButton} variant="contained" size="large" component="span">
                                    Ajouter un document
                                </Button>
                            </label>
                        </Box>
                    )}
            </Paper>
        </Box>
    );
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(TelechargementsSettingsCarnet));
