import { createAsyncAction, createAction } from "typesafe-actions";
import { LoadStatsState, Murs } from "./types";
import { Post } from "classes/murs/Post.class";

/**
 * Action de chargement des stats d'un mur
 */
export const loadStatsAsync = createAsyncAction('LOAD_STATS_REQUEST', 'LOAD_STATS_SUCCESS', 'LOAD_STATS_FAILURE')<
LoadStatsState,
Post[],
string
>();

/**
 * Action de chargement de la liste des murs
 */
export const loadMursAsync = createAsyncAction('LOAD_MURS_REQUEST', 'LOAD_MURS_SUCCESS', 'LOAD_MURS_FAILURE')<
void,
Murs[],
string
>();