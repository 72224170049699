import moment, { Moment } from 'moment';
import { CarnetVoyageService } from 'services';

export class CarnetActivity {

    public idActivity : number;

    public idDay : number;

    public hour : Moment;

    public title : string;

    public idCategory : number = 0;

    public description : string;

    public location : string;

    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idActivity,
                idDay,
                hour,
                title,
                idCategory,
                description,
                location,
            } = row;
            this.idActivity = idActivity;
            this.idDay = idDay;
            this.hour = hour ? moment(hour, 'X') : null;
            this.title =title;
            this.idCategory = idCategory;
            this.description =description;
            this.location =location;
        }
    }


    public toRaw() {
        return {
            idActivity : this.idActivity,
            idDay : this.idDay,
            hour : this.hour ? this.hour.unix() : null,
            idCategory : this.idCategory,
            title : this.title,
            description : this.description,
            location : this.location,
        };
    }


}
