import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Animation } from 'classes/animations/Animation.class'
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum'
import { colors } from 'Theme'
import IdeaBoxSVG from '../../assets/choix_animation_boite_idees.svg'
import MysteryCardsSVG from '../../assets/choix_animation_cartes_mysteres.svg'
import PhotoContestSVG from '../../assets/choix_animation_concours_photo.svg'
import ScratchGameSVG from '../../assets/choix_animation_grattage.svg'
import QuizSVG from '../../assets/choix_animation_quiz.svg'
import LuckyWheelSVG from '../../assets/choix_animation_roue_chance.svg'
import React from 'react';
import useStyle from './NewAnimationDialog.style';

interface NewAnimationDialogProps{
    open: boolean;
    onClose: (type: number) => void;
}

interface AnimationInfos {
    color: string;
    image: string;
    title: string;
    subtitle: string;
    out: boolean;
}

const NewAnimationDialog : React.FC<NewAnimationDialogProps> = props => {
    const classes = useStyle();

    function getAnimationInfos(type: number): AnimationInfos {
        switch (type) {
            case AnimationTypes.Quiz:
                return ({
                    color: colors.purple.main,
                    image: QuizSVG,
                    title: 'Quiz',
                    subtitle: 'Lancez un quiz ou sondage pour tester les connaissances ou recueillir des avis.',
                    out: true
                })
            case AnimationTypes.IdeaBox:
                return ({
                    color: colors.blue.main,
                    image: IdeaBoxSVG,
                    title: 'Boîte à idées',
                    subtitle: 'Recueillez les meilleures idées de vos salariés sur un thème de votre choix',
                    out: false
                })
            case AnimationTypes.PhotoContest:
                return ({
                    color: colors.blue.dark,
                    image: PhotoContestSVG,
                    title: 'Concours photo',
                    subtitle: 'Animez votre challenge avec un concours photo et récompensez les photos les plus likées !',
                    out: true
                })
            case AnimationTypes.LuckyWheel:
                return ({
                    color : colors.green.main,
                    image : LuckyWheelSVG,
                    title : 'Roue de la chance',
                    subtitle : 'Jouez et faites tourner la grande roue pour tenter de remporter des lots !',
                    out : false
                })
            case AnimationTypes.MysteryCards:
                return ({
                    color : colors.pink.dark,
                    image : ScratchGameSVG,
                    title : 'Jeu de grattage',
                    subtitle : 'Un classique: grattez, grattez... et découvrez tout de suite si vous avez gagné !',
                    out: false
                })
            case AnimationTypes.ScratchGame:
                return ({
                    color : colors.yellow.dark,
                    image : MysteryCardsSVG,
                    title : 'Cartes mystères',
                    subtitle : 'Soulevez l\'une des cartes mystères et découvrez quel lot (ou non) se cache derrière.',
                    out : false
                })
        }
    }

    return (
        <Dialog
            open={props.open}
            maxWidth="lg"
            fullWidth
            onClose={() => props.onClose(0)}
        >
            <DialogTitle className={classes.title}>
                Créer une animation

                <IconButton
                    className={classes.closeButton}
                    onClick={() => props.onClose(0)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid
                    className={classes.grid}
                    container spacing={2}
                    direction="row"
                    justify="center"
                >
                    {Object.keys(Animation.TYPES_ANIMATION).map((type) => {
                        let typeNumber = parseInt(type);
                        let infos = getAnimationInfos(typeNumber);

                        return (
                            <Grid className={classes.gridItem} item key={typeNumber}>
                                <Card className={classes.card}>
                                    <CardActionArea onClick={() => props.onClose(typeNumber)} component="div" className={classes.cardActionArea} disabled={!infos.out}>
                                        <CardMedia className={classes.cardMedia} image={infos.image} style={{ backgroundColor: infos.color }} >
                                            {!infos.out && <div className={classes.blackBackground}><div className={classes.bientotText}>BIENTÔT</div></div>}
                                        </CardMedia>

                                        <CardContent className={classes.cardContent}>
                                            <div className={classes.cardContentTitle}>{infos.title}</div>
                                            <div className={classes.cardContentSubtitle}>{infos.subtitle}</div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )

                    })}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default NewAnimationDialog;
