import { ChallengeService } from "services";
import { ChallengeThemePersonnaliseImage } from "./ChallengeThemePersonnaliseImage.class";
import cloneDeep from 'lodash/cloneDeep'
import { ChallengeThemePredefiniImage } from "./ChallengeThemePredefiniImage.class";
import { ChallengeTheme } from "./ChallengeTheme.class";

export class ChallengeThemePersonnalise extends ChallengeTheme {

    public idThemePersonnalise : number = null;

    public libelle : string = null;

    public actif : number = null;

    public images : ChallengeThemePersonnaliseImage[] = null

    private ChallengeService = ChallengeService.getInstance();

    constructor(row? : any) {
        super(row)
        if (row) {

            const {
                idThemePersonnalise,
                libelle,
                actif,
                images
            } = row;

            this.idThemePersonnalise = idThemePersonnalise;

            this.libelle = libelle;

            this.actif = actif;

            if (images) {
                this.images = images.map((image: any) => new ChallengeThemePersonnaliseImage(image));
            }

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.ChallengeThemeService.saveChallengeTheme(this);
    // }

    public toDatabaseObject(): object {
        return {
            idThemePersonnalise: this.idThemePersonnalise,
            libelle: this.libelle,
            actif : this.actif
        }
    }
}