import { combineEpics } from 'redux-observable';
import * as animations from '../features/animations/src/store/epics';
import * as adminAnimations from '../features/admin/animations/src/store/epics';
import * as challenges from '../features/challenges/src/store/epics';
import * as carnetVoyage from '../features/carnet_voyage/src/store/epics';
import * as acteur from './acteur/acteurEpics';
import * as mur from '../features/murs/src/store/epics'
import * as notifications from './notifications/notificationsEpics'
import * as signalements from '../features/admin/signalements/src/store/epics';
import * as recherche from '../features/admin/recherche/src/store/epics';
import * as statistiques from '../features/admin/statistiques/src/store/epics';
import * as backParametres from '../features/admin/parametres/src/store/epics';
import * as frontParametres from '../features/parametres/src/store/epics';
import * as participants from '../features/admin/participants/src/store/epics';
import * as contenus from '../features/contenus/src/store/epics';
import * as error from './error/errorEpics'
import * as navigation from './navigation/navigationEpics'
import * as video from '../features/video/src/store/epics'
import { RootEpic } from './types';
import { from, of, iif, empty, EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, tap, take } from 'rxjs/operators';

// const initialStoreCallEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
//     filter(isActionOf(initialStoreCallAsync.request)),
//     switchMap(() => from(challengeService.findAll(state$.value.challenges.listChallengesFilters)).pipe(
//         map(initialStoreCallAsync.success),
//         catchError(message => of(initialStoreCallAsync.failure(message))),
//     )),
// );

export default combineEpics(
    ...Object.values(animations),
    ...Object.values(adminAnimations),
    ...Object.values(challenges),
    ...Object.values(acteur),
    ...Object.values(mur),
    ...Object.values(carnetVoyage),
    ...Object.values(notifications),
    ...Object.values(signalements),
    ...Object.values(recherche),
    ...Object.values(statistiques),
    ...Object.values(error),
    ...Object.values(navigation),
    ...Object.values(backParametres),
    ...Object.values(frontParametres),
    ...Object.values(participants),
    ...Object.values(video),
    ...Object.values(contenus),
);
