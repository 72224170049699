import { Challenge } from 'classes/challenges/Challenge.class';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { B64File } from 'classes/B64File.class';
import { ContenuWithEverything, Document } from './types';

// Rubrique présentation
export const loadPresentationAsync = createAsyncAction(
    'LOAD_CONTENU_REQUEST',
    'LOAD_CONTENU_SUCCESS',
    'LOAD_CONTENU_FAILURE',
)<number, ContenuWithEverything, string>();

export const loadChallengeFromContenuAsync = createAsyncAction(
    'LOAD_CHALLENGE_FROM_CONTENU_REQUEST',
    'LOAD_CHALLENGE_FROM_CONTENU_SUCCESS',
    'LOAD_CHALLENGE_FROM_CONTENU_FAILURE',
)<number, Challenge, string>();

export const loadContenuFromChallengeAsync = createAsyncAction(
    'LOAD_CONTENU_FROM_CHALLENGE_REQUEST',
    'LOAD_CONTENU_FROM_CHALLENGE_SUCCESS',
    'LOAD_CONTENU_FROM_CHALLENGE_FAILURE',
)<number, ContenuWithEverything, string>();

export const loadDocuments = createAsyncAction(
    "LOAD_CONTENUS_DOCUMENTS_REQUEST", 
    "LOAD_CONTENUS_DOCUMENTS_SUCCESS", 
    "LOAD_CONTENUS_DOCUMENTS_FAILURE"
)<number, Document[], string>();

export const addFileDocuments = createAsyncAction(
    "ADD_FILE_CONTENUS_DOCUMENTS_REQUEST", 
    "ADD_FILE_CONTENUS_DOCUMENTS_SUCCESS", 
    "ADD_FILE_CONTENUS_DOCUMENTS_FAILURE"
)<{ b64File: B64File, idBloc: number }, { b64File: B64File, idBloc: number }, string>();

export const removeFileDocuments = createAsyncAction(
    "REMOVE_FILE_CONTENUS_DOCUMENTS_REQUEST", 
    "REMOVE_FILE_CONTENUS_DOCUMENTS_SUCCESS", 
    "REMOVE_FILE_CONTENUS_DOCUMENTS_FAILURE"
)<{ b64File: B64File, idBloc: number }, { b64File: B64File, idBloc: number }, string>();

export const updateFileDocumentIndex = createAsyncAction(
    "UPDATE_FILE_INDEX_CONTENUS_DOCUMENTS_REQUEST", 
    "UPDATE_FILE_INDEX_CONTENUS_DOCUMENTS_SUCCESS", 
    "UPDATE_FILE_INDEX_CONTENUS_DOCUMENTS_FAILURE"
)<{ oldIndex: number, newIndex: number, idBloc: number }, Document[], string>();


export const setProgressSaveFiles = createAction('SET_CONTENUS_PROGRESS_SAVE_FILES')<{ b64File: B64File, idBloc: number }>()
