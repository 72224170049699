import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadAdminParametresTypesPostInfosAsync} from "./actions";

// Reducer pour les types de murs ayant des types de posts
const typesMurs = createReducer<{[key : number] : string}, RootAction>([])
    .handleAction(loadAdminParametresTypesPostInfosAsync.success, (_state, action) => action.payload.typesMurs)

const AdminParametresTypesPostReducer = combineReducers({
    typesMurs
});

export default AdminParametresTypesPostReducer;


