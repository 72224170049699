import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { WithStyles, withStyles } from '@material-ui/styles';
import ArrowBackSVG from '@material-ui/icons/ArrowBack'
import Avatar from 'components/avatar/Avatar.component'
import { Pacman } from 'components/pacman'
import { goBack, push, replace } from 'connected-react-router'
import { navigationReplace } from 'store/navigation/navigationActions';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { loadCurrentActeurAsync } from 'store/acteur/acteurActions'
import { RootState } from 'store/types'
import { getPostAsync, setShouldPostsListGetUpdated } from '../store/actions'
import style from './ModifyPost.style'

const stateToProps = ({ acteur: { current }, murs: { typeMur, typesPost, isLoading, post, elements }, router : { location : {pathname }} }: RootState) => ({
    typeMur, typesPost, isLoading, post, elements, moi: current, pathname
})

const dispatchToProps = {
    getPostAsync: getPostAsync.request,
    goBack,
    loadCurrentActeurAsync: loadCurrentActeurAsync.request,
    setShouldPostsListGetUpdated,
    push, 
    navigationReplace
}

interface ModifyPostLocalProps {
    idPost?: number,
    tMur?: number,
    onClose? : () => void
}

interface ModifyPostState {
    statePost: string,
    loadedPost: boolean
}

type ModifyPostProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ModifyPostLocalProps
    & RouteComponentProps<{ id: string }>;

class ModifyPost extends Component<ModifyPostProps, ModifyPostState> {

    readonly state: ModifyPostState = {
        statePost: '',
        loadedPost: false
    }

    public static defaultProps: ModifyPostLocalProps = {
        idPost: 0,
        onClose : ()=>{}
    };

    componentDidMount() {
        const { match, goBack, getPostAsync, moi, loadCurrentActeurAsync, idPost } = this.props;
        var id: number =  idPost ? idPost : Number.parseInt(match.params.id);
        if (isNaN(id) || !id) {
            goBack();
        } else {
            //TODO : Tester en back que le mec a bien le droit de voir ce fichier
            getPostAsync(id)
        }
        if (!moi) {
            loadCurrentActeurAsync();
        }
    }
    componentDidUpdate(prevProps: ModifyPostProps, prevState: ModifyPostState) {
        const { match, goBack, isLoading, getPostAsync, moi, loadCurrentActeurAsync, idPost, post } = this.props;
        const { statePost, loadedPost } = this.state
        var id: number = idPost ? idPost : Number.parseInt(match.params.id);
        if (!isLoading) {
            if (isNaN(id) || !id) {
                goBack();
            }
            if (!post) {
                goBack();
            }
            if (post && !loadedPost) {
                this.setState({
                    statePost: post.post,
                    loadedPost: true
                })
            }
        }

    }

    goBackToPreviousComponent(strToPrev : string){
        const { pathname, navigationReplace } = this.props;
        navigationReplace(pathname.substring(0,pathname.indexOf(strToPrev)));
    }

    async handlePublishClick() {
        const { setShouldPostsListGetUpdated, push, typeMur, goBack } = this.props;
        const { statePost } = this.state
        this.props.post.post = statePost
        await this.props.post.publishPost(typeMur);
        setShouldPostsListGetUpdated(true);
        if (IS_ADMIN) {
            this.props.onClose();
        } else {
            goBack();
        }

    }

    render() {
        const { moi,
            classes,
            post
        } = this.props;
        const {
            statePost
        } = this.state;
        if (!post || !moi) {
            return <Pacman />
        }
        return (
            <Paper className={classes.paperPost} elevation={IS_ADMIN ? 0 : 1}>
                <Box display='flex' margin={1}>
                    <Avatar acteur={moi} variant='front' />
                    <TextField value={statePost} onChange={(event) => { this.setState({ statePost: event.target.value }) }} fullWidth placeholder='Exprimez-vous' multiline={true} rows={5} className={classes.textFieldPost} />
                </Box>
                <Box display='flex' justifyContent='space-between'>
                    <div />
                    <Box minHeight='100%'>
                        <Button onClick={this.handlePublishClick.bind(this)} disabled={!post.post} variant='contained' color='primary'>Publier</Button>
                    </Box>
                </Box>
                {
                    IS_ADMIN &&
                    <Box position='fixed' top={0} left={0} height={56} width={56} zIndex={500} bgcolor='black'>
                        <IconButton onClick={() => this.goBackToPreviousComponent('modify-post')} style={{ height: 56, width: 56, color: 'white' }}>
                            <ArrowBackSVG />
                        </IconButton>
                    </Box>
                }
            </Paper>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ModifyPost));