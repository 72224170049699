import { Moment } from 'moment';
import { CarnetVoyageService } from 'services';
import { B64File } from 'classes/B64File.class';
import { v4 } from 'uuid'
import { HotelBooleanField } from 'features/carnet_voyage/src/store/types';
import WifiIcon from "@material-ui/icons/Wifi";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import LocalLaundryServiceIcon from "@material-ui/icons/LocalLaundryService";
import PoolIcon from "@material-ui/icons/Pool";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import SingleBedIcon from "@material-ui/icons/SingleBedRounded";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import PetsIcon from "@material-ui/icons/Pets";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import ToysIcon from "@material-ui/icons/Toys";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import SpaIcon from "@material-ui/icons/Spa";
import HotTubIcon from "@material-ui/icons/HotTub";
import RowingIcon from "@material-ui/icons/Rowing";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import SportsTennisIcon from "@material-ui/icons/SportsTennis";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import SafeWhiteIcon from '../../../features/carnet_voyage/assets/icon_coffre_fort_blanc.svg'
import SafeGreyIcon from '../../../features/carnet_voyage/assets/icon_coffre_fort_gris.svg'
import NewsPaperWhiteIcon from '../../../features/carnet_voyage/assets/icon_journaux_blanc.svg'
import NewsPaperGreyIcon from '../../../features/carnet_voyage/assets/icon_journaux_gris.svg'
import RoomCleaningWhiteIcon from '../../../features/carnet_voyage/assets/icon_menage_quotidien_blanc.svg'
import RoomCleaningGreyIcon from '../../../features/carnet_voyage/assets/icon_menage_quotidien_gris.svg'

export class Hotel {

    public uid: string = v4();


    public idHotel: number;

    public idCarnet: number;

    public stars: number = 3;


    public image1: B64File = null;


    public image2: B64File = null;


    public image3: B64File = null;


    public image4: B64File = null;


    public image5: B64File = null;


    public image6: B64File = null;


    public name: string = '';


    public description: string = '';


    public location: string = '';


    public phone: string = '';


    public website: string = '';


    public arrivingTime: string = '';


    public mail: string = '';


    public facebook: string = '';


    public wifi: boolean = false;


    public tv: boolean = false;


    public parking: boolean = false;


    public laundry: boolean = false;


    public pool: boolean = false;


    public roomService: boolean = false;


    public barbecue: boolean = false;


    public breakfast: boolean = false;


    public shop: boolean = false;


    public bedKingSize: boolean = false;


    public conferenceRoom: boolean = false;


    public restaurant: boolean = false;


    public snack: boolean = false;


    public noSmoking: boolean = false;


    public airConditioner: boolean = false;


    public sportsHall: boolean = false;


    public spa: boolean = false;


    public jacuzzi: boolean = false;


    public roomCleaning: boolean = false;


    public safe: boolean = false;


    public newspaper: boolean = false;


    public carRental: boolean = false;


    public bar: boolean = false;

    
    public pets: boolean = false;


    public groceryStore: boolean = false;


    public kayak: boolean = false;


    public bicycle: boolean = false;


    public tennis: boolean = false;


    public golf: boolean = false;

    static boolFields: HotelBooleanField[] = [
        {
            field: 'wifi',
            tooltip: 'Wifi',
            icon: WifiIcon,
            materialIcon : true
        },
        {
            field: 'tv',
            tooltip: 'Télévision',
            icon: DesktopWindowsIcon,
            materialIcon : true
        },
        {
            field: 'parking',
            tooltip: 'Parking',
            icon: LocalParkingIcon,
            materialIcon : true
        },
        {
            field: 'laundry',
            tooltip: 'Blanchisserie',
            icon: LocalLaundryServiceIcon,
            materialIcon : true
        },
        {
            field: 'pool',
            tooltip: 'Piscine',
            icon: PoolIcon,
            materialIcon : true
        },
        {
            field: 'roomService',
            tooltip: 'Room service',
            icon: RoomServiceIcon,
            materialIcon : true
        },
        {
            field: 'barbecue',
            tooltip: 'Barbecue',
            icon: OutdoorGrillIcon,
            materialIcon : true
        },
        {
            field: 'breakfast',
            tooltip: 'Petit déjeuner',
            icon: FreeBreakfastIcon,
            materialIcon : true
        },
        {
            field: 'shop',
            tooltip: 'Boutique',
            icon: LocalMallIcon,
            materialIcon : true
        },
        {
            field: 'bedKingSize',
            tooltip: 'Lit King Size',
            icon: SingleBedIcon,
            materialIcon : true
        },
        {
            field: 'conferenceRoom',
            tooltip: 'Salle de réunion',
            icon: BusinessCenterIcon,
            materialIcon : true
        },
        {
            field: 'restaurant',
            tooltip: 'Restaurant',
            icon: RestaurantIcon,
            materialIcon : true
        },
        {
            field: 'snack',
            tooltip: 'Snack',
            icon: FastfoodIcon,
            materialIcon : true
        },
        {
            field: 'noSmoking',
            tooltip: 'Non-fumeur',
            icon: SmokeFreeIcon,
            materialIcon : true
        },
        {
            field: 'airConditioner',
            tooltip: 'Climatisation',
            icon: ToysIcon,
            materialIcon : true
        },
        {
            field: 'sportsHall',
            tooltip: 'Salle de sport',
            icon: FitnessCenterIcon,
            materialIcon : true
        },
        {
            field: 'spa',
            tooltip: 'SPA',
            icon: SpaIcon,
            materialIcon : true
        },
        {
            field: 'jacuzzi',
            tooltip: 'Jacuzzi',
            icon: HotTubIcon,
            materialIcon : true
        },
        {
            field: 'roomCleaning',
            tooltip: 'Ménage quotidien',
            whiteIcon: RoomCleaningWhiteIcon,
            greyIcon: RoomCleaningGreyIcon,
            materialIcon : false
        },
        {
            field: 'safe',
            tooltip: 'Coffre-fort',
            whiteIcon: SafeWhiteIcon,
            greyIcon: SafeGreyIcon,
            materialIcon : false
        },
        {
            field: 'newspaper',
            tooltip: 'Journaux',
            whiteIcon: NewsPaperWhiteIcon,
            greyIcon: NewsPaperGreyIcon,
            materialIcon : false
        },
        {
            field: 'carRental',
            tooltip: 'Location de voiture',
            icon: DirectionsCarIcon,
            materialIcon : true
        },
        {
            field: 'bar',
            tooltip: 'Bar',
            icon: LocalBarIcon,
            materialIcon : true
        },
        {
            field: 'pets',
            tooltip: 'Animaux acceptés',
            icon: PetsIcon,
            materialIcon : true
        },
        {
            field: 'groceryStore',
            tooltip: 'Supérette',
            icon: LocalGroceryStoreIcon,
            materialIcon : true
        },
        {
            field: 'kayak',
            tooltip: 'Kayak',
            icon: RowingIcon,
            materialIcon : true
        },
        {
            field: 'bicycle',
            tooltip: 'Vélo',
            icon: DirectionsBikeIcon,
            materialIcon : true
        },
        {
            field: 'tennis',
            tooltip: 'Tennis',
            icon: SportsTennisIcon,
            materialIcon : true
        },
        {
            field: 'golf',
            tooltip: 'Golf',
            icon: GolfCourseIcon,
            materialIcon : true
        }
    ]

    static stringFields: HotelBooleanField[] = [
        {
            field: 'wifi',
            tooltip: 'Wifi',
            icon: WifiIcon,
            materialIcon : true
        },
        {
            field: 'tv',
            tooltip: 'Télévision',
            icon: DesktopWindowsIcon,
            materialIcon : true
        },
        {
            field: 'parking',
            tooltip: 'Parking',
            icon: LocalParkingIcon,
            materialIcon : true
        },
        {
            field: 'laundry',
            tooltip: 'Blanchisserie',
            icon: LocalLaundryServiceIcon,
            materialIcon : true
        },
        {
            field: 'pool',
            tooltip: 'Piscine',
            icon: PoolIcon,
            materialIcon : true
        },
        {
            field: 'roomService',
            tooltip: 'Room service',
            icon: RoomServiceIcon,
            materialIcon : true
        },
        {
            field: 'barbecue',
            tooltip: 'Barbecue',
            icon: OutdoorGrillIcon,
            materialIcon : true
        },
        {
            field: 'breakfast',
            tooltip: 'Petit déjeuner',
            icon: FreeBreakfastIcon,
            materialIcon : true
        },
        {
            field: 'shop',
            tooltip: 'Boutique',
            icon: LocalMallIcon,
            materialIcon : true
        },
        {
            field: 'bedKingSize',
            tooltip: 'Lit King Size',
            icon: SingleBedIcon,
            materialIcon : true
        },
        {
            field: 'conferenceRoom',
            tooltip: 'Salle de réunion',
            icon: BusinessCenterIcon,
            materialIcon : true
        },
        {
            field: 'restaurant',
            tooltip: 'Restaurant',
            icon: RestaurantIcon,
            materialIcon : true
        },
        {
            field: 'snack',
            tooltip: 'Snack',
            icon: FastfoodIcon,
            materialIcon : true
        },
        {
            field: 'noSmoking',
            tooltip: 'Non-fumeur',
            icon: SmokeFreeIcon,
            materialIcon : true
        },
        {
            field: 'airConditioner',
            tooltip: 'Climatisation',
            icon: ToysIcon,
            materialIcon : true
        },
        {
            field: 'sportsHall',
            tooltip: 'Salle de sport',
            icon: FitnessCenterIcon,
            materialIcon : true
        },
        {
            field: 'spa',
            tooltip: 'SPA',
            icon: SpaIcon,
            materialIcon : true
        },
        {
            field: 'jacuzzi',
            tooltip: 'Jacuzzi',
            icon: HotTubIcon,
            materialIcon : true
        },
        {
            field: 'roomCleaning',
            tooltip: 'Ménage quotidien',
            whiteIcon: RoomCleaningWhiteIcon,
            greyIcon: RoomCleaningGreyIcon,
            materialIcon : false
        },
        {
            field: 'safe',
            tooltip: 'Coffre-fort',
            whiteIcon: SafeWhiteIcon,
            greyIcon: SafeGreyIcon,
            materialIcon : false
        },
        {
            field: 'newspaper',
            tooltip: 'Journaux',
            whiteIcon: NewsPaperWhiteIcon,
            greyIcon: NewsPaperGreyIcon,
            materialIcon : false
        },
        {
            field: 'carRental',
            tooltip: 'Location de voiture',
            icon: DirectionsCarIcon,
            materialIcon : true
        },
        {
            field: 'bar',
            tooltip: 'Bar',
            icon: LocalBarIcon,
            materialIcon : true
        },
        {
            field: 'pets',
            tooltip: 'Animaux acceptés',
            icon: PetsIcon,
            materialIcon : true
        },
        {
            field: 'groceryStore',
            tooltip: 'Supérette',
            icon: LocalGroceryStoreIcon,
            materialIcon : true
        },
        {
            field: 'kayak',
            tooltip: 'Kayak',
            icon: RowingIcon,
            materialIcon : true
        },
        {
            field: 'bicycle',
            tooltip: 'Vélo',
            icon: DirectionsBikeIcon,
            materialIcon : true
        },
        {
            field: 'tennis',
            tooltip: 'Tennis',
            icon: SportsTennisIcon,
            materialIcon : true
        },
        {
            field: 'golf',
            tooltip: 'Golf',
            icon: GolfCourseIcon,
            materialIcon : true
        }
    ]
    
    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idHotel,
                idCarnet,
                stars,
                image1,
                image2,
                image3,
                image4,
                image5,
                image6,
                name,
                description,
                location,
                phone,
                website,
                arrivingTime,
                mail,
                facebook,
                wifi,
                tv,
                parking,
                laundry,
                pool,
                roomService,
                barbecue,
                breakfast,
                shop,
                bedKingSize,
                conferenceRoom,
                restaurant,
                snack,
                noSmoking,
                airConditioner,
                sportsHall,
                spa,
                jacuzzi,
                roomCleaning,
                safe,
                newspaper,
                carRental,
                bar,
                pets,
                groceryStore,
                kayak,
                bicycle,
                tennis,
                golf,
            } = row;
            this.idHotel = idHotel;
            this.idCarnet = idCarnet;
            this.stars = stars;
            this.image1 = B64File.fromDb(image1);
            this.image2 = B64File.fromDb(image2);
            this.image3 = B64File.fromDb(image3);
            this.image4 = B64File.fromDb(image4);
            this.image5 = B64File.fromDb(image5);
            this.image6 = B64File.fromDb(image6);
            this.name = name;
            this.description = description;
            this.location = location;
            this.phone = phone;
            this.website = website;
            this.arrivingTime = arrivingTime;
            this.mail = mail;
            this.facebook = facebook;
            this.wifi = wifi;
            this.tv = tv;
            this.parking = parking;
            this.laundry = laundry;
            this.pool = pool;
            this.roomService = roomService;
            this.barbecue = barbecue;
            this.breakfast = breakfast;
            this.shop = shop;
            this.bedKingSize = bedKingSize;
            this.conferenceRoom = conferenceRoom;
            this.restaurant = restaurant;
            this.snack = snack;
            this.noSmoking = noSmoking;
            this.airConditioner = airConditioner;
            this.sportsHall = sportsHall;
            this.spa = spa;
            this.jacuzzi = jacuzzi;
            this.roomCleaning = roomCleaning;
            this.safe = safe;
            this.newspaper = newspaper;
            this.carRental = carRental;
            this.bar = bar;
            this.pets = pets;
            this.groceryStore = groceryStore;
            this.kayak = kayak;
            this.bicycle = bicycle;
            this.tennis = tennis;
            this.golf = golf;
        }
    }


    public toRaw() {
        return {
            idHotel: this.idHotel,
            idCarnet: this.idCarnet,
            stars: this.stars,
            image1: this.image1,
            image2: this.image2,
            image3: this.image3,
            image4: this.image4,
            image5: this.image5,
            image6: this.image6,
            name: this.name,
            description: this.description,
            location: this.location,
            phone: this.phone,
            website: this.website,
            arrivingTime: this.arrivingTime,
            mail: this.mail,
            facebook: this.facebook,
            wifi: this.wifi,
            tv: this.tv,
            parking: this.parking,
            laundry: this.laundry,
            pool: this.pool,
            roomService: this.roomService,
            barbecue: this.barbecue,
            breakfast: this.breakfast,
            shop: this.shop,
            bedKingSize: this.bedKingSize,
            conferenceRoom: this.conferenceRoom,
            restaurant: this.restaurant,
            snack: this.snack,
            noSmoking: this.noSmoking,
            airConditioner: this.airConditioner,
            sportsHall: this.sportsHall,
            spa: this.spa,
            jacuzzi: this.jacuzzi,
            roomCleaning: this.roomCleaning ,
            safe: this.safe ,
            newspaper: this.newspaper ,
            carRental: this.carRental ,
            bar: this.bar,
            pets: this.pets,
            groceryStore: this.groceryStore,
            kayak: this.kayak,
            bicycle: this.bicycle,
            tennis: this.tennis,
            golf: this.golf,
        };
    }


}
