import { ChallengeService } from "services"
import moment, { Moment } from "moment";
import { B64File } from "classes/B64File.class";
import { MulticritereCritere } from "./MulticritereCritere.class";
import { MulticritereGroupe } from "./MulticritereGroupe.class";

export class Suivi {

    static typeSuiviTitle: string[] = [
		"Mécanique simple",
		"Mécanique existante",
		"Mécanique à construire",
        "Mécanique multicritère",
	]

    public idSuivi : number = null;

    public leChallenge : number = null;

    public idType : number = null;

    public libelle : string = null;

    public dateDebut : Moment = null;

    public dateFin : Moment = null;

    public actif : number = null;

    public idEntiteNiveau : number = null;

    public filtreGroupe : number = null;

    public libelleNiveau : string = null;

    public criteres : MulticritereCritere[] = null;

    public groupes : MulticritereGroupe[] = null;

    public nbrCritereWithObjectif : number = null;

    public hasGroupeImported : boolean = false
    
    public hasObjectifsImported : boolean = false

    private challengeService = ChallengeService.getInstance();

    constructor(row? : any) {

        if (row) {

            const {
                idSuivi,
                leChallenge,
                idType,
                libelle,
                dateDebut,
                dateFin,
                actif,
                idEntiteNiveau,
                filtreGroupe,
                criteres,
                groupes,
                libelleNiveau,
                nbrCritereWithObjectif,
                hasGroupeImported,
                hasObjectifsImported
            } = row;

            this.idSuivi = idSuivi;

            this.leChallenge = leChallenge;

            this.idType = idType;

            this.libelle = libelle;

            this.dateDebut =  moment(dateDebut, 'X');;

            this.dateFin =  moment(dateFin, 'X');;

            this.actif = actif;

            this.idEntiteNiveau = idEntiteNiveau;

            this.filtreGroupe = filtreGroupe;

            this.libelleNiveau = libelleNiveau;

            this.nbrCritereWithObjectif = nbrCritereWithObjectif;
            
            if (criteres) {
                this.criteres = criteres.map((critere: any) => new MulticritereCritere(critere));
            }
            if (groupes) {
                this.groupes = groupes.map((groupe: any) => new MulticritereGroupe(groupe));
            }

            this.hasGroupeImported = hasGroupeImported

            this.hasObjectifsImported = hasObjectifsImported

        }
    }

    public async save(criteres : MulticritereCritere[]) : Promise<string> {
        
        return this.challengeService.saveSuivi(this, criteres);
    }
    public delete : () => Promise<string> = () => {
        return this.challengeService.deleteSuivi(this);
    }

    public importFile (file : B64File, sp? : number) : Promise<{ success? : string, error? : string, level? : number}> {
        return this.challengeService.importFile(this.idSuivi, file, sp)
    }

    public toDatabaseObject(): object {
        return {
            idSuivi: this.idSuivi,
            leChallenge: this.leChallenge,
            idType: this.idType,
            libelle: this.libelle,
            dateDebut: this.dateDebut.unix(),
            dateFin: this.dateFin.unix(),
            actif: this.actif,
            idEntiteNiveau: this.idEntiteNiveau,
            filtreGroupe: this.filtreGroupe
        }
    }
}