import { routerMiddleware } from 'connected-react-router';
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';
import { createHashHistory } from 'history';
import { applyMiddleware, compose as reduxCompose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { QuizService } from 'services/animations/Quiz.service';
import { AnimationsService, CarnetVoyageService, MursService, NotificationsService, ReferentielService, ParametresService, CanauxService, RechercheService, SignalementsService, ParticipantsService, StatistiquesService, ChallengeService, ContenusService, VideoService, ConcoursPhotoService } from '../services';
import rootEpics from './rootEpic';
import rootReducer from './rootReducer';
import { RootAction, RootDependencies, RootState } from './types';

export const history = createHashHistory();

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
    dependencies: {
        animationService: AnimationsService.getInstance(),
        challengeService: ChallengeService.getInstance(),
        quizService: QuizService.getInstance(),
        referentielService: ReferentielService.getInstance(),
        mursService: MursService.getInstance(),
        carnetVoyageService: CarnetVoyageService.getInstance(),
        notificationsService: NotificationsService.getInstance(),
        signalementsService: SignalementsService.getInstance(),
        rechercheService: RechercheService.getInstance(),
        statistiquesService: StatistiquesService.getInstance(),
        parametresService: ParametresService.getInstance(),
        canauxService: CanauxService.getInstance(),
        participantsService: ParticipantsService.getInstance(),
        contenusService: ContenusService.getInstance(),
        videoService: VideoService.getInstance(),
        concoursPhotoService: ConcoursPhotoService.getInstance()
    },
});

const compose: typeof reduxCompose = process.env.NODE_ENV === 'development' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : reduxCompose;

const middlewares = [createLogger(), routerMiddleware(history), epicMiddleware];

const enhancer = compose(applyMiddleware(...middlewares));

const RootStore = createStore(rootReducer(history), {}, enhancer);

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

epicMiddleware.run(rootEpics);

export default RootStore;
