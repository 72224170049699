import { Moment } from 'moment';
import { ContenusService } from 'services';
import {v4} from 'uuid'

export class Contenu {

    public uid: string = v4();

    public idContenu : number;

    public titre : string;

    public actif : boolean;

    public dateDebut : Moment;

    public dateFin : Moment;

    private contenusService = ContenusService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idContenu,
                titre,
                actif,
                dateDebut,
                dateFin
            } = row;
            this.idContenu = idContenu;
            this.titre =titre;
            this.actif = actif;
            this.dateDebut =dateDebut;
            this.dateFin = dateFin;
        }
    }


    public toRaw() {
        return {
            idContenu : this.idContenu,
            titre : this.titre,
            actif : this.actif,
            dateDebut : this.dateDebut,
            dateFin : this.dateFin
        };
    }

    public save(idChallenge?:number): Promise<number> {
        return this.contenusService.saveContenu(this, idChallenge);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteContenu(this.idContenu);
    }


}
