import Axios from "axios";
import qs from "qs";
import { LoadStatsState, Murs } from "features/admin/statistiques/src/store/types";
import { Post } from "classes/murs/Post.class";
import { plainToClass } from "class-transformer";
import { Mur } from "classes/murs/Mur.class";
import { forEach } from "lodash";

export class StatistiquesService {
    private static instance: StatistiquesService;

    public static getInstance(): StatistiquesService {
        if (!StatistiquesService.instance) {
            StatistiquesService.instance = new StatistiquesService();
        }

        return StatistiquesService.instance;
    }

    private static rubriqueRecherche = 304;

    public async loadStats(filters: LoadStatsState): Promise<Post[]> {
        const params: any = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 1, /* Code pour avoir les données en JSON */
            mur : filters.mur,
            limit : filters.limit
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: posts } }) => posts.map((post: any) => plainToClass(Post, post)));
    }

    public async loadMurs(): Promise<Murs[]> {
        const params: any = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 2, /* Code pour avoir les données en JSON */
        };
        
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: murs } }) => murs.map(function(mur: any) {
                return {id: mur.id, name: mur.name}
        }));
    }
}
