import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { WithStyles, withStyles } from '@material-ui/styles';
import { AnimationStatus } from 'classes/animations/AnimationStatus.enum';
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { Quiz } from 'classes/animations/Quiz.class';
import EmptyListComponent from 'components/EmptyList.component';
import { Pacman } from 'components/pacman';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { colors } from 'Theme';
import NoAnimationSVG from '../../assets/animations/aucune_animation.svg';
import quizSVG from '../../assets/animations/quiz.svg';
import photoContestSVG from '../../assets/animations/photoCompetition.svg';
import style from './AnimationList.style';
import { setHeaderStore } from 'features/header/src/store/actions'
import { withRouter } from 'react-router-dom';
import { navigationPush, navigationSetCurrentModule } from 'store/navigation/navigationActions';
import { RouteComponentProps } from 'react-router-dom';
import { Animation } from 'classes/animations/Animation.class';

const mapStateToProps = ({ animations }: RootState) => ({
    animations: animations.list,
});

const dispatchToProps = {
    push,
    setHeaderStore,
    navigationPush
}
interface LocalProps {
    carnet: boolean
}

type AnimationListProps = ReturnType<typeof mapStateToProps> & WithStyles<typeof style> & LocalProps & typeof dispatchToProps & RouteComponentProps<{}>;

class AnimationListComponent extends Component<AnimationListProps> {


    componentDidMount(): void {
        this.props.setHeaderStore({
            pageTitle: 'Animations',
            leftComponents: [
            ],
            rightComponents: []
        });
    }
    getAnimationCover(animationType: number) {
        switch (animationType) {
            case AnimationTypes.Quiz:
                return quizSVG;
            case AnimationTypes.PhotoContest:
                return photoContestSVG;
        }
    }

    getLabelColor(status: AnimationStatus): string {
        switch (status) {
            case AnimationStatus.INCOMING:
                return colors.yellow.dark;

            case AnimationStatus.ONGOING:
                return colors.green.main;

            case AnimationStatus.FINISHED:
                return colors.pink.main;
        }
    }

    getCoverBackgroundColor(type: AnimationTypes): string {
        switch (type) {
            case AnimationTypes.Quiz:
                return colors.purple.main;
            case AnimationTypes.PhotoContest:
                return colors.blue.dark;
        }
    }

    handleAnimationClick(idAnimation: number) {
        const { carnet, push, navigationPush } = this.props;
        if (carnet) {
            window.location.replace(`index.php?rub=20#/${idAnimation}`);
        } else {
            navigationPush(`/${idAnimation}/`)
        }
    }

    render() {
        if (this.props.animations === null) {
            return <Pacman />;
        }
        const { classes, animations, carnet } = this.props;

        return (
            <div className={classes.animationsList}>
                {animations.length === 0 && <div className={classes.emptyListContainer}>
                    <EmptyListComponent
                        urlImage={NoAnimationSVG}
                        title="Désolé :("
                        subtitle="Il n'y a pas encore d'animation en cours"
                    />
                </div>}

                {animations.map((animation , index ) => {
                    // if (!!carnet == (animation.type === AnimationTypes.Quiz && (animation as Quiz).isCarnet)) {
                    return <Grow in timeout={1000 * Math.log(index + 2) / 2} key={index}>
                        <Paper elevation={0} onClick={this.handleAnimationClick.bind(this, animation.idAnimation)} className={classes.animation}>
                            <div className={classes.image} style={{
                                backgroundImage: `url(${animation.frontImage ? animation.frontImage.getSrc() : this.getAnimationCover(animation.type)})`,
                                backgroundColor: (animation.frontImage ? colors.white.main : this.getCoverBackgroundColor(animation.type)),
                                backgroundSize: 'cover'
                            }} />
                            <div className={classes.description}>
                                <p className={classes.typeName}>{animation.typeName}</p>
                                <h3 className={classes.title}>{animation.title}</h3>
                                <p className={classes.date}>
                                    Du <b>{animation.beginDate.format('L')}</b> au <b>{animation.endDate.format('L')}</b>
                                </p>
                            </div>
                            <Chip
                                size="small"
                                label={animation.getStatusLabel()}
                                className={classes.statusLabel}
                                style={{
                                    backgroundColor: this.getLabelColor(animation.getStatus()),
                                }}
                            />
                        </Paper>
                    </Grow>
                    // } else {
                    //     return <div></div>
                    // }
                })}
            </div>
        );
    }
}

export default withRouter(withStyles(style)(connect(mapStateToProps, dispatchToProps)(AnimationListComponent)));
