import Axios from "axios";
import qs from 'qs';
import { ArianeInfo, NiveauEntitiesInfos, ListRequestParameters, CreateModifyEntityInfos } from "features/admin/participants/src/store/types";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import cloneDeep from 'lodash/cloneDeep'
import { EntiteChampValue } from "classes/referentiel/EntiteChampValue.class";
import { EntiteCaracteristiqueValue } from "classes/referentiel/EntiteCaracteristiqueValue.class";
import { Acteur } from "classes/Acteur.class";
import { plainToClass } from "class-transformer";
import { Role } from "classes/referentiel/Role.class";
import { ActeurEntiteRole } from "classes/referentiel/ActeurEntiteRole.class";

export class ParticipantsService {
    private static instance: ParticipantsService;

    public static getInstance(): ParticipantsService {
        if (!ParticipantsService.instance) {
            ParticipantsService.instance = new ParticipantsService();
        }

        return ParticipantsService.instance;
    }

    public async loadArianeInfos(): Promise<ArianeInfo[]> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 3,
            p: 1,
        })}`).then(({ data: { content } }) => content);
    }

    public async loadEntities(niveau?: number, idPere?: string): Promise<Entite[]> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 3,
            p: 2,
            niveau: niveau || null,
            idPere: idPere || null
        })}`).then(({ data: { content } }) => content.map((entite: any) => new Entite(entite)));
    }


    public async loadNiveau(niveau: number): Promise<Niveau> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 3,
            p: 3,
            niveau
        })}`).then(({ data: { content } }) => new Niveau(content))
    }

    public async loadEntity(idEntite: string): Promise<Entite> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 3,
            p: 4,
            idEntite
        })}`).then(({ data: { content } }) => new Entite(content))
    }

    public async loadNiveauEntitiesInfos(parametersInfos?: ListRequestParameters): Promise<NiveauEntitiesInfos> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 3,
            p: 4,
            ...parametersInfos,
        })}`).then(({ data: { content } }) => {
            var ret: NiveauEntitiesInfos = {
                entities: [],
                niveau: null,
                arianeInfos: [],
                currentEntity: null,
                fatherEntity: null,
                niveauMax: null,
                niveauPere: null,
                niveauChamps: null,
                niveauCaracteristiques: null,
                actors: null,
                roles: null
            }
            ret.niveau = new Niveau(content.niveau);

            ret.niveauPere = new Niveau(content.niveauPere);

            ret.entities = content.entities.map((entity: Entite) =>
                new Entite(entity)
            );

            ret.actors = content.actors.map((actor: Acteur) =>
                plainToClass(Acteur, actor)
            );

            ret.arianeInfos = content.arianeInfos;

            ret.fatherEntity = content.fatherEntity ? new Entite(content.fatherEntity) : null;

            ret.currentEntity = content.currentEntity ? new Entite(content.currentEntity) : null;

            ret.niveauMax = content.niveauMax;

            ret.niveauCaracteristiques = cloneDeep(content.niveauCaracteristiques);

            ret.niveauChamps = cloneDeep(content.niveauChamps);

            ret.roles = content.roles.map((role: Role) =>
                new Role(role)
            );

            return ret;
        })
    }

    public async loadCreateModifyEntityInfos(parametersInfos?: ListRequestParameters): Promise<CreateModifyEntityInfos> {
        return Axios.get(`index.php?${qs.stringify({
            rub: 3,
            p: 5,
            ...parametersInfos,
        })}`).then(({ data: { content } }) => {

            var ret: CreateModifyEntityInfos = {
                niveau: null,
                currentEntity: null,
                niveauChamps: null,
                niveauMax: null,
                niveauCaracteristiques: null,
                entities: null,
                niveauPere: null,
                fatherEntity: null,
                roles: null,
                acteur: null
            }

            console.log(content, content.acteur);
            ret.niveau = new Niveau(content.niveau);

            ret.niveauPere = new Niveau(content.niveauPere);

            ret.currentEntity = content.currentEntity ? new Entite(content.currentEntity) : null;

            ret.fatherEntity = content.fatherEntity ? new Entite(content.fatherEntity) : null;

            ret.niveauMax = content.niveauMax;

            ret.niveauCaracteristiques = cloneDeep(content.niveauCaracteristiques);

            ret.niveauChamps = cloneDeep(content.niveauChamps);

            ret.entities = content.entities.map((entity: Entite) =>
                new Entite(entity)
            );

            ret.roles = content.roles.map((role: Role) =>
                new Role(role)
            );

            ret.acteur = plainToClass(Acteur, content.acteur);
            // ret.actors = content.actors.map((actor: Acteur) =>
            //     plainToClass(Acteur, actor)
            // );


            return ret;
        })
    }

    public async saveEntity(entity: Entite, entiteChampValues: EntiteChampValue[], entiteCaracteristiqueValues: EntiteCaracteristiqueValue[], estModification : boolean): Promise<string> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 6,

            })}`,
            qs.stringify({
                entity: JSON.stringify(entity.toDatabaseObject()),
                entiteChampValues: JSON.stringify(entiteChampValues),
                entiteCaracteristiqueValues: JSON.stringify(entiteCaracteristiqueValues),
                estModification: JSON.stringify(estModification)
            })
        )
            .then(({ data: { content } }) => '')
            .catch(function ({ response: { data: { message } } }) {
                return message;
            });

    }

    public async deleteEntity(idEntite: string): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 7,

            })}`,
            qs.stringify({
                idEntite
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async deleteActeurEntiteRole(idEntite: string, idActeur: string): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 11,

            })}`,
            qs.stringify({
                idEntite,
                idActeur
            })
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

    public async saveActeur(act: Acteur, acteurCaracteristiqueValues?: EntiteCaracteristiqueValue[], acteurEntiteRole?: ActeurEntiteRole, estModification? : boolean): Promise<boolean> {
        let str: { [key: string]: string } = {
            acteur: JSON.stringify(act.toDatabaseObject())
        }

        if (acteurCaracteristiqueValues) {
            str["acteurCaracteristiqueValues"] = JSON.stringify(acteurCaracteristiqueValues);
        }
        if (acteurEntiteRole) {
            str["acteurEntiteRole"] = JSON.stringify(acteurEntiteRole.toDatabaseObject());
        }
        str["estModification"] = JSON.stringify(estModification);

        console.log('STR', str);
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 9,

            })}`,
            qs.stringify(str)
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);

    }

}
