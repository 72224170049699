import { AxiosError } from 'axios';
import { ActeurAnswer } from 'classes/animations/ActeurAnswer.class';
import { Participation } from 'classes/animations/Participation.class';
import { QuizBadge } from 'classes/animations/QuizBadge.class';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const loadParticipationAsync = createAsyncAction(
    'LOAD_PARTICIPATION_REQUEST',
    'LOAD_PARTICIPATION_SUCCESS',
    'LOAD_PARTICIPATION_FAILURE'
)<{ create?: boolean }, Participation, string>();

export const loadCurrentQuestionAsync = createAsyncAction(
    'LOAD_CURRENT_QUESTION_REQUEST',
    'LOAD_CURRENT_QUESTION_SUCCESS',
    'LOAD_CURRENT_QUESTION_FAILURE',
)<void, ActeurAnswer, string>();

export const unlockQuizBadgeAsync = createAsyncAction(
    'UNLOCK_QUIZ_BADGE_REQUEST',
    'UNLOCK_QUIZ_BADGE_SUCCESS',
    'UNLOCK_QUIZ_BADGE_FAILURE',
)<void, QuizBadge,string>();

export const setCheckedAnswers = createAction('SET_CHECKED_ANSWERS')<Array<number|string>>();

export const setQuizBadge = createAction('SET_QUIZ_BADGE')<QuizBadge>();

export const submitAnswerAsync = createAsyncAction(
    'SUBMIT_ANSWER_REQUEST',
    'SUBMIT_ANSWER_SUCCESS',
    'SUBMIT_ANSWER_FAILURE'
)<void, void, AxiosError>();

