import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import SnackbarSpawner from 'components/snackbarSpawner/SnackbarSpawner.component';
import { ConnectedRouter } from 'connected-react-router';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import RootStore, { history } from 'store/rootStore';
import { Theme } from 'Theme';
import Root from './Root.component'
import { AuthProvider, AuthContext } from 'Auth';

function findGetParameter(parameterName: string): string {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

class LocalizedUtils extends MomentUtils {
    getDateTimePickerHeaderText(date: Moment) {
        return this.getDatePickerHeaderText(date);
    }

    getDatePickerHeaderText(date: Moment) {
        return date.format('Do MMM');
    }
}

// axios.interceptors.request.use(function (config) {
//     const token = store.getState().session.token;
//     config.headers.Authorization = token;

//     return config;
// });

moment.locale("fr");

const element = document.getElementById('root');
if (element) {
    render(
        <Provider store={RootStore}>
            <AuthProvider>
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale='fr'>
                    <ThemeProvider theme={Theme}>
                        <ConnectedRouter history={history}>
                            <SnackbarSpawner />
                            <Root />
                        </ConnectedRouter>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </AuthProvider>
        </Provider>,
        element,
    );
}
