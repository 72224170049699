import LikePleinSVG from '../../../team-up/images/icon/like-plein2-stroke.svg'
import ApplaudirPleinSVG from '../../../team-up/images/icon/applaudir-plein-stroke.svg'
import MdrPleinSVG from '../../../team-up/images/icon/mdr-plein-stroke.svg'
import EtonnementPleinSVG from '../../../team-up/images/icon/etonnement-plein-stroke.svg'
import EnervePleinSVG from '../../../team-up/images/icon/enerve-plein-stroke.svg'
import { MursService } from 'services'
import { Acteur } from 'classes/Acteur.class'

export class Like {
    public static readonly TYPES_LIKES : {[key: number] : string} = {
        1 : LikePleinSVG,
        2 : ApplaudirPleinSVG,
        3 : MdrPleinSVG,
        4 : EtonnementPleinSVG,
        5 : EnervePleinSVG,
    };

    public idMurLike: number;

    public idReferentielActeur: number;

    public acteur : Acteur;

    public idReferentielMetier: number;

    public idMurPost: number;

    public idCommentaire: number;

    public dateCreationLike: string;

    public actif: number;

    public idGroupeLike: number;

    private murService = MursService.getInstance();

    constructor(comment?: any) {

        if (comment) {
            const {
                idMurLike,
                idReferentielActeur,
                acteur,
                idReferentielMetier,
                idMurPost,
                idCommentaire,
                longueDateCreationLike,
                actif,
                idGroupeLike
            } = comment;

            this.idMurLike = idMurLike;
            this.idReferentielActeur = idReferentielActeur;
            this.acteur = new Acteur(acteur);
            this.idReferentielMetier = idReferentielMetier;
            this.idMurPost = idMurPost;
            this.idCommentaire = idCommentaire;
            this.dateCreationLike = longueDateCreationLike;
            this.actif = actif;
            this.idGroupeLike = idGroupeLike;
        }
    }
    
}