import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ClearAllSVG from '@material-ui/icons/ClearAll'
import CloseSVG from '@material-ui/icons/Close'
import withStyles from '@material-ui/styles/withStyles'
import { WithStyles } from '@material-ui/styles'
import Axios from "axios"
import Avatar from 'components/avatar/Avatar.component'
import ConfirmationDialog from 'components/dialog/ConfirmationDialog.component'
import EmptyListComponent from 'components/EmptyList.component'
import qs from 'qs'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { loadNotificationsAsync, setIdTypeMurNotificationFilter } from 'store/notifications/notificationsActions'
import { RootState } from 'store/types'
import { whiteTheme } from 'Theme'
import NotificationsVideSVG from '../../../assets/visuels/notifications_vide.svg'
import SuppressionSVG from '../../../assets/visuels/suppression.svg'
import style from './NotificationsPaper.style'

const stateToProps = ({ notifications: { isLoading, notifications, notificationsFilters: { idTypeMur } }, murs: { typeMur } }: RootState) => ({
    isLoading,
    notifications,
    typeMur,
    idTypeMur

})

const dispatchToProps = {
    loadNotifications: loadNotificationsAsync.request,
    setTypeMurFilter: setIdTypeMurNotificationFilter
}

interface NotificationsPaperLocalProps {
}

interface NotificationsPaperState {
    alreadyLoaded: boolean,
    confirmClearDialogOpen: boolean
}

type NotificationsPaperProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & NotificationsPaperLocalProps;

class NotificationsPaper extends Component<NotificationsPaperProps, NotificationsPaperState> {

    readonly state: NotificationsPaperState = {
        alreadyLoaded: false,
        confirmClearDialogOpen: false
    }

    componentDidMount() {
        const { loadNotifications, typeMur, setTypeMurFilter } = this.props;
        if (typeMur != -1) {
            setTypeMurFilter(typeMur);
            loadNotifications();
            this.setState({ alreadyLoaded: true });
        }
    }

    componentDidUpdate() {
        const { loadNotifications, typeMur, setTypeMurFilter, isLoading, idTypeMur } = this.props;
        if (!isLoading) {
            if (typeMur != -1 && !this.state.alreadyLoaded) {
                setTypeMurFilter(typeMur);
            }
            if (typeMur != 1 && idTypeMur === typeMur && !this.state.alreadyLoaded) {
                loadNotifications();
                this.setState({ alreadyLoaded: true })
            }
        }

    }

    handleCloseConfirmClear(ok: boolean) {
        if (ok) {
            Axios.post(`index.php?${qs.stringify({
                rub: IS_ADMIN ? 130 : 13,
                p: 5,
                idTypeMur : this.props.typeMur
            })}`);
            this.props.notifications.splice(0, this.props.notifications.length);
        }
        this.setState({
            confirmClearDialogOpen: false
        })
    }

    render() {
        const { classes, notifications } = this.props;
        const { confirmClearDialogOpen } = this.state;
        return (
            <Fragment>
                <Box >
                    <Box display='flex' marginBottom={2}>
                        <Paper elevation={0} className={classes.paperTitreNotifications}>
                            Notifications
                            </Paper>
                        <ThemeProvider theme={whiteTheme} >
                            <Button onClick={() => this.setState({ confirmClearDialogOpen: true })} className={classes.buttonClear} variant='contained' size='large' color='primary'>
                                <ClearAllSVG />
                            </Button>
                        </ThemeProvider>
                    </Box>
                    <Paper elevation={0} className={classes.paperNotifications}>
                        {
                            notifications.length > 0 ?
                                <List>
                                    {notifications.map((notification, index) =>
                                        <ListItem key={`notification-${index}`}>
                                            <ListItemIcon><Avatar acteur={notification.acteur_envoyeur}></Avatar></ListItemIcon>
                                            <ListItemText >
                                                <Box display='flex' flexDirection='column' width='100%'>
                                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                        <Typography className={classes.notificationDate}>{notification.date_envoie}</Typography>
                                                        <CloseSVG className={classes.notificationClose} onClick={() => { notification.vueNotification(); this.props.notifications.splice(index, 1); this.forceUpdate(); }} />
                                                    </Box>
                                                    <Typography className={classes.notificationTitle} noWrap dangerouslySetInnerHTML={{ __html: notification.titre }} />
                                                    <Typography className={classes.notificationMessage} noWrap dangerouslySetInnerHTML={{ __html: notification.message }} />
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                </List>
                                :
                                <Box height={700}>
                                    <EmptyListComponent title='Désolé' subtitle="Vous n'avez aucune notification pour le moment !" urlImage={NotificationsVideSVG} />
                                </Box>
                        }
                    </Paper>
                </Box>
                <ConfirmationDialog triggered={confirmClearDialogOpen} onClose={this.handleCloseConfirmClear.bind(this)} title='Nettoyer les notifications' text='Voulez-vous vraiment supprimer toutes les notifications ? ATTENTION : Cette action est irréversible.' image={SuppressionSVG} />
            </Fragment>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(NotificationsPaper));