import React, { Component } from 'react';
import NoTaskSVG from '../assets/no_task.svg';

export default class NoTask extends Component {
    render() {
        return (
            <div id="no-task-container">
            <img src={NoTaskSVG} alt="pas de tâche" />

                <h3 id="no-task-title">Aucune tâche</h3>

                <p id="no-task-message">
                    Vous n&apos;avez renseigné
                    <br />
                    {' '}
aucune tâche pour le moment
              </p>
          </div>
        );
    }
}
