import { B64File } from 'classes/B64File.class';
import moment, { Moment } from 'moment';
import { CarnetVoyageService } from 'services';
import { CarnetActivity } from './CarnetActivity.class';

export class CarnetDay {

    public idCarnet: number;

    public idDay : number;

    public image: B64File = null;

    public day: Moment = null;

    public activities : CarnetActivity[] = []

    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { idCarnet, 
                idDay,
                image,
                day,
                activities
            } = row;
            this.idCarnet = idCarnet;
            this.idDay = idDay;
            this.image =  B64File.fromDb(image);
            this.day = day ? moment(day,'X') : null;
            if (activities) {
                this.activities = activities.map((activity: any) => new CarnetActivity(activity));
            }
        }
    }


    public toRaw() {
        return {
            idCarnet: this.idCarnet,
            idDay : this.idDay,
            image : this.image,
            day : this.day ? this.day.unix() : null,
            activities : this.activities.map((activity)=> activity.toRaw())
        };
    }


}
