import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from './Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({

    mb0: {
        marginBottom: 0

    },
    mb5: {
        marginBottom: 5

    },

    mb10: {
        marginBottom: 10

    },

    mb15: {
        marginBottom: 15

    },

    mb20: {
        marginBottom: 20

    },

    mb25: {
        marginBottom: 25

    },

    mb30: {
        marginBottom: 30

    },
    mb35: {
        marginBottom: 35

    },
    mb40: {
        marginBottom: 40

    },
    mt0: {
        marginTop: 0
    },
    mt5: {
        marginTop: 5
    },

    mt10: {
        marginTop: 10
    },

    mt15: {
        marginTop: 15
    },

    mt20: {
        marginTop: 20
    },

    mt25: {
        marginTop: 25
    },

    mt30: {
        marginTop: 30
    },

    mt35: {
        marginTop: 35
    },

    mt40: {
        marginTop: 40
    },

    mr5: {
        marginRight: 5
    },

    mr10: {
        marginRight: 10
    },

    mr15: {
        marginRight: 15
    },

    mr20: {
        marginRight: 20
    },

    mr25: {
        marginRight: 25
    },

    mr30: {
        marginRight: 30
    },

    mr35: {
        marginRight: 35
    },

    mr40: {
        marginRight: 40
    },

    ml5: {
        marginLeft: 5
    },

    ml10: {
        marginLeft: 10
    },

    ml15: {
        marginLeft: 15
    },

    ml20: {
        marginLeft: 20
    },

    ml25: {
        marginLeft: 25
    },

    ml30: {
        marginLeft: 30
    },

    ml35: {
        marginLeft: 35
    },

    ml40: {
        marginLeft: 40
    },

    borderRadiusRound: {
        borderRadius: '50%',
    },

    borderRadius8: {

        borderRadius: 8,
    },

    borderRadius30: {

        borderRadius: 30,
    },
    
    height50 : {
        height : 50
    },
    height100 : {
        height : 100
    },
    height100Per : {
        height : '100%'
    },
    width50 : {
        width : 50
    },
    width100 : {
        width : 100
    },
    width100Per : {
        width : '100%'
    },
    
    taCenter : {
        textAlign : 'center'
    },

    responsiveContainer : {
        '@media (min-width: 576px)': {
            maxWidth: 540
        },
        '@media (min-width: 768px)': {
            maxWidth: 720
        },
        '@media (min-width: 992px)': {
            maxWidth: 960
        },
        '@media (min-width: 1200px)': {
            maxWidth: 1140
        }
    }
    


}));