import Axios from "axios";
import qs from 'qs';
import { MonProfilInfos } from "features/parametres/src/monProfil/store/types";
import { Post } from "classes/murs/Post.class";
import { plainToClass } from "class-transformer";
import { QuizBadge } from "classes/animations/QuizBadge.class";
import { Acteur } from "classes/Acteur.class";
import { TypePost } from "classes/murs/TypePost.class";
import { AdminParametresTypesPostInfos } from 'features/admin/parametres/src/typesPost/store/types';

export class ParametresService {
    private static instance: ParametresService;

    public static getInstance(): ParametresService {
        if (!ParametresService.instance) {
            ParametresService.instance = new ParametresService();
        }

        return ParametresService.instance;
    }

    public async loadMonProfilInfos(): Promise<MonProfilInfos> {
        const params: any = {
            rub: 68553,
            p: 18
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => {
                var ret: MonProfilInfos = {
                    aPropos : null,
                    badges : [],
                    favoris : [],
                    mesPosts : []
                }
                ret.favoris = content.favoris.map((post: Post) => {
                    return plainToClass(Post, post);
                })

                ret.mesPosts = content.mesPosts.map((post: Post) => {
                    return plainToClass(Post, post);
                })
                // ret.canaux = content.canaux.map((canal: Metier) => {
                //     return new Metier(canal);
                // })

                ret.badges = content.badges.map(( badge : QuizBadge) => {
                    return new QuizBadge(badge);
                })
                ret.aPropos = new Acteur(content.aPropos);
                return ret;
            });

    }

    // Récupération des types de posts en fonction du type de mur
    public async loadTypesPost(typeMur: number): Promise<TypePost[]> {
        const params: any = {
            rub: 306,
            typeMur: typeMur,
            p: 3,
            sp : 2
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => content.typesPost.map((typePost: any) => new TypePost(typePost)));
    }

    // Récupération des infos des types de post et types de murs par défaut à l'initialisation de la page
    public async loadTypesPostInfos(): Promise<AdminParametresTypesPostInfos>
    {
        const params: any = {
            rub: 306,
            p: 3,
            sp : 1
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => {
                var ret : AdminParametresTypesPostInfos = {
                    typesMurs : [],
                    typesPost : []
                }
                // Gestion des types de Posts et des types de Murs qu'on reçoit du back
                ret.typesPost = content.typesPost
                ret.typesMurs = content.typesMurs;

                return ret;
            });
    }
}
