import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { v4 } from 'uuid';
import { Acteur } from 'classes/Acteur.class';
import { NotificationsService } from 'services/notifications/Notifications.service';

export class Notification {

    public uid : string = v4();
    
    public id_notification: number;

    public nom: string;
    
    public message: string;

    public titre: string;

    public url: string;

    public image: string;

    public id_acteur_envoyeur: string;

    public acteur_envoyeur : Acteur = null;

    public id_acteur_receptionneur: string;

    public id_type : number;


    private notificationService = NotificationsService.getInstance();

    public date_envoie : string;


    constructor(row?: any) {
        if (row) {
            const { id_notification, nom, message, url, image, titre, id_acteur_envoyeur, acteur_envoyeur, id_acteur_receptionneur, id_type, date_envoie } = row;
            this.id_notification = id_notification;
            this.nom = nom;
            this.message = message;
            this.url = url;
            this.image = image;
            this.id_acteur_receptionneur = id_acteur_receptionneur;
            this.titre = titre;
            this.id_acteur_envoyeur = id_acteur_envoyeur;
            this.acteur_envoyeur = new Acteur(acteur_envoyeur);
            this.id_type = id_type;
            this.date_envoie = date_envoie;
        }
    }


    public toRaw() {
        return {
            id_notification: this.id_notification,
            nom: this.nom,
            message: this.message,
            url: this.url,
            image: this.image,
            titre: this.titre,
            id_acteur_envoyeur: this.id_acteur_envoyeur,
            id_type : this.id_type
        };
    }

    public vueNotification() : Promise<void> {
        return this.notificationService.vueNotification(this.id_notification);
    }
}
