import { ChallengeService } from 'services';
import { B64File } from 'classes/B64File.class';
import {v4} from 'uuid'
export class ChallengeDestinationRubrique {

    public uid: string = v4();

    public leChallenge : number;

    public lesInfosRubrique : number;

    public texte : string;

    public image : B64File = null;

    public indexDestinationRubrique : number;

    private challengeService = ChallengeService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { 
                leChallenge,
                lesInfosRubrique,
                texte,
                indexDestinationRubrique,
                image,
            } = row;
            this.leChallenge = leChallenge;
            this.lesInfosRubrique = lesInfosRubrique;
            this.texte = texte;
            this.indexDestinationRubrique = indexDestinationRubrique;
            this.image = B64File.fromDb(image);
        }
    }


    public toRaw() {
        return {
            leChallenge : this.leChallenge,
            lesInfosRubrique : this.lesInfosRubrique,
            texte : this.texte,
            indexDestinationRubrique : this.indexDestinationRubrique,
            image : this.image,
        };
    }


}
