
import Axios, { CancelTokenSource } from 'axios';
import { Acteur } from 'classes/Acteur.class';
import { Commentaire } from 'classes/murs/Commentaire.class';
import { Like } from 'classes/murs/Like.class';
import { Post } from 'classes/murs/Post.class';
import { TypePost } from 'classes/murs/TypePost.class';
import { ElementLiteral, MurInfos, TypesPostAssociative, ListPostsFilterState } from 'features/murs/src/store/types';
import qs from 'qs';
import { Metier } from 'classes/referentiel/Metier.class';
import { plainToClass } from 'class-transformer'
import { B64File } from 'classes/B64File.class';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { useRef } from 'react';

export class MursService {
    private static instance: MursService;

    public static getInstance(): MursService {
        if (!MursService.instance) {
            MursService.instance = new MursService();
        }

        return MursService.instance;
    }

    private static rubriqueMur = IS_ADMIN ? 240 : 24;

    public async loadPosts(typeMur: number, listPostFilters: ListPostsFilterState): Promise<Post[]> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur,
            filters: JSON.stringify(listPostFilters),
            p: 1
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content: posts } }) => posts.map((post: any) => plainToClass(Post, post)));

    }



    public async loadElements(typeMur: number): Promise<ElementLiteral> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur: typeMur,
            p: 2
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => content);
    }

    public async addLike(typeMur: number, typeLike: number, idMurPost: number): Promise<number> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 3,
                typeMur: typeMur
            })}`,
            qs.stringify({
                typeLike: typeLike,
                idMurPost: idMurPost,
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async removeLike(typeMur: number, idMurPost: number): Promise<number> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 4,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idMurPost: idMurPost,
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async removePost(typeMur: number, idMurPost: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 5,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idMurPost: idMurPost,
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async getPost(typeMur: number, idMurPost: number, index?: number): Promise<Post> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur: typeMur,
            idMurPost: idMurPost,
            p: 6
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => { let post =  plainToClass(Post, content); post.index = index; return post; });
    }

    public async addComment(commentaire: string, idPere: number, typeMur: number, typeComment: number, idMurPost: number): Promise<number> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 7,
                typeMur: typeMur
            })}`,
            qs.stringify({
                commentaire: commentaire,
                idPere: idPere,
                idMurPost: idMurPost,
                typeComment: typeComment
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async loadCommentaires(typeMur: number, idMurPost: number): Promise<Commentaire[]> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur: typeMur,
            idMurPost: idMurPost,
            p: 8
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => content.map((commentaire: any) => new Commentaire(commentaire)));
    }

    public async loadLikes(typeMur: number, idMurPost: number): Promise<Like[]> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur: typeMur,
            idMurPost: idMurPost,
            p: 17
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => content.map((like: any) => new Like(like)));
    }

    public async deleteCommentaire(typeMur: number, idMurCommentaire: number): Promise<number> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 9,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idMurCommentaire: idMurCommentaire
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async saveCommentaireText(typeMur: number, idMurCommentaire: number, commentaire: string): Promise<number> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 18,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idMurCommentaire: idMurCommentaire,
                comment: commentaire
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async loadTypesPost(typeMur: number): Promise<TypesPostAssociative> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur: typeMur,
            p: 10
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => content.map((typePost: any) => new TypePost(typePost)));
    }

    public async loadMurInfos(typeMur: number, listPostFilters: ListPostsFilterState): Promise<MurInfos> {
        const params: any = {
            rub: MursService.rubriqueMur,
            typeMur: typeMur,
            filters: JSON.stringify(listPostFilters),
            p: 11
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                return message;
            })
            .then(({ data: { content } }) => {

                var ret: MurInfos = {
                    list: [],
                    typesPost: {},
                    canaux: {},
                    elements: null,
                    acteur: null,
                    statistics: {},
                    referentielMetier: null,
                    raisonsSignalement: {},
                    title: '',
                    nbChallenges: null
                }
                ret.list = content.list.map((post: Post) => {
                    return plainToClass(Post, post);
                })

                content.canaux.forEach((canal: Metier) => {
                    ret.canaux[canal.idReferentielMetier] = new Metier(canal);
                });
                // ret.canaux = content.canaux.map((canal: Metier) => {
                //     return new Metier(canal);
                // })

                if (content.typesPost) {
                    content.typesPost.forEach((typePost: any) => {
                        ret.typesPost[typePost.idTypePost] = new TypePost(typePost);
                    });
                }
                ret.acteur = new Acteur(content.acteur);
                ret.statistics = content.statistics;
                ret.elements = content.elements;
                ret.elements['cm'] = new Acteur(ret.elements['cm']);
                ret.referentielMetier = new Metier(content.referentielMetier);
                ret.raisonsSignalement = content.raisonsSignalement;
                ret.title = content.title;
                ret.nbChallenges = content.nbChallenges;

                return ret;

            });
    }

    public async sendReponseSondage(typeMur: number, idReponse: number, idMurPost: number): Promise<number> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 13,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idReponse: idReponse,
                idMurPost: idMurPost,
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => content);
    }

    public async publishPost(typeMur: number, post: Post): Promise<Post> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 15,
                typeMur: typeMur
            })}`,
            qs.stringify({
                post: JSON.stringify(post.toDatabaseObject())
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
    } 

    public async epinglerPost(typeMur: number, idMurPost: number, dateDebut?: string, dateFin?: string): Promise<Post> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 16,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idMurPost,
                dateDebut,
                dateFin
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => plainToClass(Post, content));
    }

    public async favoriPost(typeMur: number, idMurPost: number): Promise<Post> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 19,
                typeMur: typeMur
            })}`,
            qs.stringify({
                idMurPost
            })
        ).catch(function ({ response: { data: { message } } }) {
            return message;
        })
            .then(({ data: { content } }) => plainToClass(Post, content));
    }

    private source: CancelTokenSource = null;

    public preUploadVideo(typeMur: number, file: File): RxAxios<any> {
        const formData = new FormData();
        formData.append('file', file);
        
        this.source = Axios.CancelToken.source();

        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 20,
                typeMur: typeMur
            })}`,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            },
            cancelToken: this.source.token
        });
    }

    public cancelPreUploadVideo() {
        if (this.source != null) {
            this.source.cancel("Operation canceled by the user.")
        }
    }
}
