import { ChallengeService } from 'services';
import LocalisationSVG from 'features/admin/carnet_voyage/assets/informations/icone_localisation.svg';
import FuseauHoraireSVG from 'features/admin/carnet_voyage/assets/informations/icone_fuseau_horaire.svg';
import LangueSVG from 'features/admin/carnet_voyage/assets/informations/icone_langue.svg';
import ClimatSVG from 'features/admin/carnet_voyage/assets/informations/icone_climat.svg';
import MonnaieSVG from 'features/admin/carnet_voyage/assets/informations/icone_monnaie.svg';
import IndispensablesSVG from 'features/admin/carnet_voyage/assets/informations/icone_indispensables.svg';
import ElectriciteSVG from 'features/admin/carnet_voyage/assets/informations/icone_electricite.svg';
import TransportsSVG from 'features/admin/carnet_voyage/assets/informations/icone_transports.svg';
import SecuriteSVG from 'features/admin/carnet_voyage/assets/informations/icone_securite.svg';
import TelephoneSVG from 'features/admin/carnet_voyage/assets/informations/icone_telephone.svg';
import ShoppingSVG from 'features/admin/carnet_voyage/assets/informations/icone_shopping.svg';
import SanteSVG from 'features/admin/carnet_voyage/assets/informations/icone_sante.svg';
import NourritureSVG from 'features/admin/carnet_voyage/assets/informations/icone_nourriture.svg';
import ReligionSVG from 'features/admin/carnet_voyage/assets/informations/icone_religion.svg';
import FauneSVG from 'features/admin/carnet_voyage/assets/informations/icone_faune.svg';
import FloreSVG from 'features/admin/carnet_voyage/assets/informations/icone_flore.svg';
import BanquesSVG from 'features/admin/carnet_voyage/assets/informations/icone_banques.svg';
import VetementsSVG from 'features/admin/carnet_voyage/assets/informations/icone_vetements.svg';
import CourrierSVG from 'features/admin/carnet_voyage/assets/informations/icone_courrier.svg';
import FormalitesSVG from 'features/admin/carnet_voyage/assets/informations/icone_formalites.svg';
import BarSVG from 'features/admin/carnet_voyage/assets/informations/icone_bar.svg';


import { numberToStringObject } from 'features/carnet_voyage/src/store/types';

export class ChallengeDestinationRubriqueInfos {


    public idRubriqueInfos : number;

    public titre : string;

    public image : string;

    static destinationsItemImage : numberToStringObject  = {
        1 :LocalisationSVG,
        2 :FuseauHoraireSVG,
        3 :LangueSVG,
        4 :ClimatSVG,
        5 :MonnaieSVG,
        6 :IndispensablesSVG,
        7 :ElectriciteSVG,
        8 :TransportsSVG,
        9 :SecuriteSVG,
        10 :TelephoneSVG,
        11 :ShoppingSVG,
        12 :SanteSVG,
        13 :NourritureSVG,
        14 :ReligionSVG,
        15 :FauneSVG,
        16 :FloreSVG,
        17 :BanquesSVG,
        18 :VetementsSVG,
        19 :CourrierSVG,
        20 :FormalitesSVG,
        21 :BarSVG
    }

    static destinationsItemContent : numberToStringObject  = {
        1 :'Localisation',
        2 :'Fuseau Horaire',
        3 :'Langue',
        4 :'Climat',
        5 :'Monnaie',
        6 :'Indispensables',
        7 :'Électricite',
        8 :'Transports',
        9 :'Sécurité',
        10 :'Téléphone',
        11 :'Shopping',
        12 :'Santé',
        13 :'Nourriture',
        14 :'Religion',
        15 :'Faune',
        16 :'Flore',
        17 :'Banques',
        18 :'Vêtements',
        19 :'Courrier',
        20 :'Formalités',
        21 :'Bars & Discothèques'
    }

    private challengeService = ChallengeService.getInstance();

    constructor(row?: any) {
        if (row) {
            const { 
                idRubriqueInfos,
                titre,
                image
            } = row;
            this.idRubriqueInfos =idRubriqueInfos;
            this.titre = titre;
            this.image =image;
        }
    }


    public toRaw() {
        return {
            idRubriqueInfos : this.idRubriqueInfos,
            titre : this.titre,
            image : this.image,
        };
    }


}
