import Axios from "axios";
import qs from "qs";
import { RechercheFilterState, RechercheFiltersInfos } from "features/admin/recherche/src/store/types";
import { Post } from "classes/murs/Post.class";
import { plainToClass } from "class-transformer";

export class RechercheService {
    private static instance: RechercheService;

    public static getInstance(): RechercheService {
        if (!RechercheService.instance) {
            RechercheService.instance = new RechercheService();
        }

        return RechercheService.instance;
    }

    private static rubriqueRecherche = 280;

    public async loadPosts(rechercheFilters: RechercheFilterState): Promise<Post[]> {
        const params: any = {
            rub: RechercheService.rubriqueRecherche,
            filters: JSON.stringify(rechercheFilters),
            p: 1
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: posts } }) => posts.map((post: any) => plainToClass(Post, post)));
    }

    public async loadPostsInfos(): Promise<RechercheFiltersInfos> {
        const params: any = {
            rub: RechercheService.rubriqueRecherche,
            p: 2
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                return content;
            });
    }
}
