import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'store/rootStore';
import { useDispatch } from 'react-redux';
import { RootState } from 'store/types';
import isEqual from 'lodash/isEqual';
import { Post } from 'classes/murs/Post.class'
import useStyle from './ListPosts.style';
import EnLigneVideSVG from '../../../../../assets/visuels/en_ligne_vide.svg'
import { loadPostsAsync, loadMorePostsAsync, setListPostsLimitFilter } from '../store/actions';
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import PostComponent from './Post.hook.component'
import EmptyListComponent from 'components/EmptyList.component'

interface ListPostsProps {
    imgEmptyList?: string,
    subtitleEmptyList?: string,
    titleEmptyList?: string,
    listFromLocal?: Post[],
    removePost?: (index: number) => void,
    readonly?: boolean
}

const defaultPropValue: ListPostsProps = {
    imgEmptyList: EnLigneVideSVG,
    subtitleEmptyList: "Vous n'avez pas encore publié de publications",
    titleEmptyList: "C'est vide :)"
}

const ListPosts: React.FC<ListPostsProps> = (props = defaultPropValue) => {

    const {
        listFromLocal,
        subtitleEmptyList,
        imgEmptyList,
        removePost,
        readonly
    } = props;

    const classes = useStyle();

    const [test, setTest] = useState<Object>(null);

    const [rotate, setRotate] = useState<boolean>(false)
    const [filterDisplayed, setFilterDisplayed] = useState<boolean>(false)
    const [previousListLength, _setPreviousListLength] = useState<number>(-1)

    const containerListPostsRef = useRef(null)

    const isListPostsLoadingRef = useRef(null)

    const previousListLengthRef = useRef(null)
    function setPreviousListLength(listLength: number) {
        previousListLengthRef.current = listLength;
        _setPreviousListLength(listLength);
    }
    
    const listRef = useRef(null)


    const dispatch = useDispatch();

    const [
        list,
        isListPostsLoading,
        elements,
        tMur,
        post,
        shouldPostsListGetUpdated,
        idTypePost,
        isLoading,
        pathname
    ] = useSelector((state) => [
        state.murs.list,
        state.murs.isListPostsLoading,
        state.murs.elements,
        state.murs.typeMur,
        state.murs.post,
        state.murs.shouldPostsListGetUpdated,
        state.murs.listPostsFilters.idTypePost,
        state.murs.isLoading,
        state.router.location.pathname
    ], isEqual);

    useEffect(() => {
        if (!listFromLocal) {
            window.addEventListener('scroll', (e) => handleContainerScroll(e), true);

            if (shouldPostsListGetUpdated) {
                dispatch(loadPostsAsync.request());
            }

        }
        return function cleanup() {
            if (!listFromLocal) {
                window.removeEventListener('scroll', (e) => handleContainerScroll(e));
            }
        }
    }, []);

    useEffect(() => {
        listRef.current = list;
    }, [list])

    useEffect(() => {
        isListPostsLoadingRef.current = isListPostsLoading;
    }, [isListPostsLoading])

    useEffect(() => {
        if (post) {
            var elmnt = document.getElementById(`post-${post.idMurPost}-${post.typeMur}`);
            if (elmnt) {
                elmnt.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }, [post])

    useEffect(() => {
        if (!isLoading && tMur != -1) {
            if (shouldPostsListGetUpdated && !isListPostsLoading && !listFromLocal) {
                setPreviousListLength(list.length);
                dispatch(setListPostsLimitFilter(5))
                dispatch(loadPostsAsync.request());
                // setState({ previousListLength: -1 });
            }
            if (!isListPostsLoading && !shouldPostsListGetUpdated && containerListPostsRef.current.getBoundingClientRect().bottom <= window.innerHeight + 50 ) {
                setPreviousListLength(list.length);
                dispatch(setListPostsLimitFilter(list.length + 5))
                dispatch(loadMorePostsAsync.request());
            }
        }
    }, [shouldPostsListGetUpdated, isListPostsLoading,])

    function handleContainerScroll(event: Event) {
        // Nombre de pixels restants entre le bas de la zone affichée par client et le bas de la page (= hauteur de la fenêtre)
        if (containerListPostsRef.current) {
            var pxRestants = containerListPostsRef.current.getBoundingClientRect().bottom - window.innerHeight;

            // Le nombre maximum de pixels restants à afficher, qui déclenche le chargement de nouveaux posts
            var pxLimite = 0.2 * containerListPostsRef.current.getBoundingClientRect().height;

            if (!listFromLocal && containerListPostsRef.current != null && pxRestants <= pxLimite && !isListPostsLoadingRef.current && previousListLengthRef.current !== listRef.current.length) {

                //Empêcher que ça arrive quand on a plus de nouveaux résultats?
                setPreviousListLength(listRef.current.length);
                dispatch(setListPostsLimitFilter(listRef.current.length + 5))
                dispatch(loadMorePostsAsync.request());
            };
        }

    }

    if (isListPostsLoading && !shouldPostsListGetUpdated) {
        return <Box display='flex'><CircularProgress style={{ margin: 'auto' }} /></Box>
    }


    return (
        <div ref={containerListPostsRef} style={{ display: 'flex', flexDirection: 'column', width: "100%" }} className={filterDisplayed ? classes.containerFilterDisplayed : ''} >
            {
                listFromLocal ?
                    <Fragment>
                        {
                            listFromLocal.map((mapPost, index) =>
                                <PostComponent key={`post-${mapPost.idMurPost}-${mapPost.typeMur}`} post={mapPost} index={index} removePost={removePost} readOnly={readonly} />
                            )
                        }
                    </Fragment>

                    :
                    <Fragment>
                        {
                            list.map((mapPost, index) =>
                                <PostComponent key={`post-${mapPost.idMurPost}-${mapPost.typeMur}`} post={mapPost} index={index} removePost={removePost} readOnly={readonly} />
                            )
                        }
                    </Fragment>
            }

            {
                isLoading &&
                <CircularProgress style={{ margin: 'auto' }} />
            }
            {/* {
                previousListLength === list.length &&
                <EmptyListComponent title='Vous savez tout maintenant !' subtitle='Vous êtes arrivé au bout de toutes les publications de ce mur !' />
            } */}
            {
                !isLoading && !isListPostsLoading && !shouldPostsListGetUpdated && !list.length && (listFromLocal && !listFromLocal.length || !listFromLocal) &&
                <EmptyListComponent title={props.titleEmptyList} subtitle={props.subtitleEmptyList} urlImage={props.imgEmptyList} />
            }
        </div>
    );
}

export default ListPosts;