import { B64File } from 'classes/B64File.class';
import { ContenusService } from 'services';
import {v4} from 'uuid'

export class ContenuDotation {
    
    public contenusService = ContenusService.getInstance();

    public uid: string = v4();

    public idDotation : number;

    public idPere : number;

    public name : string;

    public image : B64File;

    constructor(row?: any) {
        if (row) {
            const { idDotation,
                idPere,
                name,
                image
            } = row;
            this.idDotation = idDotation;
            this.idPere = idPere;
            this.name = name;
            this.image = B64File.fromDb(image);;
        }
    }

    public toRaw() {
        return {
            idDotation  : this.idDotation,
            idPere : this.idPere,
            name : this.name,
            image  : this.image
        };
    }

    public isEmpty(): boolean {
        // L'image n'est pas obligatoire
        if (this.name) return false;
        else return true;
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteDotation(this.idDotation);
    }

    public save(): Promise<number> {
        return this.contenusService.saveDotation(this);
    }

}
