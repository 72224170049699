import { Animation } from '../Animation.class';
import { AnimationTypes } from '../AnimationTypes.enum';
import { StatisticsDisplayerBaseProps } from 'components/statisticsDisplayer/StatisticsDisplayer.component';
import { ConcoursPhotoService } from 'services';
import { Post } from "./Post.class";
import { Moment } from 'moment';
import moment from 'moment';

/**
 * Cette classe stocke l'instance d'un concours photo en front
 */
export class ConcoursPhoto extends Animation {

    /**
     * Le service du concours photo
     */
    public service = ConcoursPhotoService.getInstance();
    
    /**
     * Les différents types de likes appliquables sur les publications
     */
    public likeTypes: number = 0;

    /**
     * Le nombre maximum de likes qu'un utilisateur peut attribuer
     */
    public maxVotes: number = 1;

    /**
     * La date de début des votes du concours
     */
    public voteStart: Moment;

    /**
     * La date de fin des votes du concours
     */
    public voteEnd: Moment;

    /**
     * Le service du concours photo
     */
    public maxPhotosPerPerson: boolean = false;

    /**
     * Vrai si les validations de post sont activées, faux sinon
     */
    public validationEnabled: boolean = false;

    /**
     * La photo de profil de l'auteur
     */
    public imgAuthorDisplayed: boolean = false;

    /**
     * Vrai si les descriptions sont activées, faux sinon
     */
    public descriptionsEnabled: boolean = false;

    public maxPhotosPerPersonCount: number = null;

    /**
     * Vrai si les participants peuvent liker leurs propres publications
     */
    public ownVote: boolean = false;

    /**
     * Vrai si l'ordre d'affichage des posts est aléatoire, faux sinon
     */
    public displayRandom: boolean = false;

    /**
     * Vrai si la pagination est activée, faux si l'utilisateur doit 'scroll' vers le bas pour charger de nouveaux posts
     */
    public paging: boolean = false;

    /**
     * L'ordre d'affichage des posts
     */
    public postsOrder: Array<any>;

    /**
     * L'indice de chargement des posts
     */
    public randomOffset: number;

    constructor(concours?: any) {
        super(concours);

        this.type = AnimationTypes.PhotoContest;

        if (concours) {
            this.likeTypes = concours.likeTypes;
            this.maxVotes = concours.maxVotes;
            this.voteStart = moment.unix(concours.voteStart);
            this.voteEnd = moment.unix(concours.voteEnd);
            this.maxPhotosPerPersonCount = concours.maxPhotosPerPerson;
            this.maxPhotosPerPerson = concours.maxPhotosPerPerson > 0;
            this.validationEnabled = concours.validationEnabled;
            this.imgAuthorDisplayed = concours.imgAuthorDisplayed;
            this.descriptionsEnabled = concours.descriptionsEnabled;
            this.ownVote = concours.ownVote;
            this.displayRandom = concours.displayRandom;
            this.paging = concours.paging;
            this.postsOrder = concours.postsOrder;
            this.randomOffset = concours.randomOffset;
        }
    }

    /**
     * Convertit l'objet ConcoursPhoto en tableau pour le transmettre au back
     * @returns Le tableau représentant l'instance
     */
    public toObject(): object {
        return {
            likeTypes: this.likeTypes,
            maxVotes: this.maxVotes,
            voteStart: this.voteStart.unix(),
            voteEnd: this.voteEnd.unix(),
            maxPhotosPerPerson: this.maxPhotosPerPerson ? this.maxPhotosPerPersonCount : -1,
            validationEnabled : this.validationEnabled,
            imgAuthorDisplayed : this.imgAuthorDisplayed,
            descriptionsEnabled : this.descriptionsEnabled,
            ownVote: this.ownVote,
            displayRandom: this.displayRandom,
            paging: this.paging,
            postsOrder: this.postsOrder,
            randomOffset: this.randomOffset
        };
    }

    /**
     * Retourne les statistiques du concours
     * @returns les statistiques du concours
     */
    public async getStatistics(): Promise<StatisticsDisplayerBaseProps> {
        //const stats = await this.animationService.getStatistics(this.idAnimation, AnimationTypes.PhotoContest);
        return {
            statistics: [
                //TODO
            ]
        }
    }

    /**
     * Définit le nouvel ordre d'affichage des posts
     * @param postsOrder L'ordre d'affichage des posts
     */
    public setPostsOrder(postsOrder: Array<any>): void {
        this.postsOrder = postsOrder;
    }

}

ConcoursPhoto.TYPES_ANIMATION[AnimationTypes.PhotoContest] = ConcoursPhoto;