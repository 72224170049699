

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { createMuiTheme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ThemeProvider } from "@material-ui/core/styles"
import FixedKeyboardDateTimePicker from 'components/fixedKeyboardDateTimePicker/FixedKeyboardDateTimePicker.component';
import moment, { Moment } from "moment"
import 'moment/locale/fr'
import React, { Component } from "react"
import { Theme } from 'Theme'

interface EpinglerPostDialogProps {
    triggered: boolean;
    onClose: ((dateDebutValue: Moment, dateFinValue: Moment) => void);
    dateDebut: string;
    dateFin: string;
}

interface EpinglerPostDialogState {
    open: boolean;
    dateDebutValue: Moment;
    dateFinValue: Moment;
}

class EpinglerPostDialogComponent extends Component<EpinglerPostDialogProps, EpinglerPostDialogState> {

    public readonly state: EpinglerPostDialogState = {
        open: false,
        dateDebutValue : null,
        dateFinValue : null
    };

    private datePickerTheme = (mainTheme: typeof Theme) => createMuiTheme({
        props: mainTheme.props,
        palette: mainTheme.palette
    });

    componentDidMount() {
        const { dateDebut, dateFin } = this.props;
        this.setState({
            dateDebutValue: dateDebut ? moment(dateDebut, 'YYYYMMDDHHmmss') : null,
            dateFinValue: dateFin ? moment(dateFin, 'YYYYMMDDHHmmss') : null
        })
    }

    public componentDidUpdate(prevProps: EpinglerPostDialogProps): void {
        if (prevProps.triggered !== this.props.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDialog(ok: boolean) {
        const { dateDebutValue, dateFinValue } = this.state;
        if (ok) {
            this.props.onClose(dateDebutValue, dateFinValue);
        } else {
            this.props.onClose(null, null);
        }

        this.setState({
            open: false,
        });
    }

    private onChangeMoment(
        fin: boolean,
        value: Moment
    ): void {
        fin ? this.setState({ dateFinValue: value }) : this.setState({ dateDebutValue: value });
    }

    public render(): JSX.Element {
        const { dateDebutValue, dateFinValue } = this.state;
        return (

            <Dialog fullWidth maxWidth='sm' open={this.state.open} onClose={() => this.closeDialog(false)}>
                <DialogTitle>
                    Épingler en haut
                </DialogTitle>
                <DialogContent>
                    <DialogContentText> Sélectionnez la date de début et de fin à laquelle vous souhaitez mettre en avant votre publication</DialogContentText>
                    <ThemeProvider theme={this.datePickerTheme}>
                        <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap'>
                            <FixedKeyboardDateTimePicker
                                required
                                autoOk
                                inputVariant="outlined"
                                ampm={false}
                                label='Date de début'
                                value={dateDebutValue}
                                format="DD/MM/YYYY HH:mm"
                                margin="normal"
                                onChange={(value) => this.onChangeMoment(false, value)}
                                maxDate={dateFinValue || moment([2100, 1, 1])}
                                maxDateMessage={"La date de début ne devrait pas être après la date de fin."}
                                cancelLabel={"Annuler"}
                                clearLabel={"Vider"}
                                okLabel={"Valider"}
                                todayLabel={"Aujourd'hui"}
                                invalidDateMessage='Format de date invalide'
                                style={{marginLeft : Theme.spacing(0.5), marginRight : Theme.spacing(0.5), maxWidth:270}}
            
                            />
                            <FixedKeyboardDateTimePicker
                                required
                                autoOk
                                inputVariant="outlined"
                                ampm={false}
                                label='Date de fin'
                                value={dateFinValue}
                                format="DD/MM/YYYY HH:mm"
                                margin="normal"
                                onChange={(value) => this.onChangeMoment(true, value)}
                                minDate={dateDebutValue || moment([1900, 1, 1])}
                                minDateMessage={"La date de fin ne devrait pas être avant la date de début."}
                                cancelLabel={"Annuler"}
                                clearLabel={"Vider"}
                                okLabel={"Valider"}
                                todayLabel={"Aujourd'hui"}
                                invalidDateMessage='Format de date invalide' style={{marginLeft : Theme.spacing(0.5), marginRight : Theme.spacing(0.5), maxWidth:270}}

                            />
                        </Box>
                    </ThemeProvider>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="contained" color="secondary" onClick={() => this.closeDialog(false)}>
                        Annuler
                    </Button>
                    <Button size="large" variant="contained" color="primary" disabled={(!dateDebutValue || !dateFinValue) || (!dateFinValue.isValid() || !dateDebutValue.isValid())} onClick={this.closeDialog.bind(this, true)}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>);
    }

}

export default EpinglerPostDialogComponent;