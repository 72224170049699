import Axios from 'axios';
import qs from 'qs';
import { ActeurAnswer } from './ActeurAnswer.class';
import moment, { Moment } from 'moment';
import { Question } from './Question.class';
import { Answer } from './Answer.class';
import { QuestionTypes } from './QuestionTypes.enum';
import { QuizBadge } from './QuizBadge.class';

export class Participation {
    public idParticipation: number;

    public idAnimation: number;

    public completed: boolean;

    public score: number;

    public beginDate: Moment;

    public endDate: Moment;

    public duration: number;

    public rank: number;

    constructor(participation?: any) {
        if (participation) {
            const {
                idParticipation, idAnimation, completed, score, beginDate, endDate, duration, rank
            } = participation;
            this.idParticipation = idParticipation;
            this.idAnimation = idAnimation;
            this.completed = completed;
            this.score = score;
            this.beginDate = moment(beginDate, 'X');
            this.endDate = moment(endDate, 'X');
            this.duration = duration;
            this.rank = rank;
        }
    }

    public async fetchCurrentQuestion(): Promise<ActeurAnswer> {
        return await Axios.get(
            `index.php?${qs.stringify({
                rub: 21,
                p: 2,
                idParticipation: this.idParticipation,
            })}`,
        ).then(({ data: { content } }) => {
            if (content) {
                return new ActeurAnswer(content);
            } else {
                return null;
            }
        });
    }

    public async computeScore(): Promise<void> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 21,
                p: 5,
                idParticipation: this.idParticipation,
                endDate: moment.now(),
            })}`,
        ).then(({ data: { content } }) => {
            if (content) {
                const {
                    idParticipation, idAnimation, completed, score, beginDate, endDate, duration, rank
                } = content;
                this.idParticipation = idParticipation;
                this.idAnimation = idAnimation;
                this.completed = completed;
                this.score = score;
                this.beginDate = moment(beginDate, 'X');
                this.endDate = moment(endDate, 'X');
                this.duration = duration;
                this.rank = rank;
            }
        });
    }

    public async unlockQuizBadge(): Promise<QuizBadge> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: 21,
                p: 7,
                idParticipation: this.idParticipation,
            })}`,
        ).then(({ data: { content : { badge, alreadyUnlocked} } }) => {
            var quizBadge = new QuizBadge(badge);
            quizBadge.alreadyUnlocked = alreadyUnlocked;
            return quizBadge;
        });
    }

    public async getActeurAnswers(): Promise<{
        question: Question,
        answers: Answer[]
    }[]> {
        const params = {
            rub: 23,
            p: 7,
            idParticipation: this.idParticipation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: acteurs } }) => acteurs.map((acteur : any) => {
                return {
                    question : new Question(acteur.question),
                    answers : acteur.question.type === QuestionTypes.TEXT ? acteur.answers : acteur.answers.map((answer : any ) => new Answer(answer))
                }
            }));
    }

    public async getPlayerResults() : Promise<{
        question: Question,
        answers: number[],
        sequence : number
    }[]> {
        const params = {
            rub: 21,
            p: 6,
            idParticipation: this.idParticipation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: acteurs } }) => acteurs.map((acteur : any) => {
                return {
                    question : new Question(acteur.question),
                    answers : acteur.question.type === QuestionTypes.TEXT ? acteur.answers : acteur.answers,
                    sequence : acteur.sequence
                }
            }));
    }
}
