import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Commentaire } from "classes/murs/Commentaire.class"
import React, { Component } from "react"
import { Theme } from "Theme"

interface ModifyCommentDialogProps {
    triggered: boolean;
    onClose: ((comment: string) => void);
    comment: Commentaire;
    typeMur: number;
}

interface ModifyCommentDialogState {
    currentValue: string;
    open: boolean;
}

class ModifyCommentDialogComponent extends Component<ModifyCommentDialogProps, ModifyCommentDialogState> {


    dialogTheme = (theme: typeof Theme) => {
        var th = createMuiTheme({
            ...theme,
            overrides: {
                MuiDialogContent: {
                    root: {
                        padding: '10px 20px 10px 20px'
                    }
                },
                MuiDialogContentText: {
                },
                MuiDialogTitle: {
                    root: {
                        padding: '20px 20px 10px 20px',

                        "& h2": {
                            color: 'black',
                            fontSize: 14,
                            fontWeight: 700,
                            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",
                        }
                    },
                }
            }
        })
        th.overrides.MuiDialogActions = {
            root: {
                padding: '10px 20px 20px 20px'
            }
        };
        return th;
    }

    componentDidMount() {
        this.setState({ currentValue: this.props.comment.commentaire });
    }

    public readonly state: ModifyCommentDialogState = {
        open: false,
        currentValue: null
    }

    private handleOnChange(value: any): void {
        this.setState({
            currentValue: value
        });
    }

    private closeDialog(value: string) {
        this.props.onClose(value);
        this.setState({
            open: false
        });

    }

    public componentDidUpdate(prevProps: ModifyCommentDialogProps): void {
        const { triggered } = this.props;
        const { open } = this.state;
        if (triggered != prevProps.triggered && triggered && !open) {
            this.setState({ open: true });
        }
    }


    public render(): JSX.Element {
        const { onClose, comment } = this.props;
        const { currentValue } = this.state;
        return <ThemeProvider theme={this.dialogTheme}>
            <Dialog open={this.state.open} onClose={this.closeDialog.bind(this, '')} fullWidth maxWidth="lg">

                <form onSubmit={(e) => {
                    e.preventDefault()
                    this.closeDialog(currentValue);
                }}>
                    <DialogTitle>Modifier commentaire</DialogTitle>
                    <DialogContent >
                        <TextField
                            label='Commentaire'
                            autoFocus
                            fullWidth
                            variant="outlined"
                            value={currentValue || ''}
                            onChange={(event) => this.handleOnChange(event.target.value)}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.closeDialog.bind(this, '')}>
                            Annuler
                    </Button>
                        <Button type="submit" color="primary" disabled={this.state.currentValue === null}>
                            Valider
                    </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </ThemeProvider>;

    }

}

export default ModifyCommentDialogComponent;
