import { CanauxService } from "services";

export class Metier {

    static etatToString : {[key:number] : string} = 
    {
        1 : 'Initialisation',
        2 : 'Ouverte',
        3 : 'Fermée',
        4 : 'Verrouillée'
    }
    public dateModification: number = null;

    public libelle: string = null;

    public idEtat: number;

    public idReferentielMetier: number = null;

    public prefixe: string = null;

    public idMatomo: number = null;

    public actif: boolean = null;

    public abreviation: string = null;

    public isPublic: number = null;

    private canauxService = CanauxService.getInstance();

    constructor(row?: any) {

        if (row) {
            const {
                dateModification,
                libelle,
                idEtat,
                idReferentielMetier,
                prefixe,
                idMatomo,
                actif,
                abreviation,
                isPublic,
            } = row;

            this.dateModification = dateModification;

            this.libelle = libelle;

            this.idEtat = idEtat;

            this.idReferentielMetier = idReferentielMetier;

            this.prefixe = prefixe;

            this.idMatomo = idMatomo;

            this.actif = !!actif;

            this.abreviation = abreviation;

            this.isPublic = isPublic;

        }
    }

    public save: () => Promise<boolean> = () => {
        return this.canauxService.saveMetier(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.canauxService.deleteMetier(this.idReferentielMetier);
    }


    public toDatabaseObject(): object {
        return {
            date_modification: this.dateModification,
            libelle: this.libelle,
            id_etat: this.idEtat,
            id_referentiel_metier: this.idReferentielMetier,
            prefixe: this.prefixe,
            id_matomo: this.idMatomo,
            actif: this.actif,
            abreviation: this.abreviation,
            public: this.isPublic,
        }
    }



}