import {v4} from 'uuid'
import { B64File } from 'classes/B64File.class';

export enum AnswerType {
    TEXT,
    IMAGE,
    UNDEFINED
}

export class Answer {
    public uid: string = v4();
    
    public idAnswer: number = null;

    public title: string = "";

    public imageUrl: B64File = null;

    public idQuestion: number = null;

    public isCorrect: boolean = false;

    public answerType : AnswerType = AnswerType.UNDEFINED;

    constructor(reponse?: any) {
        if (reponse) {
            const { idAnswer, title, imageUrl, idQuestion, isCorrect } = reponse;

            this.idAnswer = idAnswer;
            this.title = title;
            this.imageUrl = B64File.fromDb(imageUrl);
            this.idQuestion = idQuestion;
            this.isCorrect = isCorrect;
            this.answerType = this.getAnswerType();
        }
    }

    public getAnswerType(): AnswerType {
        if (typeof this.imageUrl === 'object' && this.imageUrl != null) {
            return AnswerType.IMAGE;
        } else if (typeof this.title === 'string' && this.title.length > 0) {
            return AnswerType.TEXT;
        }else{
            return AnswerType.UNDEFINED
        }
    }
}
