export class TypePost {

    public idTypePost: number = null;

    public libelle: string = null;

    public icone: string = null;

    public couleur: string = null;

    public actif: boolean = null;

    public listeRole: number = null;

    public bloque: boolean = null;

    public dateCreation: string = null;

    // Date de création timestamp mise au format DD/MM/AAAA
    public dateCreationFormatDate: string = null;

    constructor(newTypePost?: any) {

        if (newTypePost) {
            const {
                idTypePost,
                libelle,
                icone,
                couleur,
                actif,
                listeRole,
                bloque,
                dateCreation,
                dateCreationFormatDate
            } = newTypePost;

            this.idTypePost = idTypePost
            this.libelle = libelle
            this.icone = icone
            this.couleur = couleur
            this.actif = actif
            this.listeRole = listeRole
            this.bloque = bloque
            this.dateCreation = dateCreation
            this.dateCreationFormatDate = dateCreationFormatDate
        }
    }
}