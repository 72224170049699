import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import CheckCircleSVG from '@material-ui/icons/CheckCircle'
import { CarnetInformationsItem } from "classes/carnet_voyage/informations/CarnetInformationsItem.class"
import React, { Component } from "react"
import { colors } from "Theme"
import style from './AddCarnetVoyageInformationsItemDialog.style'






interface BaseProps {
    triggered: boolean;
    onClose: ((selectedItems: number[]) => void);
    carnetVoyageInformationsItem: CarnetInformationsItem[];
}

interface AddCarnetVoyageInformationsItemDialogState {
    open: boolean;
    selectedItems: number[]

}

type AddCarnetVoyageInformationsItemDialogProps =
    WithStyles<typeof style>
    & BaseProps

class AddCarnetVoyageInformationsItemDialogComponent extends Component<AddCarnetVoyageInformationsItemDialogProps, AddCarnetVoyageInformationsItemDialogState> {

    public readonly state: AddCarnetVoyageInformationsItemDialogState = {
        open: false,
        selectedItems: []
    };

    componentDidMount() {

    }


    public componentDidUpdate(prevProps: AddCarnetVoyageInformationsItemDialogProps): void {
        const { triggered } = this.props;
        const { open } = this.state;
        if (triggered != prevProps.triggered && triggered && !open) {
            this.setState({ open: true });
        }
    }

    public closeDialog(ok: boolean) {
        if (ok) {
            this.props.onClose(this.state.selectedItems);
        } else {
            this.props.onClose([]);
        }
        this.state.selectedItems = [];
        this.setState({
            open: false
        });
    }

    public addItemToSelected(idInformationItem: number) {
        const { selectedItems } = this.state;
        if (selectedItems.includes(idInformationItem)) {
            let indexOf = selectedItems.indexOf(idInformationItem);
            this.setState({
                selectedItems: [...selectedItems.slice(0, indexOf), ...selectedItems.slice(indexOf + 1)]
            });
        } else {
            this.setState({
                selectedItems: [...selectedItems, idInformationItem]
            })
        }
    }

    render() {
        const { classes, carnetVoyageInformationsItem } = this.props;
        const { selectedItems } = this.state;
        return (
            <Dialog open={this.state.open} onClose={() => this.closeDialog(false)} maxWidth='lg'>
                <DialogTitle disableTypography className={classes.dialogTitle}>Ajouter une rubrique </DialogTitle>
                <DialogContent>
                    <List className={classes.list}>
                        {
                            carnetVoyageInformationsItem.map((informationItem, index) =>
                                <ListItem key={informationItem.idInformationItem} onClick={() => this.addItemToSelected(informationItem.idInformationItem)} alignItems='center' >
                                    <ListItemIcon><img src={CarnetInformationsItem.informationsItemImage[informationItem.idInformationItem]} /></ListItemIcon>
                                    <ListItemText disableTypography className={classes.listItemText} >{informationItem.titre}</ListItemText>
                                    <ListItemIcon >{selectedItems.includes(informationItem.idInformationItem) ? <Box margin='auto' color={colors.green.main} width={24} height={24} display='flex' justifyContent='center' alignItems='center'><CheckCircleSVG /></Box> : <Box margin='auto' width={20} height={20} border={`1px solid ${colors.grey.main}`} borderRadius='50%' />}</ListItemIcon>
                                </ListItem>
                            )
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="contained" color="secondary" disabled={false} onClick={() => this.closeDialog(false)}>
                        Annuler
                        </Button>
                    <Button disabled={selectedItems.length === 0} size="large" variant="contained" color="primary" onClick={() => this.closeDialog(true)}>
                        Ajouter
                        </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default withStyles(style)(AddCarnetVoyageInformationsItemDialogComponent);