import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer, createAction } from 'typesafe-actions';
import { loadNotificationsAsync, setIdActeurEnvoyeurNotificationFilter, setDateNotificationNotificationFilter, setIdTypeNotificationFilter, setIdTypeMurNotificationFilter } from './notificationsActions';
import { Notification } from 'classes/notifications/Notification.class';

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction(loadNotificationsAsync.request, () => true)
    .handleAction(loadNotificationsAsync.success, () => false);

const notifications = createReducer<Notification[], RootAction>([])
    .handleAction(loadNotificationsAsync.success, (state, action) => action.payload);


/*****************************************
************ ListPostFilters ************* 
******************************************/

const idActeurEnvoyeur = createReducer<string, RootAction>('')
    .handleAction(setIdActeurEnvoyeurNotificationFilter, (_state, action) => action.payload)


const dateNotification = createReducer<string, RootAction>('')
    .handleAction(setDateNotificationNotificationFilter, (_state, action) => action.payload)

const idType = createReducer<number, RootAction>(-1)
    .handleAction(setIdTypeNotificationFilter, (_state, action) => action.payload)

const idTypeMur = createReducer<number, RootAction>(-1)
    .handleAction(setIdTypeMurNotificationFilter, (_state, action) => action.payload)

const notificationsFilterReducer = combineReducers({
    idActeurEnvoyeur,
    dateNotification,
    idType,
    idTypeMur,
})

const notificationsReducer = combineReducers({
    isLoading,
    notifications,
    notificationsFilters : notificationsFilterReducer
});

export default notificationsReducer;
