import React from 'react';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Axios from 'axios';
import qs from 'qs';

const DragHandle = SortableHandle(({ name }) => <span className="task-dragged">{name}</span>);

const SortableItem = sortableElement(({
    value, index, tickTask, deleteTask,
}) => (
    <li className="task-container">
        <button onClick={tickTask.bind(this, index)} className={`tick-task-button${value.done ? ' done' : ''}`}>
        <CheckIcon className="checkIcon" />
      </button>
        <DragHandle name={<div dangerouslySetInnerHTML={{__html: `${value.message}`}} />} />
    <button className="delete-task-button" onClick={deleteTask.bind(this, index)}>
          <CloseIcon className="closeIcon" />
        </button>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => <ul id="task-list-container">{children}</ul>);

export default class TodoList extends React.Component {
    async onSortEnd({ oldIndex, newIndex }) {
        const newTasks = arrayMove(this.props.tasks, oldIndex, newIndex);
        this.props.setTasks(newTasks);

        try {
            const { data: response } = await Axios.post(
                `index.php?${qs.stringify({
                    rub: 19,
                    p: 5,
                })}`,
                qs.stringify({
                    todos: newTasks
                        .map(({ id_todo }, priority) => ({ id_todo, priority }))
                        .sort(({ priority: priorityA }, { priority: priorityB }) => priorityA - priorityB),
                }),
            );
        } catch (error) {
            this.props.setTasks(arrayMove(this.props.tasks, newIndex, oldIndex));
            alert(error.response.data.message);
        }
    }

    async tickTaskHandler(index) {
        this.props.tasks[index].done = !this.props.tasks[index].done;
        this.props.setTasks(this.props.tasks);

        try {
            const { data } = await Axios.get(
                `index.php?${qs.stringify({
                    rub: 19,
                    p: 4,
                    idTodo: this.props.tasks[index].id_todo,
                    done: Number(this.props.tasks[index].done),
                })}`,
            );
        } catch (error) {
            this.props.tasks[index].done = !this.props.tasks[index].done;
            this.props.setTasks(this.props.tasks);
            alert(error?.response?.data?.message);
        }
    }

    async deleteTaskhandler(index) {
        const { data } = await Axios.get(
            `index.php?${qs.stringify({
                rub: 19,
                p: 3,
                idTodo: this.props.tasks[index].id_todo,
            })}`,
        );

        if (data.success) {
            const tasks = this.props.tasks.filter((task, i) => i != index);
            this.props.setTasks(tasks);
        } else {
            alert(data.message);
        }
    }

    render() {
        return (
            <SortableContainer useDragHandle onSortEnd={this.onSortEnd.bind(this)} pressDelay={150} lockAxis="y">
                {this.props.tasks.map((value, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                        tickTask={this.tickTaskHandler.bind(this, index)}
                        deleteTask={this.deleteTaskhandler.bind(this, index)}
                  />
                ))}
          </SortableContainer>
        );
    }
}

TodoList.propTypes = {
    tasks: PropTypes.array.isRequired,
    setTasks: PropTypes.func.isRequired,
};

