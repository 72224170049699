import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailIcon from '@material-ui/icons/Mail';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Axios from 'axios';
import { replace, push } from 'connected-react-router';
;
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import React, { Fragment, useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'store/rootStore';
import useStyle from './Root.style';
import useTeamUpStyle from 'TeamUp.style';
import clsx from 'clsx';
import { loadCurrentActeurInfosAsync } from 'store/acteur/acteurActions';
import TodoList from './features/todolist/src/AppTodoList';
import AvatarComponent from 'components/avatar/Avatar.component';
import { ThemeProvider } from '@material-ui/styles';
import { blackTheme } from 'Theme'
import Header from 'features/header/src/Header.component';
import AnimationSvg from '../react/assets/navbar/animation.svg'
import ChallengeSvg from '../react/assets/navbar/challenge.svg'
import FilInfoSvg from '../react/assets/navbar/fil_info.svg'
import CarnetVoyageSvg from '../react/assets/navbar/icon_carnet_voyage.svg'
import JournalSvg from '../react/assets/navbar/journal.svg'
import ParametresSvg from '../react/assets/navbar/parametres.svg'
import TodolistSvg from '../react/assets/navbar/todolist.svg'
import { navigationSetCurrentModule } from 'store/navigation/navigationActions';

import { Desktop, Mobile } from 'components/mediaQueries/MediaQueries.component';
import { setLoggedIn } from 'store/acteur/acteurActions';
import { setHeaderStore } from 'features/header/src/store/actions'

const Animations = lazy(() => import('features/animations/src/Animations.component'));
const Challenges = lazy(() => import('features/challenges/src/Challenges.hook.component'));
const Murs = lazy(() => import('features/murs/src/Murs.component'));
const Parametres = lazy(() => import('features/parametres/src/Parametres.component'));
const Notifications = lazy(() => import('features/notification/src/Notifications.component'));
const Login = lazy(() => import('./features/login/src/Login.component'));

interface RootProps {
}
type MenuModule = {
    icon: string,
    title: string,
    link: string
}

const moduleToMenuModule: {
    [key: string]: MenuModule
} = {
    'animation': {
        title: 'Animations',
        icon: AnimationSvg,
        link: 'animation',
    },
    'challenge': {
        title: 'Challenges',
        icon: ChallengeSvg,
        link: 'challenge',
    },
    'fil_info': {
        title: 'Fil d\'information',
        icon: FilInfoSvg,
        link: 'fil-info',
    },
    'carnet_voyage': {
        title: 'Carnet de voyage',
        icon: CarnetVoyageSvg,
        link: '',
    },
    'journal': {
        title: "Mur d'échanges",
        icon: JournalSvg,
        link: 'mur-echange',
    },
    'parametres': {
        title: "Paramètres",
        icon: ParametresSvg,
        link: 'parametre',
    },
    'todolist': {
        title: "To Do List",
        icon: TodolistSvg,
        link: 'todolist',
    }
}

const Root: React.FC<RootProps> = props => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const teamUpClasses = useTeamUpStyle();

    const [jwt, setJwt] = useState(localStorage.getItem('access_token'));

    const [module, pathname, currentActeur, loggedIn] = useSelector((state) => [state.navigation.currentModule, state.router.location.pathname, state.acteur.current, state.acteur.loggedIn], isEqual);

    const [authorizedModules, setAuthorizedModules] = useState<string[]>(null)
    const [open, setOpen] = React.useState(false);


    useEffect(() => {

        Axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error && error.response && error.response.status && 401 === error.response.status) {
                window.localStorage.removeItem('access_token');
                setJwt(null);
                dispatch(setLoggedIn(false))
            }
            return Promise.reject(error);

        });



        var jwt = localStorage.getItem('access_token');
        setJwt(jwt);
        verifyJwt();
    }, []);

    useEffect(() => {
        if (!jwt) {
            dispatch(replace('/login/'))
            $("body").css('backgroundColor', '#fff')
            dispatch(setLoggedIn(false))
        } else {
            dispatch(loadCurrentActeurInfosAsync.request())
            dispatch(setLoggedIn(true))
            getAuthorizedModules();
            $("body").css('backgroundColor', '#eee')
        }
    }, [jwt])

    useEffect(() => {
        if (jwt !== localStorage.getItem('access_token')) {
            setJwt(localStorage.getItem('access_token'))
        }
    }, [pathname])


    useEffect(() => {
        if (jwt && module == 'login') {
            dispatch(navigationSetCurrentModule('parametre'));
            dispatch(replace('/parametre/'))
        }
    }, [module])

    useEffect(() => {
        if (!loggedIn && jwt) {
            disconnect()
        }
    }, [loggedIn])

    async function verifyJwt() {
        await Axios.get(
            `index.php?${qs.stringify({
                p: 1
            })}`
        )
            .catch((data) => { });
    }

    function onClickMenu() {
        setOpen(!open)
    }

    async function getAuthorizedModules() {
        await Axios.get(
            `index.php?${qs.stringify({
                rub: 400,
                p: 1
            })}`
        ).then(({ data: { content } }) => {
            setAuthorizedModules(Object.keys(content))
        })
            .catch((data) => { });
    }

    function navigateTo(link: string, titleModule: string) {
        dispatch(setHeaderStore({
            pageTitle: titleModule
        }))
        dispatch(push(`/${link}/`))
        setOpen(false);
    }

    async function disconnect() {
        await Axios.post(
            `index.php?${qs.stringify({
                p: 2
            })}`
        ).then(({ data: { content } }) => {
            window.localStorage.removeItem('access_token');
            dispatch(navigationSetCurrentModule('login'));
            setJwt(null);
            dispatch(push('/'))
        })
            .catch((data) => { });
    }
    return (

        <Fragment>

            {
                jwt ?
                    <Box >
                        <Header onClickMenu={() => onClickMenu()} />

                        <Drawer
                            anchor='left'
                            open={open}
                            onClose={() => setOpen(false)}
                            className={clsx(classes.drawer)}
                            PaperProps={{ className: clsx(classes.drawer) }}
                        >

                            {
                                currentActeur &&
                                <div className={classes.toolbar}>
                                    <Box onClick={() => navigateTo('parametre', 'Paramètres')}>
                                        <AvatarComponent acteur={currentActeur} border='thin' variant='medium' />
                                    </Box>
                                    <Typography style={{ fontSize: 14, fontWeight: 700, color: 'white' }} className={clsx(teamUpClasses.mt10, teamUpClasses.mb5)}>{currentActeur.prenom} {currentActeur.nom}</Typography>
                                    <Typography style={{ fontSize: 12, color: 'white' }}>{currentActeur.idActeur}</Typography>

                                </div>
                            }
                            <Divider />
                            <List>
                                {
                                    authorizedModules && authorizedModules.map((module, index) => (
                                        <ListItem button key={index} onClick={() => navigateTo(moduleToMenuModule[module].link, moduleToMenuModule[module].title)}>
                                            <ListItemIcon><img src={moduleToMenuModule[module].icon} /></ListItemIcon>
                                            <ListItemText primary={moduleToMenuModule[module].title} />
                                        </ListItem>
                                    ))
                                }
                            </List>

                        </Drawer>
                        <Suspense fallback={ <CircularProgress />}>
                            <Switch>
                                <Route path='/animation/'>
                                    <Animations />
                                </Route>
                                <Route path='/challenge/'>
                                    <Challenges />
                                </Route>
                                <Route path='/todolist/'>
                                    <TodoList />
                                </Route>
                                <Route path='/parametre/'>
                                    <Parametres />
                                </Route>
                                <Route path='/mur-echange/'>
                                    <Desktop>
                                        <Murs typeMur={2} />
                                    </Desktop>
                                    <Mobile>
                                        <div className={classes.containerListPosts}>
                                            <Murs typeMur={2} />
                                        </div>
                                    </Mobile>
                                </Route>
                                <Route path='/fil-info/'>
                                    <Desktop>
                                        <Murs typeMur={1} />
                                    </Desktop>
                                    <Mobile>
                                        <div className={classes.containerListPosts}>
                                            <Murs typeMur={1} />
                                        </div>
                                    </Mobile>
                                </Route>
                                <Route path='/notification/' >
                                    <Notifications />
                                </Route>
                                <Route path='/' >
                                    <Redirect to={`/${module}/`} />
                                </Route>
                            </Switch>
                        </Suspense>
                    </Box>
                    :
                    <Suspense fallback={ <CircularProgress />}>
                        <Switch>

                            <Route path="/login/">
                                <Login />
                            </Route>
                            <Route path='/'>
                                <Redirect to='/login/' />
                            </Route>
                        </Switch>
                    </Suspense>
            }


        </Fragment>
    );
}


export default Root;