import Axios from "axios";
import qs from "qs";
import { SignalementsFilterState, SignalementsFiltersInfos } from "features/admin/signalements/src/store/types";
import { Signalement } from "classes/signalements/Signalement.class";

export class SignalementsService {
    private static instance: SignalementsService;

    public static getInstance(): SignalementsService {
        if (!SignalementsService.instance) {
            SignalementsService.instance = new SignalementsService();
        }

        return SignalementsService.instance;
    }

    private static rubriqueMur = IS_ADMIN ? 260 : 26;

    public async loadSignalements(signalementsFilters: SignalementsFilterState): Promise<Signalement[]> {
        const params: any = {
            rub: SignalementsService.rubriqueMur,
            filters: JSON.stringify(signalementsFilters),
            p: 1
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: signalements } }) => signalements.map((signalement: any) => new Signalement(signalement)));
    }

    public async loadSignalementsInfos(): Promise<SignalementsFiltersInfos> {
        const params: any = {
            rub: SignalementsService.rubriqueMur,
            p: 2
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {

                var ret: SignalementsFiltersInfos = {
                    murs: {},
                    typesAlertes: {}
                }

                return content;
                console.log(content);
                return ret;

            });

    }

    public async saveSignalement(signalement: Signalement): Promise<void> {
        return await Axios.post(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 3
        })}`, qs.stringify({
            signalement: JSON.stringify(signalement.toDatabaseObject()),
        }));
    }


    public async signalerPost(typeMur: number, idMurPost: number, raison: number, description: string): Promise<boolean> {
        return await Axios.post(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 4
        })}`, qs.stringify({
            typeMur,
            idMurPost,
            raison,
            description
        }))
            .then((data) =>
                true
            )
            .catch(({ response: { data: { message } } }) =>
                false
            );
    }

    

    public async loadSignalementsCount(): Promise<number> {
        const params: any = {

        };
        return await Axios.get(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 5
        })}`)
            .then(({ data: { content: signalementsCount } }) => signalementsCount);
    }

    public async signalerComment(typeMur: number, idMurCommentaire: number, raison: number, description: string): Promise<boolean> {
        return await Axios.post(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 6
        })}`, qs.stringify({
            typeMur,
            idMurCommentaire,
            raison,
            description
        }))
            .then((data) =>
                true
            )
            .catch(({ response: { data: { message } } }) =>
                false
            );
    }
}
