import qs from 'qs';
import Axios, { CancelTokenSource } from 'axios';
import { Challenge } from 'classes/challenges/Challenge.class';
import { Observable, Subject } from 'rxjs';
import { ChallengeEdito } from 'classes/challenges/Edito.class';
import { B64File } from 'classes/B64File.class';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { Telechargement } from 'classes/challenges/Telechargement.class';
import {
    ThemesIndexedByRubriquesLabels,
    ChallengeRubriquesAndInfos,
    ListChallengesFilterState,
    LoadClassements,
    ListClassementsEtDate,
} from 'features/challenges/src/store/types';
import { ChallengeThemePredefini } from 'classes/challenges/ChallengeThemePredefini.class';
import { ChallengeThemePredefiniImage } from 'classes/challenges/ChallengeThemePredefiniImage.class';
import { ChallengeThemePersonnalise } from 'classes/challenges/ChallengeThemePersonnalise.class';
import { ChallengeThemePersonnaliseImage } from 'classes/challenges/ChallengeThemePersonnaliseImage.class';
import { ChallengeActualite } from 'classes/challenges/ChallengeActualite.class';
import { ChallengeReglement } from 'classes/challenges/Reglement.class';
import { ChallengeRubrique } from 'classes/challenges/ChallengeRubrique.class';
import { ChallengeRubriqueInfos } from 'classes/challenges/ChallengeRubriqueInfos.class';
import { ChallengeDestinationRubrique } from 'classes/challenges/ChallengeDestinationRubrique.class';
import { ChallengeDestinationRubriqueInfos } from 'classes/challenges/ChallengeDestinationRubriqueInfos.class';
import { Video } from 'classes/videos/Video.class';
import { VideoLike } from 'classes/videos/VideoLike.class';
import { Suivi } from 'classes/challenges/suivi/Suivi.class';
import { MulticritereClassement } from 'classes/challenges/suivi/MulticritereClassement.class';
import { MulticritereGroupe } from 'classes/challenges/suivi/MulticritereGroupe.class';
import { MulticritereCritere } from 'classes/challenges/suivi/MulticritereCritere.class';

const rub = IS_ADMIN ? 290 : 29;
export class ChallengeService {
    private static instance: ChallengeService;

    public static getInstance(): ChallengeService {
        if (!ChallengeService.instance) {
            ChallengeService.instance = new ChallengeService();
        }

        return ChallengeService.instance;
    }

    public async findAll(filters: ListChallengesFilterState): Promise<Challenge[]> {
        const params: any = {
            rub: rub,
            p: 3,
            filters: JSON.stringify(filters),
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: challenges } }) => {
            return challenges.map(this.mapToChallenge);
        });
    }

    public async find(idChallenge: number): Promise<Challenge> {
        const params: any = {
            rub: rub,
            p: 2,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: challenge } }) => this.mapToChallenge(challenge));
    }

    public async getChallengeAndRubrique(
        idChallenge: number,
        idRubriqueInfos: number,
    ): Promise<{
        challenge: Challenge;
        challengeRubrique: ChallengeRubrique;
    }> {
        const params: any = {
            rub: rub,
            p: 49,
            idChallenge,
            idRubriqueInfos,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => {
            let ret: {
                challenge: Challenge;
                challengeRubrique: ChallengeRubrique;
            } = {
                challenge: null,
                challengeRubrique: null,
            };
            ret.challenge = this.mapToChallenge(content['challenge']);
            ret.challengeRubrique = new ChallengeRubrique(content['challengeRubrique']);
            return ret;
        });
    }

    public save(challenge: Challenge, themeImages?: ChallengeThemePersonnaliseImage[]): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 1,
            })}`,
            qs.stringify({
                challenge: JSON.stringify(challenge.toDatabaseObject()),
                themeImages: JSON.stringify(themeImages),
            }),
        )
            .then(({ data: { content } }) => null)
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    /**
     * Enregistre une actualité
     * @param challengeActualite
     */
    public saveActualite(challengeActualite: ChallengeActualite): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 30,
            })}`,
            qs.stringify({
                actualite: JSON.stringify(challengeActualite.toDatabaseObject()),
            }),
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);
    }

    /**
     * Supprime une actualité
     * @param idActualite l'id de l'actualité à supprimer
     */
    public async deleteActualite(idActualite: number): Promise<Boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 33,
                idActualite,
            })}`,
            qs.stringify({
                idActualite: idActualite,
            }),
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);
    }

    /**
     * Charge la liste des actualités d'un challenge
     * @param idChallenge l'id du challenge dont on veut charger les actualités
     */
    public async loadListActualites(idChallenge: number): Promise<ChallengeActualite[]> {
        const params: any = {
            rub: rub,
            p: 32,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: actualites } }) => {
            return actualites.map(this.mapToChallengeActualite);
        });
    }

    /**
     * Charge les infos d'une actualité
     * @param idActualite l'id de l'activité dont on veut charger les infos
     */
    public async loadActualite(idActualite: number): Promise<ChallengeActualite> {
        const params: any = {
            rub: rub,
            p: 31,
            idActualite,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                throw message;
            })
            .then(({ data: { content: actualite } }) => new ChallengeActualite(actualite));
    }

    /**
     * Ajoute un like à une actualité
     * @param challengeActualite
     */
    public addActualiteLike(idActualite: number): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 37,
            })}`,
            qs.stringify({
                idActualite: JSON.stringify(idActualite),
            }),
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);
    }

    /**
     * Supprime un like à une actualité
     * @param challengeActualite
     */
    public removeActualiteLike(idActualite: number): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 38,
            })}`,
            qs.stringify({
                idActualite: JSON.stringify(idActualite),
            }),
        )
            .then(({ data: { content } }) => true)
            .catch(() => false);
    }

    public async delete(idChallenge: number): Promise<boolean> {
        const params: any = {
            rub: rub,
            p: 5,
            idChallenge: idChallenge,
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(() => true);
    }

    public async duplicate(idChallenge: number): Promise<void> {
        const params: any = {
            rub: rub,
            p: 7,
            idChallenge,
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`);
    }

    public async loadPredefinedThemes(): Promise<ThemesIndexedByRubriquesLabels> {
        const params: any = {
            rub: rub,
            p: 9,
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => {
            let ret: ThemesIndexedByRubriquesLabels = {};
            Object.entries(content).map(([key, value]) => {
                let val: (ChallengeThemePredefini | ChallengeThemePersonnalise)[] = [];
                (value as any).map((theme: any) => {
                    if (theme.hasOwnProperty('idThemePredefini')) {
                        val.push(new ChallengeThemePredefini(theme));
                    } else {
                        val.push(new ChallengeThemePersonnalise(theme));
                    }
                });

                ret[key] = val;
            });
            return ret;
        });
    }

    private mapToChallenge(challenge: any): Challenge {
        return new Challenge(challenge);
    }

    private mapToEdito(edito: any): ChallengeEdito {
        return new ChallengeEdito(edito);
    }

    private mapToReglement(reglement: any): ChallengeReglement {
        return new ChallengeReglement(reglement);
    }
    /**
     * Méthode privée permettant de créer un nouvel object ChallengeDestinationRubrique
     * @param challengeDestinationRubrique la rubrique à maper
     * @return l'objet ChallengeDestinationRubrique créé
     */
    private mapToDestinationRubrique(challengeDestinationRubrique: any): ChallengeDestinationRubrique {
        return new ChallengeDestinationRubrique(challengeDestinationRubrique);
    }

    /**
     * Méthode privée permettant de créer un nouvel object ChallengeDestinationRubriqueInfos
     * @param challengeDestinationRubriqueInfos la rubrique infos à maper
     * @return l'objet ChallengeDestinationRubriqueInfos créé
     */
    private mapToDestinationRubriqueInfos(challengeDestinationRubriqueInfos: any): ChallengeDestinationRubriqueInfos {
        return new ChallengeDestinationRubriqueInfos(challengeDestinationRubriqueInfos);
    }

    public async findEdito(idChallenge: number, admin?: boolean): Promise<ChallengeEdito> {
        const params: any = {
            rub: rub,
            p: 20,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: edito } }) => this.mapToEdito(edito));
    }

    public async saveEdito(edito: ChallengeEdito, admin?: boolean): Promise<Boolean> {
        const params: any = {
            rub: rub,
            p: 21,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ edito: JSON.stringify(edito.toRaw()) }));
    }

    public async loadTelechargements(idChallenge: number): Promise<B64File[]> {
        const params: any = {
            rub: rub,
            p: 10,
            idChallenge: idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => B64File.rowsToArray(content));
    }

    public addFileTelechargements(b64File: B64File, idChallenge: number): RxAxios<{ content: B64File }> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: rub,
                p: 11,
            })}`,
            data: qs.stringify({
                b64File: JSON.stringify(b64File),
                idChallenge: idChallenge,
            }),
        });
    }

    public async removeFileTelechargements(b64File: B64File): Promise<B64File> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 12,
            })}`,
            qs.stringify({
                b64File: JSON.stringify(b64File),
            }),
        ).then(({ data: { content } }) => B64File.rowsToFile(content));
    }

    public async updateFileTelechargementsIndex(index: { oldIndex: number; newIndex: number }, challenge: Challenge): Promise<B64File[]> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 13,
            })}`,
            qs.stringify({
                oldIndex: index.oldIndex,
                newIndex: index.newIndex,
                idChallenge: challenge.idChallenge,
            }),
        ).then(({ data: { content } }) => B64File.rowsToArray(content));
    }

    /**
     * Méthode privée permettant de créer un nouvel object ChallengeActualite
     * @param challengeActualite l'actualité à maper
     */
    private mapToChallengeActualite(challengeActualite: any): ChallengeActualite {
        return new ChallengeActualite(challengeActualite);
    }

    public async findReglement(idChallenge: number, admin?: boolean): Promise<ChallengeReglement> {
        const params: any = {
            rub: rub,
            p: 22,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: reglement } }) => this.mapToReglement(reglement));
    }

    /**
     * Charge la liste des rubriques de la partie destination d'un challenge
     * @param idChallenge l'id du challenge dont on veut charger les rubriques de destination
     */
    public async loadDestinationRubriques(idChallenge: number): Promise<ChallengeDestinationRubrique[]> {
        const params: any = {
            rub: rub,
            p: 34,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: rubriques } }) => {
            return rubriques.map(this.mapToDestinationRubrique);
        });
    }

    public async saveReglement(reglement: ChallengeReglement, admin?: boolean): Promise<Boolean> {
        const params: any = {
            rub: rub,
            p: 23,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ reglement: JSON.stringify(reglement.toRaw()) }));
    }

    public async loadChallengeRubriques(idChallenge: number): Promise<ChallengeRubrique[]> {
        const params: any = {
            rub: rub,
            p: 6,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => null);
    }
    public async loadChallengeRubriquesInfos(): Promise<ChallengeRubriqueInfos[]> {
        const params: any = {
            rub: rub,
            p: 7,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => null);
    }

    public async loadChallengeRubriquesAndInfos(idChallenge: number, rubriquesOrInfos: number): Promise<ChallengeRubriquesAndInfos> {
        const params: any = {
            rub: rub,
            p: 6,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => {
            let ret: ChallengeRubriquesAndInfos = {
                rubriques: null,
                infos: null,
            };

            console.log(content.rubriques);
            ret.rubriques = content.rubriques.map((rubrique: any) => {
                return new ChallengeRubrique(rubrique);
            });
            ret.infos = content.infos.map((info: any) => {
                return new ChallengeRubriqueInfos(info);
            });

            return ret;
        });
    }

    public async saveRubrique(rubrique: ChallengeRubrique): Promise<string> {
        const params: any = {
            rub: rub,
            p: 7,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ rubrique: JSON.stringify(rubrique.toDatabaseObject()) }))
            .then(({ data: { content } }) => '')
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message || 'Erreur inconnue';
            });
    }

    public async deleteRubrique(rubrique: ChallengeRubrique): Promise<boolean> {
        const params: any = {
            rub: rub,
            p: 4,
        };

        return await Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ rubrique: JSON.stringify(rubrique.toDatabaseObject()) }));
    }

    /**
     * Charge les informations de toutes les rubriques de la partie destination
     */
    public async loadAllDestinationRubriquesInfos(): Promise<ChallengeDestinationRubriqueInfos[]> {
        const params: any = {
            rub: rub,
            p: 35,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content: rubriquesInfos } }) => {
            return rubriquesInfos.map(this.mapToDestinationRubriqueInfos);
        });
    }

    /**
     * Enregistre les rubriques de destination d'un challenge
     * @param rubriquesDestination la liste des rubriques de destination à enregistrer
     */
    public async saveRubriquesDestination(rubriquesInfos: {
        rubriquesDestination: ChallengeDestinationRubrique[];
        idChallenge: number;
    }): Promise<ChallengeDestinationRubrique[]> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 36,
            })}`,
            qs.stringify({
                rubriquesDestination: JSON.stringify(rubriquesInfos.rubriquesDestination),
                idChallenge: rubriquesInfos.idChallenge,
            }),
        ).then(({ data: { content } }) => content.map((rubriqueDestination: any) => new ChallengeDestinationRubrique(rubriqueDestination)));
    }

    public async saveVideo(idChallenge: number, video: Video): Promise<Video> {
        video.leChallenge = idChallenge;
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 14,
            })}`,
            qs.stringify({
                video: JSON.stringify(video.toDatabaseObject()),
            }),
        )
            .then(({ data: { content } }) => new Video(content))
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    public async saveVideoLike(videoLike: VideoLike): Promise<string> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 18,
            })}`,
            qs.stringify({
                videoLike: JSON.stringify(videoLike.toDatabaseObject()),
            }),
        )
            .then(({ data: { content } }) => '')
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    public async loadVideos(idChallenge: number): Promise<Video[]> {
        const params: any = {
            rub: rub,
            p: 16,
            idChallenge: idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => Video.rowsToArray(content));
    }

    public async removeVideo(idChallenge: number, video: Video): Promise<Video> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 17,
            })}`,
            qs.stringify({
                video: JSON.stringify(video.toDatabaseObject()),
            }),
        ).then(({ data: { content } }) => new Video(content));
    }

    public async acceptRules(idChallenge: number): Promise<boolean> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 24,
            })}`,
            qs.stringify({
                idChallenge: idChallenge,
            }),
        )
            .then(({ data: { content } }) => true)
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return false;
            });
    }

    public async saveSuivi(suivi: Suivi, criteresParam?: MulticritereCritere[]): Promise<string> {
        let postData: { suivi: string; criteres: string } = {
            suivi: JSON.stringify(suivi.toDatabaseObject()),
            criteres: null,
        };
        if (criteresParam) {
            postData['criteres'] = JSON.stringify(criteresParam.map((critere) => critere.toDatabaseObject()));
        }
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 40,
            })}`,
            qs.stringify(postData),
        )
            .then(({ data: { content } }) => content)
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    public async deleteSuivi(suivi: Suivi): Promise<string> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 42,
            })}`,
            qs.stringify({
                idSuivi: suivi.idSuivi,
            }),
        )
            .then(({ data: { content } }) => '')
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    public async loadChallengeSuivis(idChallenge: number): Promise<Suivi[]> {
        const params: any = {
            rub: rub,
            p: 41,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                let ret: Suivi[] = [];
                ret = content.map((suivi: any) => {
                    return new Suivi(suivi);
                });
                return ret;
            })
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    /**
     * Méthode de rappatriement des suivis pour la version mobile
     * C'est à dire uniquement les suivis actifs dans lesquels l'acteur courant participe
     * @param idChallenge 
     * @returns la liste des suivis pour la version mobile
     */
    public async loadChallengeSuivisMobile(idChallenge: number): Promise<Suivi[]> {
        const params: any = {
            rub: rub,
            p: 50,
            idChallenge,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                let ret: Suivi[] = [];
                ret = content.map((suivi: any) => {
                    return new Suivi(suivi);
                });
                return ret;
            })
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    public async loadChallengeSuivi(idSuivi: number): Promise<Suivi> {
        const params: any = {
            rub: rub,
            p: 43,
            idSuivi,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                return new Suivi(content);
            })
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    public async importFile(idSuivi: number, file: B64File, sp?: number): Promise<{ success?: string; error?: string; level?: number }> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 44,
                sp,
            })}`,
            qs.stringify({
                idSuivi,
                upload: JSON.stringify(file),
            }),
        )
            .then(({ data: { content } }) => content)
            .catch(function ({
                response: {
                    data: { message, level },
                },
            }) {
                return { error: message, level : level };
            });
    }

    /**
     * Chargement des classements d'un suivi
     * @param idSuivi id du suivi dont on veut le classement
     * @returns classements de type MulticritereClassement[] et dateResultat de type number
     */
    public async loadClassementsMulticritere(filter: LoadClassements): Promise<ListClassementsEtDate> {
        const params: any = {
            rub: rub,
            p: 45,
            idSuivi: filter.idSuivi,
            idGroupe: filter.idGroupe,
            limit: filter.limit,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                let ret: ListClassementsEtDate = {
                    classements: null,
                    dateResultat: null,
                };
                ret.classements = content.classements.map((classement: any) => {
                    return new MulticritereClassement(classement);
                });
                ret.dateResultat = content.dateResultat;

                return ret;
            })
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }

    /**
     * Chargement du classement d'un acteur pour un suivi donné
     * @param idSuivi id du suivi dont on veut le classement pour l'acteur courant
     * @returns la liste des classements pour l'acteur courant.
     */
    public async loadClassementMulticritereParActeur(idSuivi: number): Promise<MulticritereClassement> {
        const params: any = {
            rub: rub,
            p: 47,
            idSuivi,
        };

        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => new MulticritereClassement(content))
            .catch(function ({
                response: {
                    data: { message },
                },
            }) {
                return message;
            });
    }
}
