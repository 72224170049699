import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import MonProfilReducer from "../monProfil/store/reducer";



const ParametresReducer = {
    monProfil : MonProfilReducer
};

export default ParametresReducer;
