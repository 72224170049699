// import createStyles from '@material-ui/styles/createStyles'
// import { Theme } from 'Theme'
// import { colors } from 'Theme';

// export default ({ spacing, palette }: typeof Theme) => createStyles({
//     containerListPosts: {
//         width: '100%',
//         marginRight: 'auto',
//         marginLeft: 'auto',
//         transition: '0.5s',
//         paddingX: spacing(1),
//         '@media (min-width: 576px)': {
//             maxWidth: 540
//         },
//         '@media (min-width: 768px)': {
//             maxWidth: 720
//         },
//         '@media (min-width: 992px)': {
//             maxWidth: 960
//         },
//         '@media (min-width: 1200px)': {
//             maxWidth: 1140
//         }
//     },
//     disabledFilter: {
//         backgroundColor: colors.grey.dark,
//         color: colors.lighterGrey.main
//     },
//     containerFilterDisplayed: {
//         paddingTop: 48
//     },
//     fabAddPost: {
//         position: 'fixed',
//         bottom: spacing(1.5),
//         right: spacing(1.5),
//         backgroundColor : colors.orange.main
//     },
//     typePostIcon: {
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         height: 34,
//         width: 34,
//         backgroundSize: 18,
//         borderRadius: '50%',
//         marginLeft: 5
//     },
//     typePostText: {
//         backgroundColor: 'white',
//         borderRadius: 8,
//         verticalAlign: 'center',
//         padding: 5
//     }
// });

import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    containerListPosts: {
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        transition: '0.5s',
        paddingX: spacing(1),
        '@media (min-width: 576px)': {
            maxWidth: 540
        },
        '@media (min-width: 768px)': {
            maxWidth: 720
        },
        '@media (min-width: 992px)': {
            maxWidth: 960
        },
        '@media (min-width: 1200px)': {
            maxWidth: 1140
        }
    },
    disabledFilter: {
        backgroundColor: colors.grey.dark,
        color: colors.lighterGrey.main
    },
    containerFilterDisplayed: {
        paddingTop: 48
    },
    fabAddPost: {
        position: 'fixed',
        bottom: spacing(1.5),
        right: spacing(1.5),
        backgroundColor : colors.orange.main
    },
    typePostIcon: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 34,
        width: 34,
        backgroundSize: 18,
        borderRadius: '50%',
        marginLeft: 5
    },
    typePostText: {
        backgroundColor: 'white',
        borderRadius: 8,
        verticalAlign: 'center',
        padding: 5
    }
}));