import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SettingDrawer from './../../parametres/src/monProfil/aPropos/SettingDrawer.component'
import { ThemeProvider } from '@material-ui/core';
import { Theme } from 'Theme';

export default class CreateTaskDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            editionDialog: {
                open: false,
                setting: {
                    type: 'text',
                    name: 'Tâche',
                    action: 'Ajouter', /* Pour s'afficher dans le titre */
                    value:'', 
                    prefix:'une', /* Pour être affiché dans le titre du drawer mais pas le formulaire */
                    regex: /^.+$/ /* Empêcher un champ vide */
                }
            }
        };
    }

    async closeDialog(save) {
        if (save) {
            this.addTask();
            this.setState({
                ...this.state,
                editionDialog: {
                    ...this.state.editionDialog,
                    setting: {
                        ...this.state.editionDialog.setting,
                        value: ''
                    },
                },
            });
        }
        this.setState({ saving: true });
        await this.props.createTaskDialogHandler(true);
        this.setState({ saving: false });
    }

    addTask() {
        this.props.createTaskDialogHandler(false, this.state.editionDialog.setting.value);
    }

    render() {
        return (
            <ThemeProvider theme={Theme}>
                {
                    this.props.creatingTask /* Si on décide d'ouvrir le drawer */
                    && <SettingDrawer
                        setting={this.state.editionDialog.setting}
                        onClose={this.closeDialog.bind(this)} />
                }
            </ThemeProvider>
        );
    }
}

CreateTaskDialog.propTypes = {
    creatingTask: PropTypes.bool.isRequired,
    createTaskDialogHandler: PropTypes.func.isRequired,
};
