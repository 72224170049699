import Axios from 'axios';
import { Acteur } from 'classes/Acteur.class';
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { Participation } from 'classes/animations/Participation.class';
import { Question } from 'classes/animations/Question.class';
import { Quiz } from 'classes/animations/Quiz.class';
import { QuizBadge } from 'classes/animations/QuizBadge.class';
import { Rankings } from 'classes/animations/Ranking.type';
import { B64File } from 'classes/B64File.class';
import qs from 'qs';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { isArray } from 'util';
import { AnimationsService } from './Animations.service';

export class QuizService {
    private static instance: QuizService;

    public static getInstance(): QuizService {
        if (!QuizService.instance) {
            QuizService.instance = new QuizService();
        }

        return QuizService.instance;
    }

    private pages = {
        AJAX_ADMIN_GET_QUESTIONS: 1,
        AJAX_SAVE_QUIZ_QUESTIONS: 2,
        AJAX_SUBMIT_ACTEUR_ANSWERS: 3,
        AJAX_ADMIN_GET_RANKINGS: 3,
        AJAX_GET_RANKING: 4,
        AJAX_ADMIN_DELETE_RANKING: 4,
        AJAX_SAVE_ANIMATION: 5,
        AJAX_ADMIN_GET_RESULTS_STATISTICS: 5,
        AJAX_ADMIN_GET_NON_PARTICIPANTS: 6,
        AJAX_ADMIN_GET_ACTEUR_ANSWERS: 7,
        AJAX_ADMIN_IMPORT_QUESTIONS: 9,
        AJAX_ADMIN_GET_QUESTIONS_QUIZ_COUNT: 10,
        AJAX_ADMIN_GET_QUIZ_BADGE : 11
    };

    public animationsService = AnimationsService.getInstance();

    public async find(idQuiz: number): Promise<Quiz> {
        return (await this.animationsService.find(idQuiz, AnimationTypes.Quiz)) as Quiz;
    }

    // Classements
    public async findRanking(idQuiz: number): Promise<Rankings> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: 21,
                idQuiz,
                p: this.pages.AJAX_GET_RANKING,
            })}`
        ).then(({ data: { content } }) => {
            return Object.values(content).map(({ acteur, participation }: any) => ({
                acteur: new Acteur(acteur),
                participation: new Participation(participation),
            }))
        }).catch(function ({ response: { data: { message } } }) {
            return message;
        });
    }

    // Classements
    public async findRankings(idQuiz: number): Promise<Rankings> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: 23,
                idQuiz,
                p: this.pages.AJAX_ADMIN_GET_RANKINGS,
            })}`
        ).then(({ data: { content } }) => {
            return content.map(({ acteur, participation }: any) => ({
                acteur: new Acteur(acteur),
                participation: new Participation(participation),
            }));
        }).catch(function ({ response: { data: { message } } }) {
            return message;
        });
    }



    public async save(quiz: Quiz): Promise<void> {

    }

    // Participations
    public async findParticipation(idQuiz: number, autoCreate: boolean = false): Promise<Participation> {
        return await Axios.get(
            `index.php?${qs.stringify({
                rub: 21,
                idQuiz,
                create: Number(autoCreate)
            })}`,
        ).then(({ data: { content: participation } }) => new Participation(participation));
    }

    public async getQuestionsQuiz(quizId: number): Promise<Question[]> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_QUESTIONS,
            quizId
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: questions } }) => questions.map((q: any) => new Question(q)));
    }

    public async getQuestionsQuizCount(quizId: number): Promise<number> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_QUESTIONS_QUIZ_COUNT,
            quizId
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    // Questions
    public saveQuestions(idQuiz: number, questions: Question[]): RxAxios<any> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: 23,
                p: this.pages.AJAX_SAVE_QUIZ_QUESTIONS
            })}`,
            data: qs.stringify({
                questions: JSON.stringify(questions),
                idQuiz: idQuiz
            })
        });
    }


    public async deleteRanking(idParticipation: number): Promise<void> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_DELETE_RANKING,
            idParticipation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`);
    }

    public async getResultsStatistics(idQuiz: number): Promise<{
        stats: { [key: number]: number },
        reponsesTextes: { [key: number]: { reponses: Array<string>, displayMore: boolean } }
    }> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_RESULTS_STATISTICS,
            idQuiz
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: stats } }) => {
                return {
                    'stats': stats['stats'].reduce((map: { [key: number]: number }, stat: { idAnswer: number, nbrReponses: number }) => {
                        if (isArray(map)) {
                            map = {};
                        }
                        map[stat.idAnswer] = stat.nbrReponses;
                        return map
                    }, []),
                    'reponsesTextes': stats['reponsesTextes'].reduce((map: any, reponse: { idQuestion: number, answers: string }) => {
                        if (isArray(map)) {
                            map = {};
                        }
                        if (!map[reponse.idQuestion]) {
                            map[reponse.idQuestion] = {};
                            map[reponse.idQuestion].reponses = [reponse.answers];
                        } else {
                            map[reponse.idQuestion].reponses.push(reponse.answers);
                        }
                        map[reponse.idQuestion].displayMore = false;
                        return map
                    }, [])
                }
            });;

    }

    public async getNonParticipants(idQuiz: number): Promise<Acteur[]> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_NON_PARTICIPANTS,
            idQuiz
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: acteurs } }) => acteurs.map((a: any) => new Acteur(a)));

    }


    public async getActeurAnswers(idParticipation: number) {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_ACTEUR_ANSWERS,
            idParticipation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`)
    }

    public async importQuestions(idAnimation: number, file: B64File): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 23,
                p: this.pages.AJAX_ADMIN_IMPORT_QUESTIONS,
            })}`,
            qs.stringify({
                animation: idAnimation,
                file: JSON.stringify(file)
            })
        ).then((data) => {
        }).catch(function ({ response: { data: { message } } }) {
            return message;
        });
    }

    public async getBadgeQuiz( idAnimation: number ) : Promise<QuizBadge>{
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_QUIZ_BADGE,
            idAnimation
        };
        return await Axios.get(`index.php?${qs.stringify(params)}`).then(({data : { content : quizBadge}})=>{
            return new QuizBadge(quizBadge);
        })
    }
}
