import { from, of, merge } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap, concatMap } from "rxjs/operators";
import { isActionOf, action } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import {
    loadCarnet,
    loadItems,
    loadItem,
    loadSchedule,
    loadInformations,
    saveInformations,
    saveSchedule,
    loadContacts,
    saveContacts,
    loadCarnetQuestionnaires,
    saveEdito,
    loadEdito,
    loadHotel,
    saveHotel,
    loadTelechargements,
    addFileTelechargements,
    removeFileTelechargements,
    resetCarnet,
    loadParticipants,
    setProgressSaveFiles
} from "./actions";

import { newSnackbar, SnackbarType } from "store/snackbars/snackBarsActions";
import { loadFrontCarnetQuestionnaires } from "features/animations/src/store/actions";
import { B64File } from "classes/B64File.class";

const loadCarnetEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadCarnet.request)),
        switchMap(() =>
            from(carnetVoyageService.load()).pipe(
                map(loadCarnet.success),
                catchError(message => of(loadCarnet.failure(message)))
            )
        )
    );

const loadItemsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadItems.request)),
        switchMap(() =>
            from(carnetVoyageService.loadItems()).pipe(
                map(loadItems.success),
                catchError(message => of(loadItems.failure(message)))
            )
        )
    );

const loadScheduleEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadSchedule.request)),
        switchMap(() =>
            from(carnetVoyageService.loadSchedule()).pipe(
                map(loadSchedule.success),
                catchError(message => of(loadSchedule.failure(message)))
            )
        )
    );

const saveScheduleEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(saveSchedule.request)),
        switchMap(action =>
            from(carnetVoyageService.saveSchedule(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Les informations ont bien été enregistrées.`
                        }
                    }),
                    saveSchedule.success(),
                    loadSchedule.request()
                ]),
                catchError(message => of(saveSchedule.failure(message)))
            )
        )
    );

const loadParticipantsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadParticipants.request)),
        switchMap(() =>
            from(carnetVoyageService.loadParticipants()).pipe(
                map(loadParticipants.success),
                catchError(message => of(loadParticipants.failure(message)))
            )
        )
    );

const loadContactsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadContacts.request)),
        switchMap(() =>
            from(carnetVoyageService.loadContacts()).pipe(
                map(loadContacts.success),
                catchError(message => of(loadContacts.failure(message)))
            )
        )
    );

const saveContactsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(saveContacts.request)),
        switchMap(action =>
            from(carnetVoyageService.saveContacts(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Les informations ont bien été enregistrées.`
                        }
                    }),
                    saveContacts.success(action)
                ]),
                catchError(message => of(saveContacts.failure(message)))
            )
        )
    );

const loadCarnetQuestionnairesEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf([loadCarnetQuestionnaires.request, loadFrontCarnetQuestionnaires.request])),
        switchMap(() =>
            from(carnetVoyageService.loadCarnetQuestionnaires()).pipe(
                map(loadCarnetQuestionnaires.success),
                catchError(message => of(loadCarnetQuestionnaires.failure(message)))
            )
        )
    );

const loadEditoEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadEdito.request)),
        switchMap(() =>
            from(carnetVoyageService.loadEdito()).pipe(
                map(loadEdito.success),
                catchError(message => of(loadEdito.failure(message)))
            )
        )
    );

const saveEditoEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(saveEdito.request)),
        switchMap(action =>
            from(carnetVoyageService.saveEdito(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Les informations ont bien été enregistrées.`
                        }
                    }),
                    saveEdito.success(action)
                ]),
                catchError(message => {console.log(message); return of(saveEdito.failure(message))})
            )
        )
    );

const loadHotelEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadHotel.request)),
        switchMap(() =>
            from(carnetVoyageService.loadHotel()).pipe(
                map(loadHotel.success),
                catchError(message => of(loadHotel.failure(message)))
            )
        )
    );

const saveHotelEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(saveHotel.request)),
        switchMap(action =>
            from(carnetVoyageService.saveHotel(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Les informations ont bien été enregistrées.`
                        }
                    }),
                    saveHotel.success(action)
                ]),
                catchError(message => of(saveHotel.failure(message)))
            )
        )
    );

const loadInformationsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadInformations.request)),
        switchMap(() =>
            from(carnetVoyageService.loadInformations()).pipe(
                map(loadInformations.success),
                catchError(message => of(loadInformations.failure(message)))
            )
        )
    );

const loadItemEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadItem.request)),
        switchMap(action =>
            from(carnetVoyageService.loadItem(action.payload)).pipe(
                map(loadItem.success),
                catchError(message => of(loadItem.failure(message)))
            )
        )
    );

const saveInformationsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(saveInformations.request)),
        switchMap(action =>
            from(carnetVoyageService.saveInformations(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Les informations ont bien été enregistrées.`
                        }
                    }),
                    saveInformations.success(action)
                ]),
                catchError(message => of(saveInformations.failure(message)))
            )
        )
    );

const loadTelechargementsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(loadTelechargements.request)),
        switchMap(() =>
            from(carnetVoyageService.loadTelechargements()).pipe(
                map(loadTelechargements.success),
                catchError(message => of(loadTelechargements.failure(message)))
            )
        )
    );

const resetCarnetEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(resetCarnet.request)),
        switchMap(action =>
            from(carnetVoyageService.resetCarnet()).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Le carnet a bien été remis à zéro.`
                        }
                    }),
                    resetCarnet.success(action)
                ]),
                catchError(message => of(resetCarnet.failure(message)))
            )
        )
    );

const addFileTelechargementsEpics: RootEpic = (action$, state$, { carnetVoyageService }) => action$.pipe(
    filter(isActionOf(addFileTelechargements.request)),
    map((action) => {
        const rxAxios = carnetVoyageService.addFileTelechargements(action.payload);
        return { action, rxAxios }
    }),
    mergeMap(({ action, rxAxios: { progress, response } }) => merge(
        progress.pipe(
            map((progress) => {
                const file: B64File = action.payload;
                file.progress = progress;
                return setProgressSaveFiles(file);
            }),
        ),
        response.pipe(
            mergeMap(({ content }) => [newSnackbar({
                type: SnackbarType.SUCCESS,
                props: {
                    open: true,
                    autoHideDuration: 2000,
                    message: `Le fichier a bien été ajouté.`,
                }
            }),
            addFileTelechargements.success(<B64File> content)]),
        ),
    )),
    catchError((error) => {
        newSnackbar({
            type: SnackbarType.WARNING,
            props: {
                open: true,
                autoHideDuration: 2000,
                message: `Il y a eu un problème dans la sauvegarde des fichiers.`,
            }
        });
        return of(addFileTelechargements.failure(error))
    })
);

const removeFileTelechargementsEpics: RootEpic = (action$, state$, { carnetVoyageService }) =>
    action$.pipe(
        filter(isActionOf(removeFileTelechargements.request)),
        mergeMap(action =>
            from(carnetVoyageService.removeFileTelechargements(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 2000,
                            message: `Le fichier a bien été supprimé.`
                        }
                    }),
                    removeFileTelechargements.success(action)
                ]),
                catchError(message => of(removeFileTelechargements.failure(message)))
            )
        )
    );

const CarnetVoyageEpics = combineEpics(
    loadCarnetEpics,
    loadItemsEpics,
    loadItemEpics,
    loadScheduleEpics,
    saveScheduleEpics,
    loadContactsEpics,
    saveContactsEpics,
    loadCarnetQuestionnairesEpics,
    saveEditoEpics,
    loadEditoEpics,
    saveHotelEpics,
    loadHotelEpics,
    loadInformationsEpics,
    saveInformationsEpics,
    loadTelechargementsEpics,
    addFileTelechargementsEpics,
    removeFileTelechargementsEpics,
    resetCarnetEpics,
    loadParticipantsEpics
);

export default CarnetVoyageEpics;
