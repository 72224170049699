import Axios from "axios";
import { Notification } from "classes/notifications/Notification.class";
import qs from 'qs';
import { NotificationsFilterState } from "./types";

export class NotificationsService {
    private static instance: NotificationsService;

    public static getInstance(): NotificationsService {
        if (!NotificationsService.instance) {
            NotificationsService.instance = new NotificationsService();
        }

        return NotificationsService.instance;
    }

    public async loadNotifications(notificationsFilters: NotificationsFilterState): Promise<Notification[]> {
        console.log(notificationsFilters)
        return Axios.get(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 3,
            filters: JSON.stringify(notificationsFilters),
        })}`).then(({ data: { content } }) => content.map((notification : any) => new Notification(notification)));
    }

    public async vueNotification(id : number): Promise<void> {
        return await Axios.post(
            `index.php?${qs.stringify({
                rub: IS_ADMIN ? 130 : 13,
                p : 4
            })}`,
            qs.stringify({
                id: JSON.stringify(id)
            })
        );
        
    }

    public async vueAllNotifications(): Promise<void> {
        return Axios.post(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 5
        })}`);
    }
}
