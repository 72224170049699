import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { textAlign, flexbox } from '@material-ui/system';
import { colors } from '../../../../../Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    noAnimationsCard: {
        height: '70vh',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    listAnimationGridContainer: {
        margin: spacing(2, 0)
    },
    listAnimationGridItem: {
        flexBasis: 340,
        minHeight: 340,
    },
    listAnimationsCard: {
        /* Style for "Rectangle" */

        height: '100%',
        borderRadius: 5,
        backgroundColor: 'white',
    },
    animationCardImage: {
        height: 180,
        backgroundSize: 'auto 100%',
        backgroundRepeat : 'no-repeat',
        backgroundPosition: 'center center',
    },
    listAnimationsCardContent: {
        height: '100%',
        padding: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    createAnimationGridItem: {
        flexBasis: 340,
        minHeight: 340,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',

        '& h2': {
            fontSize: 18,
            fontWeight: 'bold',
        },
    },
    createAnimationCard: {
        /* Style for "Rectangle" */
        height: '100%',
        borderRadius: 5,
        border: `2px dashed ${colors.lighterGrey.main}`,
        backgroundColor: 'white',
    },
    animationCardActionArea: {
        height: '100%',
    },
    createAnimationCardContent: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    addIcon: {
        width: 30,
        height: 30,
        backgroundColor: 'white',
        borderRadius: '50%',
        margin: 'auto',
    },
    socleOrange: {
        borderRadius: '50%',
        backgroundColor: colors.orange.main,
        width: 80,
        height: 80,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    ligneHautTableauTitle: {
        color: 'black',
        fontSize: 20,
        fontWeight: 700,
        flex: 1,
    },
    statusLabel: {
        color: 'white',
        height: 20
    },
    moreIcon: {
        color: colors.lighterGrey.light
    },
    divActionsTop: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding: spacing(1, 1, 0, 2),
        top: 0,
        left: 0,
        alignItems: 'center',
        width: '100%'
    },
    description: {
        padding: spacing(2),
    },
    typeName: {
        textTransform: 'uppercase',
        fontSize: 14,
        margin: spacing(0, 0, 1, 0)
    },

    title: {
        margin: spacing(0, 0, 1, 0),
        fontSize: 18,
        fontWeight: 700,
        height: 50

    },

    date: {
        fontSize: 16,
        color: colors.grey.main
    },
    listAnimationsCardActions : {
        display: 'flex',
        justifyContent: 'space-between',
        justifySelf : 'flex-end',
        padding : spacing(0,2,2,2)
    },
    menuAnimationCard : {
        '& .MuiPaper-root': {
            width: 110,
            height: 'auto',
            '& .MuiList-root' :{
                paddingTop: 0,
                paddingBottom: 0,
                '& .MuiMenuItem-root': {
                    minHeight: 40,
                    height: 40,
                    fontSize: 14,
                    paddingLeft: spacing(1)
                }
            } 
        }
    },
    createIcon: {
        marginRight: spacing(1),
    }

});
