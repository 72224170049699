import { Notification } from 'classes/notifications/Notification.class';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const loadNotificationsAsync = createAsyncAction(
    'LOAD_NOTIFICATIONS_REQUEST',
    'LOAD_NOTIFICATIONS_SUCCESS',
    'LOAD_NOTIFICATIONS_FAILURE',
)<void, Notification[], string>();


export const setIdActeurEnvoyeurNotificationFilter = createAction('SET_IDACTEURENVOYEUR_FILTER')<string>();

export const setDateNotificationNotificationFilter = createAction('SET_DATENOTIFICATION_FILTER')<string>();

export const setIdTypeNotificationFilter = createAction('SET_IDTYPE_FILTER')<number>();

export const setIdTypeMurNotificationFilter = createAction('SET_IDTYPEMUR_FILTER')<number>();