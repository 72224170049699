import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Axios from 'axios';
import qs from 'qs';
import NoTask from './NoTask';
import CreateTaskDialog from './CreateTaskDialog';
import TodoList from './TodoList';
import { Pacman } from '../../../components/pacman/pacman';
import NoTaskSVG from '../assets/no_task.svg';
import EmptyListComponent from 'components/EmptyList.component';

export const theme = createMuiTheme({
    palette: {
        primary: { main: '#FF7900', contrastText: 'white' },
        secondary: { main: '#8D8D8D', contrastText: 'white' },
    },
});

export default class AppTodoList extends Component {
    constructor() {
        super();

        /**
         * Seuil où l'on masque/affiche le bouton ajouter
         * @type {number}
         */
        this.FAB_TRESHHOLD = 20;

        /**
         * Stocke la dernière valeur du scrollTop de la liste
         * Permet d'afficher le fab quand on remonte
         * @type {number}
         */
        this.fabScrollTop = 0;

        this.state = {
            tasks: [],

            loading: true,

            /**
             * Si true alors le dialog d'ajout de tâche est ouvert
             * @type {boolean}
             */
            creatingTask: false,

            /**
             * Si true masque le bouton "ajouter"
             * @type {boolean}
             */
            hideFab: true,
        };

        Axios.get(`index.php?rub=19&p=1`)
            .then((response) => {
                this.setState({ tasks: response.data.content, loading: false });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    }

    /**
     * Ouvre le dialog de création de tâche
     */
    createTask() {
        this.setState({ creatingTask: true });
    }

    /**
     * Callback du dialog
     * Créer une nouvelle tâche si besoin et ferme le dialog
     * @param {boolean} canceled False si l'utilisateur a soumit le formulaire
     * @param {string} message Contenu de la nouvelle tâche
     */
    async createTaskDialogHandler(canceled, message) {
        if (!canceled && this.state.tasks.filter(task => task.name === message).length === 0) {
            try {
                const { data: response } = await Axios.post(
                    `index.php?rub=19&p=2`,
                    qs.stringify({
                        message,
                        priority: this.state.tasks.length,
                    }),
                );

                this.state.tasks.push(response.content);
            } catch (err) {
                alert(err.response.data.message);
            }
        }

        this.setState({ creatingTask: false });
    }

    /**
     * Gère le scroll de la liste pour masquer le fab
     * @param {React.UIEvent<HTMLDivElement>} event Evènement Scroll
     */
    scrollHandler({ target }) {
        const delta = target.scrollTop - this.fabScrollTop;

        if (delta < -this.FAB_TRESHHOLD) {
            this.setState({
                hideFab: true,
            });
            this.fabScrollTop = target.scrollTop;
        } else if (delta > this.FAB_TRESHHOLD) {
            this.setState({
                hideFab: false,
            });
            this.fabScrollTop = target.scrollTop;
        }
    }

    /**
     * Setter pour sauvegarder une nouvelle liste de tâches
     * @param {{ name: string, done: boolean}[]} tasks Nouvelle liste de tâches
     */
    setTasksHandler(tasks) {
        this.setState({
            tasks,
        });
    }

    renderTasks() {
        if (this.state.loading) {
            return (
                <div id="pacman-container">
                    <Pacman />
                </div>
            );
        } if (this.state.tasks.length == 0) {
            return <EmptyListComponent
                urlImage={NoTaskSVG}
                title="Aucune tâche"
                subtitle="Vous n'avez renseigné aucune tâche pour le moment"
            />;
        }
        return <TodoList tasks={this.state.tasks} setTasks={this.setTasksHandler.bind(this)} />;
    }

    render() {
        return (
            <div id='AppTodoList' className="test">
                <ThemeProvider theme={theme}>
                    <div id="todolist" onScroll={this.scrollHandler.bind(this)}>
                        {this.renderTasks()}
                        <Zoom in={this.state.hideFab} timeout={300}>
                            <Fab id="add-task" color="primary" onClick={this.createTask.bind(this)}>
                                <AddIcon />
                            </Fab>
                        </Zoom>
                        <CreateTaskDialog creatingTask={this.state.creatingTask} createTaskDialogHandler={this.createTaskDialogHandler.bind(this)} />
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}
