import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import shadows from '@material-ui/core/styles/shadows'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MoreVertSVG from '@material-ui/icons/MoreVert'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider, WithStyles, withStyles } from '@material-ui/styles'
import { Animation } from 'classes/animations/Animation.class'
import { AnimationStatus } from 'classes/animations/AnimationStatus.enum'
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum'
import { Quiz } from 'classes/animations/Quiz.class'
import EmptyListComponent from 'components/EmptyList.component'
import LigneHautTableau from 'components/LigneHautTableau.component'
import { Pacman } from 'components/pacman'
import { push } from 'connected-react-router'
import noAnimation from 'features/animations/assets/animations/aucune_animation.svg'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminHeaderSetArianeAction, adminHeaderSetTitleAction } from 'store/adminHeader/adminHeaderActions'
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions'
import { RootState } from 'store/types'
import { colors, Theme } from 'Theme'
import QuizSVG from '../../../../animations/assets/animations/quiz.svg'
import ConcoursPhotoSVG from '../../../../animations/assets/animations/photoCompetition.svg'
import { adminDeleteAnimationAsync, adminLoadAnimationsAsync, adminSetCurrentAnimation } from '../store/actions'
import { getAnimations } from '../store/selectors'
import style from './AdminAnimationList.style'
import DeleteAnimationDialogComponent from './DeleteAnimationDialog.component'
import NewAnimationDialog from './NewAnimationDialog.component'
import { ConcoursPhoto } from 'classes/animations/concoursPhoto/ConcoursPhoto.class'


const StyleCardActionArea = withStyles({
    root: {
        '&:hover $focusHighlight': {
            opacity: 0.12,
        },
    },
    focusHighlight: {},
})(CardActionArea);

const mapStateToProps = (state: RootState) => ({
    animations: getAnimations(state),
    isLoading: getAnimations(state).isLoading
});

const dispatchToProps = {
    loadAnimations: adminLoadAnimationsAsync.request,
    setCurrentAnimation: adminSetCurrentAnimation,
    deleteAnimation: adminDeleteAnimationAsync.request,
    setTitle: adminHeaderSetTitleAction,
    setAriane: adminHeaderSetArianeAction,
    newSnackbar: newSnackbar,
    push
};

interface LocalState {
    anchorEl: HTMLElement;
    dialogOpen: boolean;
    deleteDialogOpen: boolean;
    animationTargeted: Animation;
}

interface LocalProps {
    carnet: boolean
}

type AdminAnimationListProps =
    typeof dispatchToProps
    & ReturnType<typeof mapStateToProps>
    & WithStyles<typeof style>
    & LocalProps;

class AdminAnimationListComponent extends Component<AdminAnimationListProps, LocalState> {

    private blueTheme = (theme: typeof Theme) => {
        // debugger;
        return createMuiTheme({
            ...theme,
            palette:
            {
                primary: colors.blue
            },
            overrides: {
                MuiButton: {
                    containedPrimary: {
                        borderRadius: 5,
                        '&, &:hover': {
                            backgroundColor: colors.blue.main,
                            boxShadow: shadows[0]
                        }
                    }
                }
            }
        });
    };

    readonly state: LocalState = {
        anchorEl: null,
        dialogOpen: false,
        deleteDialogOpen: false,
        animationTargeted: null
    };

    constructor(props: AdminAnimationListProps) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.carnet) {
            this.props.setAriane(['Animations']);
            this.props.setTitle('Animations');
            if (this.props.animations.list.length === 0) {
                this.props.loadAnimations();
            }
        }

    }

    renderAnimation(animation: Animation): JSX.Element {
        let color: string;
        let image: string;
        let activable: string;
        switch (animation.type) {
            case AnimationTypes.Quiz:
                color = colors.purple.main;
                image = QuizSVG;
                activable = (animation as Quiz).playableQuestionCount > 0 ? "" : "Ajoutez au moins une question au quiz pour l'activer.";
                break;

            case AnimationTypes.PhotoContest:
                color = colors.blue.dark;
                image = ConcoursPhotoSVG;
                activable = ""; // Pas de condition d'activation

        }

        if (animation.frontImage) {
            color = colors.white.main;
            image = animation.frontImage.getSrc();
        }

        const { classes, carnet } = this.props;

        if (!!carnet == (animation.type === AnimationTypes.Quiz && (animation as Quiz).isCarnet)) {
            return (
                <Card
                    className={classes.listAnimationsCard}
                    elevation={0}>
                    <CardContent
                        className={classes.listAnimationsCardContent}>
                        <div
                            className={classes.divActionsTop}>
                            <Chip
                                size="small"
                                label={animation.getStatusLabel()}
                                className={classes.statusLabel}
                                style={{
                                    backgroundColor: this.getLabelColor(animation.getStatus()),
                                }}
                            />
                            <IconButton
                                id={"icon-button-" + animation.idAnimation}
                                aria-label="more"
                                aria-controls={"actions-menu" + animation.idAnimation}
                                aria-haspopup="true"
                                onClick={(event) => this.handleOnClickActionsMenu(event, animation)}>
                                <MoreVertSVG
                                    className={classes.moreIcon} />
                            </IconButton>
                        </div>
                        <div
                            className={classes.animationCardImage}
                            style={{ backgroundSize: 'cover', backgroundImage: `url(${image})`, backgroundColor: color }} />
                        <div
                            className={classes.description} >
                            <p className={classes.typeName} >{animation.typeName}</p>
                            <h3 className={classes.title}>{animation.title}</h3>
                            <p className={classes.date}>
                                Du <b>{animation.beginDate.format('L')}</b> au <b>{animation.endDate.format('L')}</b>
                            </p>
                        </div>

                        <CardActions
                            className={classes.listAnimationsCardActions}>
                            <Tooltip title={animation.active ? "Passer hors ligne" : "Passer en ligne"}>
                                <Switch
                                    checked={animation.active}
                                    onClick={this.handleChangeSwitchActive.bind(this, animation, activable)}
                                />
                            </Tooltip>
                            <Button color="primary" variant="contained" size="medium" onClick={this.handleAccessAnimation.bind(this, animation)} >
                                Accéder
                            </Button>
                        </CardActions>
                    </CardContent>
                </Card>
            );
        } else if (animation.type === AnimationTypes.PhotoContest && animation as ConcoursPhoto) {
            return (
                <Card
                    className={classes.listAnimationsCard}
                    elevation={0}>
                    <CardContent
                        className={classes.listAnimationsCardContent}>
                        <div className={classes.divActionsTop}>
                            <IconButton
                                id={"icon-button-" + animation.idAnimation}
                                aria-label="more"
                                aria-controls={"actions-menu" + animation.idAnimation}
                                aria-haspopup="true"
                                onClick={(event) => this.handleOnClickActionsMenu(event, animation)}>
                                <MoreVertSVG
                                    className={classes.moreIcon} />
                            </IconButton>
                        </div>
                        <div
                            className={classes.animationCardImage}
                            style={{ backgroundSize: 'cover', backgroundImage: `url(${image})`, backgroundColor: color }} />
                        <div
                            className={classes.description} >
                            <p className={classes.typeName} >{animation.typeName}</p>
                            <h3 className={classes.title}>{animation.title}</h3>
                            <p className={classes.date}>
                                Du <b>{animation.beginDate.format('L')}</b> au <b>{animation.endDate.format('L')}</b>
                            </p>
                        </div>

                        <CardActions
                            className={classes.listAnimationsCardActions}>
                            <Tooltip title={animation.active ? "Passer hors ligne" : "Passer en ligne"}>
                                <Switch
                                    checked={animation.active}
                                    onClick={this.handleChangeSwitchActive.bind(this, animation, activable)}
                                />
                            </Tooltip>
                            <Button color="primary" variant="contained" size="medium" onClick={this.handleAccessAnimation.bind(this, animation)} >
                                Accéder
                            </Button>
                        </CardActions>
                    </CardContent>
                </Card>
            );
        } else {
            return null;
        }
    }

    getLabelColor(status: AnimationStatus) {
        switch (status) {
            case AnimationStatus.INCOMING:
                return colors.yellow.dark;

            case AnimationStatus.ONGOING:
                return colors.green.main;

            case AnimationStatus.FINISHED:
                return colors.pink.main;
        }
    }

    async handleChangeSwitchActive(animation: Animation, activable: string) {
        if (activable.length > 0) {
            this.props.newSnackbar({
                type: SnackbarType.INFO,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: activable,
                    action: (
                        <ThemeProvider theme={this.blueTheme}>
                            <Button color="primary" variant="contained" size="medium"
                                onClick={() => {
                                    this.props.setCurrentAnimation(animation);
                                    if (this.props.carnet) {
                                        window.location.replace(`index.php?rub=22#/animation/${animation.idAnimation}`);
                                    } else {
                                        this.props.push(`/animation/${animation.idAnimation}`)
                                    }
                                }

                                }>
                                Accéder
                            </Button>
                        </ThemeProvider>)
                }
            });
        } else {
            animation.active = !animation.active;
            await animation.save().animation.toPromise();
            this.forceUpdate();
        }
    }

    handleOnClickActionsMenu(event: React.MouseEvent<HTMLButtonElement>, animation: Animation) {
        this.setState({
            anchorEl: event.currentTarget,
            animationTargeted: animation
        });
    }

    handleCloseActionsMenu() {
        this.setState({
            anchorEl: null,
            animationTargeted: null,
            deleteDialogOpen: false,
            dialogOpen: false
        });
    }

    handleDeleteAction() {
        this.setState({
            ...this.state,
            deleteDialogOpen: true,
            anchorEl: null
        });
    }

    handleDuplicateAction() {
        this.state.animationTargeted.duplicate();
        this.handleCloseActionsMenu();
        this.props.loadAnimations();
    }


    handleResultsAction() {
        this.props.setCurrentAnimation(this.state.animationTargeted);
        if (this.props.carnet) {
            window.location.replace(`index.php?rub=22#/animation/${this.state.animationTargeted.idAnimation}/results`);
        } else {
            this.props.push(`/animation/${this.state.animationTargeted.idAnimation}/results`)
        }
    }
    handleParameterAction() {
        this.props.setCurrentAnimation(this.state.animationTargeted);
        if (this.props.carnet) {
            window.location.replace(`index.php?rub=22#/animation/${this.state.animationTargeted.idAnimation}/settings`);
        } else {
            this.props.push(`/animation/${this.state.animationTargeted.idAnimation}/settings`)
        }
    }

    handleDeleteAnimation(deleteAnimation: boolean) {
        if (deleteAnimation) {
            this.props.deleteAnimation(this.state.animationTargeted.idAnimation);
        }
        this.handleCloseActionsMenu();
    }


    handleCloseNewAnimationDialog(newAnimation: number) {
        if (newAnimation) {
            if (this.props.carnet) {
                window.location.replace(`index.php?rub=22#/animations/creation/${newAnimation}`);
            } else {
                this.props.push(`/animations/creation/${newAnimation}`)
            }
        }

        this.setState({ dialogOpen: false })
    }

    handleAccessAnimation(animation: Animation) {
        this.props.setCurrentAnimation(animation);
        if (this.props.carnet) {
            window.location.replace(`index.php?rub=22#/animation/${animation.idAnimation}`);
        } else {
            this.props.push(`/animation/${animation.idAnimation}`)
        }
    }


    render() {
        if (this.props.animations === null || this.props.animations.isLoading) {
            return <Pacman />;
        }

        const { classes, animations } = this.props;
        const componentsLigneHaut = [
            <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => this.setState({ dialogOpen: true })}
            >
                <AddCircleIcon className={classes.createIcon} />
                Créer une animation
                    </Button>
        ]


        return (
            <div>
                <LigneHautTableau title="Liste des animations" components={componentsLigneHaut} className={classes.ligneHautTableauTitle} />

                {
                    animations.list && animations.list.length > 0 ? (
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            className={classes.listAnimationGridContainer}>
                            {animations.list.map((animation, index, array) => {
                                const renderedAnimation = this.renderAnimation(animation);

                                return (
                                    <Fragment>
                                        {!!renderedAnimation
                                            ?
                                            <Grow in timeout={1000 * Math.log(index + 2) / 2} key={animation.idAnimation}>
                                                <Grid
                                                    item
                                                    className={classes.listAnimationGridItem}
                                                >
                                                    {renderedAnimation}
                                                </Grid>
                                            </Grow>
                                            :
                                            null
                                        }
                                    </Fragment>
                                )
                            })}
                            <Grow in timeout={1000 * Math.log(animations.list.length + 2) / 2} >
                                <Grid
                                    item
                                    className={classes.createAnimationGridItem}>
                                    <Card
                                        className={classes.createAnimationCard}
                                        elevation={0}>
                                        <StyleCardActionArea
                                            className={classes.animationCardActionArea}
                                            onClick={() => this.setState({ dialogOpen: true })}
                                        >
                                            <CardContent
                                                className={classes.createAnimationCardContent}
                                            >
                                                <div className={classes.socleOrange}>
                                                    <AddIcon
                                                        className={classes.addIcon}
                                                        color="primary"
                                                    />
                                                </div>
                                                <h2>Créer une animation</h2>
                                            </CardContent>
                                        </StyleCardActionArea>
                                    </Card>
                                </Grid>
                            </Grow>
                        </Grid>
                    ) : (
                            <Paper
                                elevation={0}
                                className={classes.noAnimationsCard}
                            >
                                <EmptyListComponent
                                    title="Aucune animation"
                                    subtitle="Vous n'avez pas encore créé d'animations. Cliquez sur le bouton 'Créer une animation' pour créer votre première animation"
                                    urlImage={noAnimation}
                                />
                            </Paper>
                        )
                }

                <NewAnimationDialog
                    open={this.state.dialogOpen}

                    onClose={this.handleCloseNewAnimationDialog.bind(this)}
                />
                <DeleteAnimationDialogComponent
                    triggered={this.state.deleteDialogOpen}
                    onClose={this.handleDeleteAnimation.bind(this)}
                />
                {
                    this.state.animationTargeted &&
                    <Menu
                        id={"actions-menu"}
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleCloseActionsMenu.bind(this)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}

                        className={classes.menuAnimationCard}>
                            {
                                this.state.animationTargeted.type === AnimationTypes.Quiz &&
                                <MenuItem onClick={this.handleResultsAction.bind(this)}>Voir résultats</MenuItem>
                            }
                            <MenuItem onClick={this.handleParameterAction.bind(this)}>Paramètres</MenuItem>
                            {
                                this.state.animationTargeted.type === AnimationTypes.Quiz &&
                                <MenuItem onClick={this.handleDuplicateAction.bind(this)}>Dupliquer</MenuItem>
                            }
                            <MenuItem onClick={this.handleDeleteAction.bind(this)}>Supprimer</MenuItem>
                    </Menu>
                }
            </div>
        );
    }
}

export default withStyles(style)(
    connect(
        mapStateToProps,
        dispatchToProps,
    )(AdminAnimationListComponent),
);
