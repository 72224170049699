import { Theme } from 'Theme'
import createStyles from '@material-ui/styles/createStyles'
import makeStyles from '@material-ui/styles/makeStyles'
import { colors } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) =>
    createStyles({
        greyText: {
            color: colors.grey.main
        },
        nameText: {
            fontSize: 14,
            fontWeight: 700
        },
        date: {
            fontSize: 12,
            color: colors.grey.main,
            marginLeft: spacing(0.5)
        },
        contentBox: {
            fontSize: 14
        },
        imgGroupeLikeNb: {
            display: 'inline-block',
            verticalAlign: 'middle',
            width: 21,
            height: 21,
            backgroundSize: 21,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            marginLeft: -8,
            marginTop: -1
        },
        boxViewImage: {
            display: 'inline-block',
            width: '100%',
            position: 'relative',
            paddingTop: 'calc(2 * 100% / 3)',
            minHeight: 200,
            marginTop: spacing(1)
        },
        textLikeCommentActions: {
            marginLeft: spacing(0.5),
            fontSize: 14
        },
        iconLikeCommentActions: {
            height: 16,
            width: 16,
        },
        nbrLikesCommentsText: {
            fontSize: 14,
            color: colors.grey.main,
            marginTop: 1
        },
        boxPostImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: '100% 100%',
            cursor: 'zoom-in',
            borderRadius: 10
        },
        imgViewImageFront: {
            margin: 'auto',
            width: '100vw',
            maxWidth: '95%',
            maxHeight: '95%',
        },
        imgViewImageBack: {
            margin: 'auto',
        },
        videoFrame: {
            position: 'relative',
            paddingTop: '55%',
            marginTop: spacing(1),
            marginBottom: spacing(0.5),
            "& iframe": {
                borderRadius: spacing(0.5),
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
            }
        },
        paperPost: {
            marginBottom: spacing(1),
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            padding: spacing(1)
        },
        paperDocument: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: spacing(1),
            marginTop: spacing(1),
            marginBottom: spacing(0.5),
            alignItems: 'center'
        },
        paperPostDesktop: {
            border: `1px solid ${colors.lighterGrey.light}`
        },
        badgeTypePost: {
            maxHeight: 20,
            whiteSpace: 'nowrap',
        },
        popoverLikesPaper: {
            justifyContent: 'space-between',
            borderRadius: '50vh',
            padding: spacing(0.5),
            '& img': {
                display: 'inline-block',
                minWidth : '15%',
                width: 'calc(20% - 10px)',
                borderRadius: '50%',
                cursor: 'pointer',
                zIndex: 1100,
                margin: spacing(0.5)
            }
        }

    }));